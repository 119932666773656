(function flexible(window, document) {
  var docEl = document.documentElement
  var dpr = window.devicePixelRatio || 1

  // adjust body font size
  function setBodyFontSize() {
    if (document.body) {
      document.body.style.fontSize = (12 * dpr) + 'px'
    }
    else {
      document.addEventListener('DOMContentLoaded', setBodyFontSize)
    }
  }
  setBodyFontSize();

  // set 1rem = viewWidth / 103
  // 把屏幕平均分成10等份。比如1920/10= 192 px,这个时候1rem就是192px,配合vscod插件快速适配，在style中使用媒体查询。
  function setRemUnit() {
    console.log('resize');
    console.log('docEl.clientWidth',docEl);
    if(docEl.clientWidth >= 1200 &&  docEl.clientWidth < 1920) {
      var fz = (docEl.clientWidth) * 10 / 1920
    }
     else if(docEl.clientWidth < 1200) {
      var fz = 1200 * 10 / 1920
     }
    // docEl.style.fontSize = rem + 'px'
    docEl.style.fontSize = fz + 'px'
  }

  setRemUnit()

  // reset rem unit on page resize
  window.addEventListener('resize', setRemUnit)
  window.addEventListener('pageshow', function (e) {
    if (e.persisted) {
      setRemUnit()
    }
  })

  // detect 0.5px supports
  /* if (dpr >= 2) {
    var fakeBody = document.createElement('body')
    var testElement = document.createElement('div')
    testElement.style.border = '.5px solid transparent'
    fakeBody.appendChild(testElement)
    docEl.appendChild(fakeBody)
    if (testElement.offsetHeight === 1) {
      docEl.classList.add('hairlines')
    }
    docEl.removeChild(fakeBody)
  } */
}(window, document))

