<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    document.documentElement.style.fontSize = "10px";
  },
};
</script>

<style lang="less">
#app {
  // width: 1920px;
  // max-width: 1920px;
  margin: 0 auto;
  padding-top: 70px;
}
.pop-product {
  width: 597px !important;
  height: 220px;
  padding: 20px 16px 0 13px !important;
  margin-top: 0 !important;
  .content {
    display: flex;
    .soft {
      width: 180px;
      margin-right: 16px;
      .txt {
        width: 84px;
        height: 40px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 10px;
      }
      .machine {
        width: 180px;
        height: 115px;
        padding: 14px 16px 0 8px;
        background: #ffffff;
        border: 1px solid #cccccc;
        .top {
          display: flex;
          height: 22px;
          .pic {
            width: 22px;
            height: 22px;
            img {
              width: 100%;
              height: 100%;
              background-size: contain;
            }
          }
          span {
            width: auto;
            margin-left: 6px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #000000;
          }
        }
        .line {
          margin-top: 14px;
          width: 100%;
          height: 1px;
          background-color: #ccc;
        }
        p.bottom {
          height: 36px;
          margin-top: 14px;
          font-style: normal;
          font-weight: 300;
          font-family: "PingFang-Li" !important;
          font-size: 12px;
          line-height: 18px;
          color: #212121;
        }
      }
    }
    .print {
      width: 180px;
      margin-right: 32px;
      .txt {
        width: 84px;
        height: 40px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 10px;
      }
      .machine {
        width: 180px;
        height: 115px;
        padding: 14px 13px 0 8px;
        background: #ffffff;
        border: 1px solid #cccccc;
        .top {
          display: flex;
          height: 22px;
          .pic {
            width: 22px;
            height: 22px;
            img {
              width: 100%;
              height: 100%;
              background-size: contain;
            }
          }
          span {
            width: auto;
            margin-left: 6px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #000000;
          }
        }
        .line {
          margin-top: 14px;
          width: 100%;
          height: 1px;
          background-color: #ccc;
        }
        p.bottom {
          height: 36px;
          margin-top: 14px;
          font-style: normal;
          font-weight: 300;
          font-family: "PingFang-Li" !important;
          font-size: 12px;
          line-height: 18px;
          color: #212121;
        }
      }
    }
    .last {
      width: 156px;
      .download {
        display: flex;
        align-items: center;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        height: 40px;
        .pic {
          width: 22px;
          height: 22px;
          img {
            width: 100%;
            height: 100%;
            background-size: contain;
          }
        }
        span {
          height: 20px;
          margin-left: 5px;
          font-size: 14px;
          line-height: 20px;
          color: #212121;
        }
      }
      .purchase {
        margin-top: 10px;
        .top {
          display: flex;
          align-items: center;
          height: 22px;
          .pic {
            width: 22px;
            height: 22px;
            img {
              width: 100%;
              height: 100%;
              background-size: contain;
            }
          }
          span {
            height: 20px;
            margin-left: 5px;
            font-weight: 500;
            font-family: "PingFang-Me" !important;
            font-size: 16px;
            line-height: 20px;
            color: #212121;
          }
        }
        .bottom {
          margin-top: 10px;
          padding-left: 27px;
          .pur-tel {
            .txt {
              height: 20px;
              font-weight: 350;
              font-size: 12px;
              line-height: 20px;
              color: #212121;
            }
            .phone {
              height: 18px;
              font-weight: 350;
              font-size: 12px;
              line-height: 18px;
              color: #333333;
            }
          }
          .consult-tel {
            margin-top: 10px;
            .txt {
              height: 20px;
              font-weight: 350;
              font-size: 12px;
              line-height: 20px;
              color: #212121;
            }
            .phone {
              height: 24px;
              font-weight: 350;
              font-size: 12px;
              line-height: 18px;
              color: #333333;
            }
          }
        }
      }
    }
  }
  .popper__arrow {
    display: none !important;
  }
}
.pop-support {
  width: 200px !important;
  height: 122px;
  padding: 10px 0 !important;
  margin-top: 0 !important;
  .popper__arrow {
    display: none !important;
  }
  ul {
    li {
      padding-left: 15px;
      height: 34px;
      font-size: 14px;
      color: #212121;
      line-height: 34px;
    }
    li:hover {
      background-color: #f4f4f4;
    }
  }
}

.el-menu--horizontal {
  li.lang {
     .el-submenu__title {
        padding: 0 !important;
        padding-left: 20px !important;
      }
    }
  .el-menu {
    background-color: #222222 !important;
    padding-bottom: 0 !important;

    li {
      // width: 180px !important;
      // height: 46px !important;
      padding-left: 36px !important;
      line-height: 46px !important;
      background-color: #222 !important;
    }
    li:hover {
      color: #e40807 !important;
    }
    .el-input {
      width: 260px;
      height: 30px;
      input {
        border-radius: 4px;
        border: 1px solid #e40807;
        background: #222 !important;
        color: #666 !important;
      }
      input::placeholder {
        color: #666;
      }
    }
  }
}

.el-menu--horizontal {
  // left: 1216px;
  .el-menu {
    .el-input {
    }
  }
}
</style>
