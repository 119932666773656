// index.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store'
import locale from 'element-ui/lib/locale'
Vue.use(VueI18n)
// 引入各个语言配置文件
import zhLocale from '../lang/zh'
import enLocale from '../lang/en'
import jaLocale from '../lang/ja'
// 创建vue-i18n实例i18n
console.log('localStorage',localStorage.getItem('lang'));
console.log('store.state.lang',store.state.lang);
const i18n = new VueI18n({
  // 设置默认语言
  locale: localStorage.getItem('lang') || 'zh', // 语言标识
  // locale: 'zh',
  // 添加多语言（每一个语言标示对应一个语言文件）
  messages: {
    'zh': zhLocale,
    'en': enLocale,
    'ja': jaLocale
  }
})

locale.i18n((key,value) => i18n.t(key,value))
// 暴露i18n
export default i18n

