export default {
  position: 'Your location',
  homeB: 'Home Page',
  gCB: 'Genius Control Motherboard',
  head: {
    product: 'PRODUCT',
    resin: 'Consumable Resin',
    geniusControlBoard: 'Genius Motherboard',
    coreOcto: 'SOFTWARE AND PLATFORM',
    coreOctoApp: 'Octopus Core',
    support: 'SUPPORT',
    know: 'Knowledge Centre',
    warranty: 'Warranty Policy',
    faq: 'Frequently Asked Question',
    compPro: 'COMPANY PROFILE',
    aboutUs: 'About Us',
    application: 'Application Area',
    shop: 'MALL',
    search: 'Search',
    chinese: 'Chinese',
    en: 'English',
    ja: 'Japanese'
  },
  footer: {
    products: 'PRODUCT',
    printer: 'Bloom-740w',
    mainBoard: 'Genius Control motherboard',
    resin: 'Resin',
    softPatform: 'SOFTWARE And PLATFORM',
    coreOctoApp: 'Octopus Core APP',
    shop: 'Mall',
    support: 'SUPPORT',
    know: 'Knowledge Centre',
    warranty: 'Warranty Policy',
    faq: 'Frequently Asked Question',
    compPro: 'COMPANY PROFILE',
    aboutUs: 'About Us',
    application: 'Application Area',
    linkUs: 'Contact Us',
    tel: 'Phone',
    mail: 'Mailbox',
    address: 'Address',
    detailAddress: "3rd Floor, Building B, No. 6042 Xinghua Road, Bao'an Avenue, Fuyong Street, Bao'an District, Shenzhen City, Guangdong Province",
    compName: 'Shenzhen Octopus Works Technology Co., Ltd',
    ipc: 'Guangdong ICP preparation 2023010580号',
    puclicNetwork: 'Guangdong Public Network Security 44030002001547号',
    //belonging: '多样性和归属感',
    //softDownload: '软件下载',
    //coreOcto: '章鱼核',
    //coreOctoPlat: '章鱼核云平台',
    //productSup: '产品支持',
    //downInstallSoft: '下载并安装软件',
    //poli: '企业社会责任政策',
  },
  home: {
    learnMore: 'Learn More',
    buyNow: 'Buy Now',
    banner: [
      {
        title: 'New Generation Of High Performance Light Curing Bloom-740w', 
        value: 'Create with precision and enjoy the beauty of printing quietly',
        li: 'A new 3D printing experience that allows you to feel as if you are actually there during the printing process and enjoy the beauty of the peaceful valley',
      }, 
      {
        title: 'Genius UV curing control motherboard',
        value: 'Intelligent Navigator with One Stop Solution',
        li: 'Compatible With Most LCD Exposure Screens In The Market, Designed With Customized Features, Equipment, And Upgraded Systems, Providing Technical Support'
      },
      {
        title: 'LCD Desktop Grade Photosensitive Resin',
        value: 'The Coexistence Of Technological Aesthetics And Industry',
        li: 'Resin designed specifically for Bloom-740w 3D printers, the flexibility of the material brings better results and a happier experience'
      },
      {
        title: 'Magic Pen Slicer', 
        value: 'Technology integration into art, technology integration into creativity',
        li: 'Dream magic implementer, making your creativity as easy to achieve as magic, without any professional training, anyone can immediately print it'
      }
    ],

    smallBanner: [
      {
         title: 'Get Started Easily',
         value: 'Easy To Use，',
         description: 'One click operation, simple and efficient.' 
      },
      {
        title: 'Performance And Quality Coexist',
        value: 'Strong Quality，',
        description: 'High quality, long-lasting and stable.' 
      },  
      {
        title: 'Bloom-740w and Magic Pen Slicer',
        value: 'Work Partner,',
        description: 'Good teamwork.' 
      },
      {
        title: 'compatibility',
        value: 'Languages Such As English And French,',
        description: 'Bloom-740w Is Compatible With Both' 
      },
      {
        title: 'Sense Of Quality',
        value: 'Exquisite Craftsmanship,',
        description: 'Technology and Industry' 
      }
    ],

    title:[
      {
        title: 'INTELLIGENT MANUFACTURING PERFORMANCE MACHINE',
        description: 'Innovative Technology Changes The World',
      },
      {
        title: 'Understanding Bloom-740w',
        description: 'The Collision Of Technology And Technology, Born For Printing High-Performance Materials',
      },
      {
        title: 'ALL SERIES OF PRODUCTS',
        description: 'Integration Of Professional Manufacturing Technology And Creativity',
      },
      {
        title: 'GOOD WORK PARTNER',
        description: 'Integration Of Professional Manufacturing Technology And Creativity',
      },
      {
        title: 'DISPLAY OF PRINTED WORKS',
        description: 'Selected Works Created By Users',
      },
    ],

    products:[
      'Bloom-740w',
      'ABS-Like Resin',
      'Water-Wash Resin',
      'Ggenius I Control Motherboard',
      'Ggenius II Control Motherboard',
      'Ggenius III Control Motherboard'
    ],

    software: [
      {
        subTitle: 'Master creativity and print the future',
        label: 'Auto-support',
        value: 'One click support',
        des: 'Automatically detect areas in the model that need support, solve suspended situations, prevent geometric surfaces from being damaged, and complete accurate and complex model support.' 
      }, 
      {
        subTitle: '掌握创意、打印未来',
        label: 'Auto layout',
        value: 'Auto arrangement',
        des: 'Convenient operation, omitting manual adjustment steps, automatically distributing and arranging models with one click, reducing tedious repeated steps, and achieving distribution and arrangement in one step' 
      }, 
      {
        subTitle: '掌握创意、打印未来',
        label: 'Cluster printing',
        value: 'Remote control',
        des: 'Remote collective control of printing, real-time control of connected printers, real-time viewing of printing progress, and remote monitoring of printing results' 
      },   
      {
        subTitle: '掌握创意、打印未来',
        label: 'Slice preview',
        value: 'Visualization ',
        des: 'The model is compared with the slice diagram to visually display the printing results of each layer of the model, accurately standardizing the process and procedures, and fully displaying the beauty of exquisite craftsmanship.' 
      },   
      {
        subTitle: '掌握创意、打印未来',
        label: 'Image algorithm',
        value: 'Image Processing',
        des: 'Through self-developed algorithms, we perform sub-pixel exposure on the model to achieve anti-aliasing and print a silk-smooth surface to display high-resolution images.' 
      }, 
    ],
    resin: {
      title: 'Photosensitive Resin',
      subTitle: 'Leader in Material Innovation',
      abs: {
          name: 'ABS resin', 
          color: 'Color: white, gray',
          des: 'It has excellent strength and durability, has good resistance to chemicals, can form a smooth glass-like surface, can be easily treated with paint to change the appearance, remains stable within the temperature range, and is suitable for various applications. Scene and production process.'
      },
      water: {
        name: 'Water washed resin',
        color: 'Color: white, gray',
        des: 'It has balanced performance, high tensile strength, ductility and impact resistance, and can be suitable for direct production of functional parts and end product casings.'
      }
    },
    mainBoard: {
      title: 'Genius control motherboard',
      subTitle: 'Smart Navigator',
      G1: {
       name: 'Genius I',
       des: '64M DDR, 512M SDNAND'
      },
      G2: {
       name: 'Genius II',
       des: '521M DDR，16G EMMC'
      },
      G3: {
       name: 'Genius III 12K high-definition large screen is coming soon, this is the core controller you want',
       des: 'Self-developed high-end motherboard, high running memory, 512M DDR, 16G EMMC'
      }
   },
   aboutUs: {
     title: 'About Us',
     name: 'Shenzhen Octopus Works Technology Co., Ltd.',
     des: "A company committed to digitizing the small world and creating a colorful world that is primarily digital and supplemented by visual information. The company is the world's leading provider of graphics and image information processing, parallel computing, heterogeneous computing, large-scale integrated circuits and mechanical automation solutions......"
   },
   services: [
    {
      title: 'Service',
      label: 'Device selection',
      value: 'Intelligent testing Manual testing'
    },
    {
      label: 'Detailed craftsmanship',
      value: 'Ultimate feel'
    },
    {
      label: 'OEM customization',
      value: 'New product concept'
    },
    {
      label: 'product upgrade ',
      value: 'Break through design limitations'
    },
    {
      label: 'Technical Support',
      value: 'Digital solutions'
    },
  ],
  partner: 'Partner'
  },
  printer: {

    banner: {
      title: 'New Generation Of High Performance Light Curing Bloom-740w',
      value: 'From Imagination To Reality, Creativity Is Right At This Moment',
      purchase: 'Buy Now',
      watch: 'Watch video'
    },
    detailed: {
      txt1:'Bloom-740w',
      txt2: 'Small body, big creativity',
      txt3: '40μm',
      txt4: 'Fineness',
      txt5: '0.5S',
      txt6: 'Quick Release',
      txt7: 'Layerless Printing',
      txt8: 'Quick Detachable Print Head',
      txt9: "Unlimited creativity: Bloom-740w brings us infinite creative space, transforming our ideas and designs into real objects. Whether it's personal creative works or commercial product prototypes, Bloom-740w can be presented with precise printing effects, with just a small printer, opening up unlimited possibilities for creativity.",
      txt10: "Personalized Customization: Bloom-740w provides convenience for personalized customization, allowing for customized production according to individual needs. Whether it's customized jewelry, household items, or personalized gifts, we can use Bloom-740w to make them at home. This trend of personalized customization not only satisfies people's pursuit of unique items, but also provides more business opportunities and entrepreneurial space.",
      params: '详细参数'
    },

    productColor: {
      top:{
        title: 'COEXISTENCE OF STRENGTH AND APPEARANCE',
        des: 'The Collision Between Technology And Art',
      },
      product:[
        {
          title: 'Print Head',
          des: 'One Press And One Press Disassembly',
        },
        {
          title: 'Linear Guide Rail',
          des: 'High Precision Z-Axis',
        },
        {
          title: 'ARM Chip',
          des: 'High Performance And Smooth Speed',
        },
        {
          title: 'Remote Monitoring',
          des: 'Support APP/WIFI Wireless Network',
        },
        {
          title: 'DIY Creativity',
          des: 'Personalized Customization',
        },
        {
          title: 'Intuitive UI Interface',
          des: 'Dual Color Mode For Free Selection',
        },
      ],
    },

    param: 'Parameter',
    other:[
      {
        title: 'Maximum Accuracy, Restoring Perfect Details Of The Model',
        des: 'S-Shaped Graphic Algorithm With Clear And Visible Details'   
      },
      {
        title: 'RED, SILVER, AND WHITE, THE COMBINATION OF TECHNOLOGY AND INDUSTRY',
        des: 'The Integration Of Technology And Creativity'   
      },
      {
        title: 'Forty μm High Precision Printing',
        des: 'A Silky Smooth Surface'   
      },
      {
        title: 'HIGHLIGHTS COLLECTION',
        des: 'The Connotation Of BLoom-740w',   
        imgTitle01: 'Silent Printing',
        imgDes01: '50dB',
        imgDes02:'Easy Leveling',
        imgDes03:'Buckle Installation',
        imgDes04:'Reliable Quality',
        label: 'Integrating 3D Digital Full Process Visualization Management,',
        value: 'Providing The Most Practical And Agile Solution, Achieving Dual Improvement In Quality And Speed'
      },
      {
        title:'COB Light Source',
        des1:'High gloss uniformity of over 90%, eliminating imperfect factors',
        des2:'Innovative upgrade of light source types, combined with light source modification, mask light uniformity reaches over 90%, dimmable, energy-saving, and long service life, with stable brightness and color'
      },
      {
        title:'Mastering The Printing "Chip" Core',
        des1:'Ensure That Printing On Each Layer Arrives As Scheduled',
        des2:'The printer motherboard is equipped with high-performance ARM chips, ensuring stable performance. Handling any complex model printing task can ensure smooth operation',
      },
      {
        title: 'SUB-PIXEL EXPOSURE',
        des1: 'Every Detail Is Clearly Visible',
        des2: 'Image edge alpha mixing+anti aliasing processing, reducing model printing',
        des3: 'texture, making image edge transitions smoother, thereby improving the surface smoothness of the printed model'
      },
      {
        title: 'SELF DEVELOPED SLICING SOFTWARE',
        des1: 'Fully functional to meet more printing needs',
        des2:'Our self-developed Magic Pen slicing software has two theme interfaces, deep and shallow, in multiple languages. The software features include modules for model processing, support addition, model slicing, and model simulation printing. Paired with the built-in model library, enjoy professional creation while playing with your own creativity.'
      }
    ],

    video: {
      title: 'Video',
      origWork: 'Original Creations',
      vLabels: [
        'Bloom-740w',
        'Precision Printing',
        'COB Light Source',
        'Subpixel Exposure',
        'Dynamic Layer Thickness'
      ]
    }
  },
  printDetailParams: {
    bread: {
      position: 'Your Location',
      home: 'Home Page',
      detailPar: 'Detailed Parameters'
    },
    devStr: 'Device Structure',
    devPar: {
      title: 'Device Parameters',
      model: 'Model',
      theory: 'Principle',
      pxSize: 'XY Pixel Size',
      moldingSize: 'Build Size or Forming Size',
      dist: 'Resolution',
      touchScreen: 'Touch Screen',
      printWays: 'Printing Method',
      power: 'Rated Power',
      volt: 'Voltage',
      suttle: 'Net Weight',
      deviceSize: 'Device Size',
      deviceLang: 'Device Language',
      lcdDisplayForm: 'LCD Display Forming',
      wucun: '5Inch 4k',
      lang: 'Chinese、English、Japanese'
    }
  },

  software: {
    learnMore: 'Learn more',
    banner: {
      value: 'Magic Pen Slicer is a fully functional and open-source slicing software that adopts a project-based workflow, systematic slicing algorithms, and a simple and easy to understand user interface, providing users with a very smooth printing experience',
      windows: 'Windows 10 (64 bit) or higher',
      mac: 'MacOS 10.15 or higher'
    },
    introd01: {
      label: '3D PRINTING SOFTWARE THAT STARTS WITH THE BEGINNING AND ENDS WITH THE END',
      value: 'Unlimited Creative Space',
      des: 'Magic Pen Slicer is an efficient 3D printing pre-processing software that provides an intuitive and easy-to-use interface, allowing users to easily complete all pre-processing tasks before 3D printing. It also has rich operational functions such as cutting, hollowing out, pasting, repairing, Boolean operation, creating new models, automatic support, shortcut keys, multi machine printing, virtual printing, etc. Whether you are a 3D printing professional or beginner, you can use it to improve work efficiency and printing quality',
    },

    introd02: {
      title: 'One Click Automatic Processing',
      des: 'One Click Quick Operation'
    },

    introd03: {
      title: 'Intuitive And User-Friendly Interface Preprocessing Software',
      des: 'One Click Processing'
    },

    ads: {
      ads2: [
        {
          label: 'Cluster Printing',
          value: 'Convenient operation, omitting manual adjustment steps, one click automatic distribution and arrangement of models, reducing tedious and repetitive steps, and achieving one-step distribution and arrangement'
        },
        {
          label: 'Image Algorithm',
          value: 'By independently developing algorithms for sub-pixel exposure of the model, anti aliasing printing is achieved to produce a silky smooth surface and display high-resolution images'
        },
        {
          label: 'Automatic support',
          value: 'Automatically detect the areas that need support in the model, solve suspended situations, prevent geometric surface damage, and achieve accurate and complex model support'
        },
        {
          label: 'Automatic layout',
          value: 'Convenient operation, omitting manual adjustment steps, one click automatic distribution and arrangement of models, reducing tedious and repetitive steps, and achieving one-step distribution and arrangement'
        },
        {
          label: 'Slice preview',
          value: 'Compare the model with the slice diagram, visually display the printing results of each layer of the model, accurately standardize the process, and fully demonstrate the beauty of exquisite craftsmanship'
        },
        {
          label: 'ZYSLR4',
          value: 'Exclusive slicing format, while also supporting STL, OBJ, and Plain3d format files, you can import models designed using various CAD software'
        },
      ],
  
    },
  },

  softwareSysReq: {
    title: 'SYSTEM REQUIREMENTS',
    tr: 'Magic Pen Slicer',
    td: [
      "Operating System",
      "Windows 10 (64) bit, MacOS 10.15 or above systems",
      "Browser",
      "Octopus Factory recommends using the latest version of the following web browsers to access it",
      "CPU",
      "Windows 10 (64) bit, MacOS 10.15 or above systems",
      "Disk Space",
      "8GB of available disk space (for installation)",
      "RAM",
      "At least 4GB RAM (recommended to use 8GB or larger space)",
      "Pointing Device",
      "mouse",
    ]
  },

  mainBoard: {
    purchase: 'Buy Now',
    detailParams: 'Detailed Parameters',
    param: 'Parameter',
    banner: {
      label: 'Genius UV curing control motherboard',
      value: 'Intelligent Navigator with One Stop Solution',
      des: `Compatible With Most LCD Exposure Screens In The Market, Designed With Customized Features, Equipment, And Upgraded Systems, Providing Technical Support`,
    },
    g3: {
      title: 'UV cured 3D printing silent motherboard provides a one-stop solution',
      des: 'The motherboard adopts FPGA to achieve mipi screen control, which is compatible with most LCD exposure screens on the market. It can design functions and customize products, devices, and systems. Engineers provide technology and layout customization - enriching user product lines.',
      pic: [
        {
          title:'CHIP',
          des: 'TMC2208 Silent Chip'
        },
        {
          title:'DUAL CORE',
          des: 'Dual Core Detachable'
        },
        {
          title:'EXPOSURE SCREEN',
          des: '14K Exposure Screen'
        }
      ]
    },

    g2: {
      title: 'UV cured 3D printing silent motherboard provides a one-stop solution',
      des: 'The motherboard adopts FPGA to achieve mipi screen control, which is compatible with most LCD exposure screens on the market. It can design functions and customize products, devices, and systems. Engineers provide technology and layout customization - enriching user product lines.',
      pic: [
        {
          title:'CHIP',
          des: 'TMC2208 Silent Chip'
        },
        {
          title:'Wi-Fi',
          des: 'Remote Printing Monitoring'
        },
        {
          title:'INTERFACE',
          des: 'Camera Interface'
        }
      ]
    },
    g1: {
      title: 'UV cured 3D printing silent motherboard provides a one-stop solution',
      des: 'The motherboard adopts FPGA to achieve mipi screen control, which is compatible with most LCD exposure screens on the market. It can design functions and customize products, devices, and systems. Engineers provide technology and layout customization - enriching user product lines.',
      pic: [
        {
          title:'CHIP',
          des: 'TMC2208 Silent Chip'
        },
        {
          title:'COMPONENTS',
          des: 'High Quality Components'
        },
        {
          title:'EXTERIOR',
          des: 'Personalized Look'
        }
      ]
    },

    mainBoard02: {
      G3:{
        title: 'PROFESSIONAL PLAYER, THE CORE CHOICE FOR LCD',
        des: 'Industrial Grade, Three Major Features (Buzzer, Wi Fi, Dual Core), Advanced DIY'
      },
      G2: {
        title: 'STRICT SELECTION OF QUALITY, COEXISTENCE OF PERFORMANCE AND APPEARANCE',
        des: 'Advanced Class, Wi Fi, Remote Printing And Video Surveillance, Consumer Grade Printers'
      },
      G1: {
        title: 'BEST PERFORMANCE OPTIMIZATION, COST FIRST CHOICE',
        des: 'Entry Level, Small Volume (2K, 4K Or Below Resolution), Consumer Grade Printer'
      }
    },
  
    mainBoard03: {
      G3:{
        title: 'Compatible With Multi Size Exposure Screens With 14K Ultra-high Resolution',
        value: 'Improve Printing Efficiency And Restore Vivid Details',
        des1: '14k',
        des2: '&',
        des3: 'Power Outage Continuation Mold Release Detection',
        des4: 'High running memory, 512M DDR, 16G EMMC/ARM architecture, small size, low power consumption, low cost, high performance, support for connecting network ports and Wi Fi, remote printing and other functions, support for printer power outage and continuous printing, demolding detection and automatic feeding, adapt to 2K, 4K, 8K, 14K exposure screens, mute IC, can reduce noise generated during printing'
      },
      G2:{
        title: '2-3 Times Running Speed, 4K High-Definition Image Quality',
        value: 'Compatibility And Stability, 16GB of Large Memory',
        des1: '4k',
        des2: '&',
        des3: 'Wi Fi Camera',
        des4: 'High running memory, 512M DDR, 16G eMMC/ARM architecture, small size, low power consumption, low cost, high performance, support for connecting network ports and Wi Fi, remote printing and other functions, support for remote video monitoring, can remotely and clearly view the entire printing process, adapt to 2K and 4K exposure screens, mute IC, can reduce noise generated during printing',
      },
      G1:{
        title: 'Internal Design Chip With Compact Size For Optimal Performance',
        value: 'High Quality Components And Personalized Appearance',
        des1: 'High Performance',
        des2: '&',
        des3: 'Low Cost And Low Power Consumption',
        des4: 'High running memory, 64M DDR, 512M SDNAND, ARM architecture, small size, low power consumption, low cost, high performance, support for connecting network ports and Wi Fi, remote printing and other functions, compatible with 2K and 4K exposure screens, and silent ICs to reduce noise generated during printing',
      }
    },

    cpu: {
      title: 'Silent Motor Drive, Low Power Consumption, Small Yet Powerful',
      des: 'Adopting MIPI high-speed interface -2.5 Gbps, supporting up to 14K MIPI screens, equipped with imported motor drive chip TMC2208, achieving ultra quiet effect under high-speed motion. SDIO is used between the main control and FPGA to ensure communication, ensuring the normal output and display of each layer of images. The main control adopts a high-performance processing chip from all sources, providing a universal stepper motor module interface, and external high current drive motor modules such as 2209, with strong adaptability.',
      des2: 'Providing top-notch performance for visual processing applications'
    },

    oem01: {
      top:{
        title: "Aesthetic Appearance &Practical Performance",
        des: 'Personalized Customization'
      },
      down: {
        title: 'OEM customization is a highly personalized service that allows users to design and choose the appearance, functionality, and performance of touch screens based on their own needs and preferences. This customized service not only provides more choices, but also makes the product more in line with the personalized needs of users.',
        des: 'OEM Customization',
        des2: 'Product Solution'
      }
    },

    oem02: {
      title: 'Technological Design',
      des1: 'Unconstrained forming process breaks through design limitations Implement clever designs that are not limited by structure',
      des2: 'Unlocking a new product concept, redefining product customization, and responding to the diverse needs of customers, we can provide product customization, functional design, etc., which can achieve flexible adjustments and fit the modern production concept of flexible manufacturing. Breaking through 3D printing technology, OEM customization brings added value to customers.',
      des3: 'Solution',
      des4: 'Upgrades can be made to product design, functionality, etc., and product upgrade plans are provided for customers to choose flexibly.'
    },
    bordShow02: {
      title: 'Dual Core Heart Allows For Free Selection And Pairing',
      des: 'Flexibly adopting different core boards to achieve rapid customization of motherboards, reducing research and development cycles, and providing customers with complete OEM customization solutions'
    },
    lastPage: {
      title: 'DEDICATED 3D PRINTING CONTROL SYSTEM',
      des1: 'Uniform Light System, Stronger Running Speed, And Intuitive UI Interface',
      des2: 'Self developed specifically for 3D printing control systems, working in collaboration with Genius main control solutions to achieve more efficient printing processes and deeply support the development of 3D printing technology.'
    }

  },

  resin: {
    banner: {
      label: 'LCD Desktop Grade Photosensitive Resin',
      value: 'The Coexistence Of Technological Aesthetics And Industry',
      des: `Resin designed specifically for Bloom-740w 3D printers, the flexibility of the material brings better results and a happier experience`,
      purchase: 'Buy Now'
    },

    swiper:{
      des1: 'ABS-Wash',
      des2: 'Water-Wash',
      des3: 'Resin model',
    },

    introduce:{
      top:{
        title:'CREATIVE APPLICATION BREAKTHROUGH MATERIAL INNOVATION LEADER'
      },
      left01:{
        title: 'ABS-Like Resin',
        des: 'ABS resin has excellent strength and durability, suitable for various applications. It has good tolerance to many chemicals. ABS is easy to shape, cut, and machine, suitable for various production processes. It can produce a smooth and glossy surface. ABS can be easily painted and surface treated to alter appearance or increase protection. ABS resin maintains stability within a certain temperature range. It has good absorption performance for impact and vibration.'
      },
      right02:{
        title:'Material characteristics',
        des1: 'ABS resin has excellent properties such as high impact strength, low water absorption, and is easy to process and shape. It also has good resistance to low temperature and creep, and good surface gloss',
        des2: 'High temperature resistance',
        des3: 'High solid color and color stability',
        des4: 'Excellent surface quality',
        des5: 'Excellent strength and durability'
      },
      left03:{
        title:'Material characteristics',
        des1: 'Water Water Resin has balanced performance, high tensile strength, ductility, and impact resistance, making it suitable for direct production of functional spare parts and end product casings',
        des2: 'Easy to clean',
        des3: 'fast curing',
        des4: 'Low Odor',
        des5: 'Suitable for high-resolution printing'
      },
      left04:{
        title: 'Water-Wash Resin',
        des:'The water washed resin model is washable, has good fluidity, high precision, environmental protection, wide compatibility, high cost-effectiveness, smooth surface of the finished product, and short curing time. While ensuring the printing performance of the model, it solves the problem of difficult release of printed molded products, greatly improves the printing success rate, and extends the service life of the release film. Resin ensures the strength of the model and does not excessively shrink, while improving printing speed, shortening curing time, and enhancing the printing efficiency of the machine.'
      }
    },

    introduce02:{
      title: 'FEATURES AND PERFORMANCE OF APPLICATION DISPLAY OF CRAFT WORKS'
    },

    argument:{
      title:'Basic Performance',
      des1:'diversified applications',
      des2: 'Different application dimensions, printing materials for different applications, meet the comprehensive needs of performance and aesthetics, provide high-performance, high-precision, and highly stable technicians and materials, cover existing applications, and support workers in developing more innovative applications',
      tab:{
        head:{
          cell01: 'Test items',
          cell02: 'unit',
          cell03: 'Testing standards',
          cell04: 'numerical value',
        },
        title:[
          'tensile strength',
          'Tensile modulus',
          'elongation at break',
          'bending strength',
          'flexural modulus',
          'Impact Strength',
          'hardness',
          'yield point elongation',
          'Maximum force point deformation',
          'maximum force'
        ]
      }
    }

  },
  coreOctoApp: {
    banner: {
      label: 'Octopus Nucleus',
      value: 'Octopus Core is an integrated 3D printing service platform that provides cloud slicing and remote task distribution, allowing you to enjoy the fun of 3D printing anytime, anywhere',
    },
    richRe: {
      label: 'HIGH QUALITY MODELS',
      value: 'Constantly Creative',
      des: 'Massive model resources provide you with a convenient and efficient way to access and utilize model resources, making it more convenient for your creative ideas to be realized. We also support the payment function of original models, allowing creators to earn profits from their own works'
    },
    
    cloudOp: {
      top: {
        title: 'VISUAL MANAGEMENT PLATFORM',
        des: 'The 3D digital printing full process visualization management platform has functions such as cloud slicing, real-time monitoring, video playback, and cloud task distribution, providing thoughtful and integrated services. With just one mobile phone, cloud 3D printing can be achieved'
      },
      thr: [
        {
          title: 'Real Time Monitoring',
          des: 'You can observe the running status of the printer at any time to ensure the stability and safety of the printing process'
        },
        {
          title: 'Video Playback',
          des: 'Automatically record the printing process to help users better diagnose printing faults'
        },
        {
          title: 'Cloud Slicing',
          des: 'Slicing processing is carried out through intelligent algorithms, and the sliced model effect can also be previewed, which can be printed after slicing'
        },
        {
          title: 'Cloud Task Distribution',
          des: 'Remote management can quickly send tasks and start work on both PC and mobile devices'
        }
      ]
    },
    range: {
      title: 'REMOTE PRINTING',
      des: 'Support remote sending and control of your 3D printer, and through online slicing, multiple devices can be switched to achieve remote integrated slicing printing'
    },

    cloudServer: {
      top: {
        title: 'CLOUD SERVICES',
        des: 'Cloud based online batch model slicing operation allows users to quickly experience slicing, which is simple, clear, intuitive, convenient, fast, and efficient. It provides 3D cloud storage space, reduces storage space, and can be accessed and edited at any time'
      },
      left: {
        title: 'Batch Model Slicing',
        des: 'You can upload multiple model files in bulk and slice multiple models quickly and accurately'
      },
      right: {
        title: 'Cloud Storage Space',
        des: 'Provide secure and reliable 3D cloud storage space, upload model files to the cloud, access and edit anytime, anywhere, making it convenient for you to store and manage your 3D models'
      }
    }
  },

  application:{
    banner:{
      title: 'EXPLORING THE ADVANTAGES OF 3D PRINTING TECHNOLOGY IN DIFFERENT FIELDS'
    },
    page:[
      {
        title:'Product Prototype',
        des:'3D printing technology can directly convert design files into solid models and quickly and flexibly create prototypes. This type of rapid prototyping not only greatly shortens the product development cycle and reduces production costs, but also allows for multiple design iterations to quickly validate and improve product design. Provide more efficient, convenient, and innovative solutions for product design and development.'
      },
      {
        title:'Digital Dentistry',
        des:"Light cured 3D printing technology plays an important role in traditional dental customization processes. With this technology, doctors can quickly generate accurate dental models based on the patient's oral condition, and customize dental restoration plans for patients. Compared with traditional manual production or the use of molds, UV cured 3D printing technology can not only complete the entire process faster, but also achieve higher accuracy and quality, effectively solving the precision problem that has plagued the dental industry for many years."
      },
      {
        title:'Printing The Future With The Power Of Innovation',
        des:'3D printing technology has a wide range of applications in the construction industry, including building modeling, building component manufacturing, innovative design, repair and maintenance, and sustainable building. Through customized production and precision manufacturing, it can help architects and designers visualize design concepts and ideas more intuitively, thereby creating more creative and artistic architectural works. In addition, it can also reduce waste and environmental impact during the construction process, injecting new vitality and momentum into the sustainable development of the construction industry.'
      },
      {
        title:'Handmade Model',
        des:'3D printing greatly simplifies the production process of the entire prototype model, while shortening the production cycle and saving costs, making the structural proportion of the model more accurate and meeting the precision requirements of the prototype model to the maximum extent. Customized production can meet the personalized needs of customers.'
      },
      {
        title:'Jewelry Design',
        des:'The UV cured 3D printing technology solves the limitations and cost issues of wax mold production, which is the core of jewelry manufacturing, making private customization possible.'
      }
    ]
  },

  knowCenter: {
     banner: {
      label: 'CREATING A BRAND WITH QUALITY',
     },
     product:[
      'Bloom-740w',
      'Magic Pen Slicer',
      'Octopus Nucleus',
      'Genius I',
      'Genius II',
      'Genius III',
    ],
    handBook:[
      'Product Manual',
      'software Manual',
      'Platform Manual',
      'Motherboard Manual'
    ]
  },

  belonging: {
    bread: {
      belonging: 'Diversity and Sense of Belonging'
    },
    banner: {
      label: 'Diversity and Sense of Belonging',
      value: 'We are building a diverse workforce and a culture of belonging, providing more people with the opportunity to imagine, design, and create a better world'
    }, 
    oppor: 'Expand Opportunities',
    change: 'Lead Change',
    intro1: 'Diversity sparks new ideas, creativity, and business growth. We are committed to fostering a diverse and inclusive environment at Octopus Works, welcoming individuals from various backgrounds, perspectives, and beliefs',
    intro2: 'As a company leading change, we have a responsibility to create opportunities for as many people as possible to participate in the future of work',
    lis: [
      {
        label: 'Diversity and Inclusion',
        value: 'Diverse Teams, Inclusive Culture'
      },
      {
        label: 'Talent and Development',
        value: 'Inspire vitality, support growth'
      },
      {
        label: 'Creativity',
        value: 'Breakthrough Innovation, Explore the Future'
      },
      {
        label: 'Sense of Belonging',
        value: 'Inclusion, Collaboration, Progress, Development'
      },
      {
        label: 'Responsibility and Trust',
        value: 'Customer First, Collaborate for Win-Win'
      },
      {
        label: 'Present and Future',
        value: 'Pioneer Technological Innovation, Integrate Future Technology into Life'
      }
    ]
  },
  csrp: {
    title: 'Corporate Social Responsibility Policy', 
    txtLis: [
      'Octopus Works was founded in 2017, with innovation, carrying forward traditions, and professional knowledge as its core values. Each 3D printer, as well as resins and motherboards, draws inspiration from the technological inheritance of 3D technology research and development. They tell the legendary story of the profound heritage of 3D computer graphics and reflect a positive and poetic worldview',
      `Respecting the natural environment as well as the interests of employees and society is one of Octopus Works' important corporate cultures. This policy will explain how Octopus Works fulfills corporate social responsibility to customers, employees, shareholders, supply chain partners, and the entire society`,
      'Octopus Works is committed to creating a sustainable future for business, society, and the environment. Integrating corporate social responsibility into the exquisite 3D technology of Octopus Works and sharing this concept with all stakeholders. Because Octopus Works firmly believes that everyone can contribute to sustainable development',
      `Octopus Works actively supports the concept of a circular economy. The company seeks to avoid the linear model of 'take, make, dispose' as much as possible, endorsing innovative circular approaches to ensure products, components, and materials are used to their fullest extent. Starting from the design phase, efforts are made to minimize waste`,
      `As a member of the Yunlian Group, Octopus Works conducts its business activities with honesty as the foundation and integrity as the priority. At the same time, it respects all stakeholders in accordance with the group's 'Code of Business Conduct`,
      'Octopus Works conducts business in accordance with the Universal Declaration of Human Rights, respecting the rights of employees and partners. To address, supervise, and minimize the impact on human rights, Octopus Works adheres to the UN Guiding Principles on Business and Human Rights, complying with all applicable laws and regulations. In addition, Octopus Works regularly reviews its corporate social responsibility and oversight policy performance, implementing continuous improvement'
    ],
    employer: {
      label:'Responsible Employer',
      des: `Octopus Works emphasizes the core values of the company, values teamwork, and is committed to providing employees with a safe, healthy, fair, and inspiring work environment. Detailed principles regarding health and safety are outlined in its 'Environmental, Health, and Safety Policy.' Octopus Works provides development opportunities for employees and ensures they have the skills to manage business. The company values multiculturalism and has consistently promoted equal opportunities in the processes of recruitment, hiring, development, and retention`,
    },
    purchasing: {
      label: 'Responsible Procurement',
      des: `Octopus Works is committed to responsibly sourcing raw materials, respecting business ethics, human rights, labor rights, and the natural environment. Additionally, Octopus Works requires suppliers to adhere to the Yunlian Group's 'Supplier Code of Conduct,' ensuring that their own businesses align with these guiding principles. Octopus Works expects suppliers to communicate these principles to their respective subcontractors and suppliers, encouraging them to incorporate these principles into their business policies and guidelines. Octopus Works will continue to collaborate with industry organizations and business partners to promote best practices throughout the value chain. Octopus Works will seek solutions to source raw materials in a sustainable manner for the long term`
    },
    envir: {
      label: 'Environmental Responsibility',
      des: 'Octopus Works is continuously committed to improving environmental management to reduce its impact on the environment. We focus on seeking innovative solutions to promote a circular economy. As part of the Yunlian Group, we advocate for science-based reduction, providing us with a clear development path, leading us to reduce our impact on climate change by 2030, and ensuring that our business can grow in a more environmentally friendly manner in the future'
    },
    inver: {
      label: 'Social Investment',
      des: 'Octopus Works is committed to promoting its core values, including innovation, carrying on traditions, and professional knowledge, to share its positive outlook on life with the community. Octopus Works actively participates in numerous community investment activities in the fields of culture, technology, arts, heritage, education, research, and the environment, aiming to make positive contributions to society'
    }
  },

  profile: {
     about: {
      label: 'About US',
      des1: `Shenzhen Octopus Works Technology Co., Ltd`,
      des2: `Established in 2017, headquartered in Bao'an District, Shenzhen, Guangdong Province. As a digital production solution supplier for 3D printing, the company's main business includes 3D printing equipment, software, consumables, technical solutions, etc. The company independently researches and innovates, and fully possesses independent intellectual property rights in product technology. Octopus Factory is a leading global provider of graphics and image information processing, parallel computing, heterogeneous computing, large-scale integrated circuits, and mechanical automation solutions. Currently, it focuses on the field of 3D printing additive manufacturing, adheres to digital management and operation, continues to explore and innovate, and strives to achieve a digital small world, creating a colorful world mainly based on digitization and supplemented by visual information.`
    },

    framework: {
      title: 'PRODUCT ARCHITECTURE',
      lis: [
        {
          label: 'Bloom-740w',
          value: 'Consumer grade UV cured 3D printer'
        },
        {
          label: 'Photosensitive Resin',
          value: '3D printing consumables'
        },
        {
          label: 'Genius Control Motherboard',
          value: 'OEM customized'
        },
        {
          label: 'Magic pen Slicer',
          value: '3D slicing preprocessing software'
        },
        {
          label: 'Octopus Core Cloud Platform',
          value: '3D printing service platform'
        },
      ]
    },

    authentication: {
      label: 'INTERNATIONAL CERTIFICATION',
      text:[
        {
          title: 'FCC Certification',
          des: 'electromagnetic compatibility'
        },
        {
          title: 'PSE Certification',
          des: 'Product Safety'
        },
        {
          title: 'TELECT Certification',
          des: 'Product standards'
        }
      ]
    },

    culture: {
      title: 'CORPORATE CULTURE',
      text:[
        {
          title: 'Values',
          des: 'Integrity, Exploration, Innovation, Technology',
          tran: 'Values'
        },
        {
          title: 'Vision',
          des: 'Trusted partners lead the way to success',
          tran: 'Vision'
        },
        {
          title: 'Mission',
          des: 'Becoming a leader in the 3D motherboard industry',
          tran: 'mission'
        }
      ]
    },

    patent: {
      title: 'QUALIFICATION PATENTS'
    }

  },

  installDownload: {
    title: 'Download and Install Software',
    content: [
      {
        name: 'Prepare for Installation',
        label: 'Collect Information and Get Your Computer Ready',
        value: 'Before installing your Magic Pen Slicer software, please prepare your system and gather all the necessary information',
        lis: [
          {
            label: '1. Check the Necessary Requirements',
            value: [
              'Check System Requirements',
              'Confirm that you are using certified and recommended computer hardware',
              'Read the Release Notes for the Software You Are Installing'
            ]
          },
          {
            label: '2. Obtain a License',
            value: 'You need administrative privileges to install. To verify in Windows, go to Control Panel > User Accounts > Manage User Accounts'
          },
          {
            label: '3. Install System Updates and Disable Antivirus Programs',
            value: 'If you have pending operating system updates, please install them and restart. Consider temporarily disabling antivirus programs as they often interfere with installations'
          },
          {
            label: '4. Close all running applications except the installation program'
          }
        ]
      },
      {
        name: 'Download your software',
        lis: [
          {
            label: 'Where to Download Products and Updates',
            value: 'To download the software, please visit the Octopus Works official website',
            lis: [
              {
                label: 'Download Software',
                value: [
                  '1. Visit https://octopusworks.com/ Octopus Works official website',
                  `2. Click on 'Products' in the menu bar, and then click on 'Magic Pen Slicer' in the expanded list to go to the download page`,
                  '3. Select the software you want to download'
                ]
              },
              {
                label: 'Update Software',
                value: 'To update the software, click on the menu bar - Help - Check for Updates within the software. Alternatively, you can download the latest version of the software from the Octopus Works official website'
              }
            ]
          },
          {
            label: 'Download and Install',
            lis: [
              {
                label: 'Choose the download method that suits your installation',
                value: 'You can download it from the Products section on https://octopusworks.com/ by clicking on Magic Pen Slicer'
              },
              {
                label: 'Installation',
                value: `Download Magic Pen Slicer (3D printing slicing software) from the Octopus Works official website, check the license agreement, choose the software installation location (it is recommended to install it in the D drive), click Next. Start the installation, and click Finish when the installation is complete` 
              }
            ]
          }
        ]
      }
    ],
  },
  support: {
    title: 'Product Support',
    banner: {
      label: 'Product Support',
      value: 'Introduce the latest technology and environmental concepts, intelligent design'
    },         
    soft: {
      sysReq: 'Product System Requirements',
      name: 'Magic Pen Slicer',
      proVer: 'Covered Products and Versions',
      des: 'Octopus Works Magic Pen Slicer 2023 software is supported by the following 64-bit operating systems and requires a system with at least the following 64-bit hardware',
      form1: {
        soft: 'Software',
        op: {
          label: 'Operating System',
          value: 'Windows 10 (64-bit), MacOS 10.15 and above'
        },
        bro: {
          label: 'Browser',
          value: 'Octopus Works recommends using the latest version of the following web browsers to access online supplementary content:'
        }
      } ,
      form2: {
        hard: 'Hardware',
        cpu: {
          label: 'cpu',
          value: 'Windows 10 (64-bit), MacOS 10.15 and above'
        },
        vch: {
          label: 'Graphics Card Hardware',
          value: 'For a detailed list of recommended systems and graphics cards, please refer to the Magic Pen Slicer Certified Hardware'
        },
        diskSp: {
          label: 'Disk Space',
          value: '8GB available disk space (for installation)'
        },
        ram: {
          label: 'RAM',
          value: 'At least 4GB RAM (8GB or more recommended)'
        },
        pointDev: {
          label: 'Pointing Device',
          value: 'Mouse'
        }
      }
    },
    gBoard: {
      name: 'Genius Control Board',
      proVer: 'Covered Products and Versions',
      lis: [
        {
          name: 'Genius I Series Silent Mainboard',
          expScr: {
            label: 'Exposure Screen',
            value: 'Supports up to 4K Resolution'
          },
          power: 'Power',
          weight: 'Weight',
          intSto: 'Memory',
          intSup: {
            label: 'Interface Support',
            value: 'I2C、UART、SPI、GPIO etc'
          }
        },
        {
          name: 'Genius II Series Silent Mainboard',
          expScr: {
            label: 'Exposure Screen',
            value: 'Supports up to 4K Resolution'
          },
          power: 'Power',
          weight: 'Weight',
          intSto: 'Memory',
          intSup: {
            label: 'Interface Support',
            value: 'I2C、UART、SPI、GPIO etc'
          }
        },
        {
          name: 'Genius III Series Silent Mainboard',
          expScr: {
            label: 'Exposure Screen',
            value: 'Supports up to 12K Resolution'
          },
          power: 'Power',
          weight: 'Weight',
          intSto: 'Memory',
          intSup: {
            label: 'Interface Support',
            value: 'I2C、UART、SPI、GPIO、Type-C etc'
          }
        },
      ]
    }
  },

  technicalParam: {
    title:'TECHNICAL PARAMETER',
    table: [
      {
        th: 'Fuselage',
        td:[
          'Print Size',
          '163.92 * 102.4 * 180mm ³  (length * width * height)',
          'Shaft mounting plate',
          'magnesium alloy',
          'Chassis body',
          'ABS plastic',
          'Base',
          'ABS plastic',
          'Outer cover',
          'ABS plastic'
        ]
      },
      {
        th: 'print Head',
        td:[
          'Connection structure',
          'Press type buckle'
        ]
      },
      {
        th: 'Build Platform',
        td:[
          'size',
          '163.92mm * 102.4mm (length * width)',
          'platform',
          'sand blast',
          'levelling',
          'Screw leveling'
        ]
      },
      {
        th: 'Speed',
        td:[
          'Print Speed',
          '20-60 mm/h',
          'Maximum speed of motor movement',
          '10000 mm/s²',
        ]
      },
      {
        th: 'Burial',
        td:[
          'Light source cooling fan',
          'Closed loop',
        ]
      },
      {
        th: 'Support Consumables Types',
        td:[
          'ABS-Like Resin，Water-Wash Resin',
          'Suitable for printing',
        ]
      },
      {
        th: 'Sensor',
        td:[
          'Lower limit sensor',
          'photoelectric sensor',
        ]
      },
      {
        th: 'Physical Size',
        td:[
          'Product size',
          '260*260*465 mm³ (length*width*height)',
          'net weight',
          '6.55kg'
        ]
      },
      {
        th: 'Electrical parameters',
        td:[
          'Input Voltage',
          '100-240 VAC, 50/60 Hz',
          'maximum power',
          '60 W',
          'power interface',
          'Pin Zi AC socket'
        ]
      },
      {
        th: 'Electronics',
        td:[
          'display screen',
          '5-inch 800 x 480 capacitive touch screen',
          'communication',
          'Wi-Fi',
          'storage',
          'USB',
          'Operation interface',
          'Touch screen, mobile app, computer application',
          'Motion Controller',
          'Single core ARM Cortex-A7 processor'
        ]
      },
      {
        th: 'Software',
        td:[
          'Magic pen slicer',
          'Support other exportable zip format files',
          'operating system',
          'MacOS、Windows'
        ]
      }
    ]
  },


  bordParamInfo:{
    title: 'PARAMETER INFORMATION',
    G1:{
      tr: 'Genius I',
      td:[
        'physical dimension',
        '120*80*16mm',
        'Hole position distance',
        '114*74mm',
        'Hole diameter',
        '3mm',
        'input voltage',
        '24V',
        'power interface',
        '5.08 2P',
        'interface type',
        'MIPI',
        'microprocessor',
        'Cortex-A7',
        'Motor driver chip',
        'TMC2208',
        'Support for touch screen',
        '3.5/5-7 inch color touch screen',
        'Support printing screen',
        '5.96inch、DBT066MONO(6.6inch 4K Mono)、DXQ608-X04(6.08inch 2K Mono)、123D089CFSP01-00(8.9inch 4K Mono)',
        'Support slice file format',
        'zip、zyslr4',
        'Recommended slicing software',
        'Magic Pen Slicer',
        'data interface',
        'USB drive, Ethernet port'
      ]
    },
    G2:{
      tr: 'Genius II',
      td:[
        'physical dimension',
        '100*80*18mm',
        'Hole position distance',
        '94*74mm',
        'Hole diameter',
        '5.08mm',
        'input voltage',
        '24V',
        'power interface',
        '5.08 2P',
        'interface type',
        'MIPI',
        'microprocessor',
        'Cortex-A7',
        'Motor driver chip',
        'TMC2208',
        'Support for touch screen',
        '3.5/5-7 inch color touch screen',
        'Support printing screen',
        '5.96inch、DBT066MONO(6.6inch 4K Mono)、DXQ608-X04(6.08inch 2K Mono)、123D089CFSP01-00(8.9inch 4K Mono)',
        'Support slice file format',
        'zip、zyslr4',
        'Recommended slicing software',
        'Magic Pen Slicer',
        'data interface',
        'USB drive, Ethernet port'
      ]
    },
    G3:{
      tr: 'Genius II',
      td:[
        'physical dimension',
        '120*80*18mm',
        'Hole position distance',
        '114*74mm',
        'Hole diameter',
        '5.08mm',
        'input voltage',
        '24V',
        'power interface',
        '5.08 2P',
        'interface type',
        'MIPI',
        'microprocessor',
        'Cortex-A7',
        'Motor driver chip',
        'TMC2208',
        'Support for touch screen',
        '3.5/5-7 inch color touch screen',
        'Support printing screen',
        '5.96inch、DBT066MONO(6.6inch 4K Mono)、DXQ608-X04(6.08inch 2K Mono)、123D089CFSP01-00(8.9inch 4K Mono)',
        'Support slice file format',
        'zip、zyslr4',
        'Recommended slicing software',
        'Magic Pen Slicer',
        'data interface',
        'USB drive, Ethernet port'
      ]
    }
  },


  warranty:{
    title: 'WARRANTY POLICY',
    instructions:'Our Warranty Covers The Following:',
    des: 'Our warranty covers the following: Bloom-740w comes with a 1-year warranty, motherboard and touch screen come with a 1-year warranty (complimentary items are not covered).',
    collapse:[
      {
        label: 'Return and Exchange Policy',
        li:[
          'Within 7 natural days from the date of receipt, if any of the following conditions are met, we provide a return/exchange service. You can apply for a return/exchange service through the original purchase channel:',
          '1. The appearance of the product should be free of damage, scratches, stains, cracks, deformations, etc; Random accessories, tools, gifts, packaging, manuals, etc. are required to be complete and intact. According to our after-sales inspection, if the returned product is found to have damaged appearance, incomplete accessories, missing instructions, or problems caused by not packaging and mailing according to the original packaging, the customer shall bear the corresponding costs. The specific losses and costs shall be checked and calculated by professional after-sales personnel after being returned.',
          '2. Non quality issue returns, if consumables are used, the customer shall bear the cost of the consumables; Due to the fact that the packaging paper box cannot be reused after return, the customer needs to bear the packaging cost. The specific losses and costs need to be checked and calculated by professional after-sales personnel after being sent back.',
          '3. Unable to provide original purchase vouchers, three guarantees vouchers, and related vouchers, or if there are signs of forgery or alteration in the purchase vouchers, refuse to provide return/exchange services.',
          '4. Refuse to provide return/exchange services for products that have been opened for use and affect secondary sales.',
          "5. It was found that the product was damaged due to transportation, but it was not rejected on the spot, and the logistics company's proof of damage was not provided afterwards, so the return/exchange service was refused.",
          '6. For returns/exchanges caused by reasons such as dislike, unwillingness, wrong order, improper use, and dissatisfaction with the product, the customer shall bear the return shipping cost themselves.',
          '7. When exchanging goods, the customer needs to send the product back first, and the company will arrange for timely shipment after receiving the product.',
          'Note: Please keep your original purchase voucher, shopping invoice, warranty card, and related credentials as important basis for after-sales service.',
          'The platform supports a 7-day no reason return policy, which starts from the time the product is signed for and lasts for 168 hours; The return shipping cost of the product needs to be borne by the user',
          'Due to product quality issues and non-human damage, you can contact Octopus Factory for exchange service within 15 days after receiving the goods'
        ]
      },
      {
        label: 'Warranty Policy',
        li:[
          '1. From the date of purchase, the printer, motherboard, and touch screen equipment are entitled to a one-year free warranty service, excluding defective LCD screens, release films, vulnerable parts, and gifts.',
          '2. The warranty period for vulnerable parts (nozzle kit, glass, etc.) is three months.',
          '3. Maintenance during the warranty period: If your product does not fall, get soaked in water, or undergo unauthorized disassembly or modification, and it is determined that the fault is caused by poor materials, craftsmanship, or functionality, our after-sales testing will determine that it is a quality problem. The company will provide free maintenance or replace spare parts. If it is a non quality issue caused by human factors, the customer is responsible for the corresponding replacement parts cost and does not need to bear the repair cost.',
          '4. Maintenance outside the warranty period: The customer shall bear all expenses incurred during the repair process, including labor costs, parts costs, packaging costs, round-trip shipping costs, etc.'
        ]
      },
      {
        label: 'The Following Situations Cannot Be Included In The Warranty Scope',
        li:[
          '1. Unable to provide valid machine manufacturing codes, purchase vouchers, and warranty cards, or information that has been altered, blurred, or unrecognizable.',
          '2. Component damage caused by normal wear and tear during use.',
          '3. Failure to follow the instructions, incorrect installation, use, and operation, or unauthorized modification of the product resulting in product malfunction or damage.',
          '4. Causing product malfunction or damage due to use in non specified working environments.',
          '5. Product malfunction or damage caused by abuse (exceeding workload, etc.) or improper maintenance (dampness, liquid ingress, soaking, mold, etc.).',
          '6. Due to uncontrollable factors (such as fire, earthquake, lightning, flood, etc.) causing product malfunction or damage.',
          '7. Equipment malfunction or damage caused by the use of other branded components or low-quality consumables.',
          '8. Products repaired by unauthorized service points.',
          "9. It was found that the product was damaged due to transportation, but it was not rejected on the spot, and the logistics company's cargo damage certificate could not be provided afterwards."
        ]
      }
    ]
  },


  faq: {
    title: 'FREQUENTLY ASKED QUESTIONS',
    lis: [
      {
        label: 'Genius Control Motherboard',
        collapse:[
          {
            problem: 'Q: What Is A Genius Control Motherboard?',
            li:[
              'A: Genius control motherboards are compatible with most LCD exposure screens on the market, and can provide technical and layout customization for designing features and customizing products, devices, and system engineers.',
              'Implementing mipi screen control using FPGA',
              'OEM customized hardware features and models',
              'Mute IC to reduce noise generated during printing',
              'Connect network port and Wi Fi, remote printing control',
              'Using CRC verification to ensure data consistency'
            ]
          },
          {
            problem: 'Q: What Can Genius Control Motherboards Be Used For?',
            li:[
              'A: 1 Provide basic printing control logic to shorten product development cycle',
              '2. Provide external interfaces to quickly achieve customized requirements',
              '3. Connect to the application for remote operation and monitoring',
              '4. Using Linux system and Qt to present high-definition UI interface'
            ]
          }
        ]
      },

      {
        label: 'Photosensitive Resin',
        collapse:[
          {
            problem: 'Q: What Are The Characteristics Of ABS Resin?',
            li:[
              'A: 1 Has fine surface quality and can be used to assemble workpieces by tapping teeth.',
              '2. High strength and precision, the finished product has good toughness, excellent molding, and good impact resistance.',
              '3. Can be cut with a knife, with manual carving characteristics, and the surface is scratch resistant after deep curing.'
            ]
          },
          {
            problem: 'Q: What Are The Characteristics Of Water Washed Resin?',
            li:[
              'A: 1 Environmental protection and safety',
              'Water washed photosensitive resin has a very low viscosity, good fluidity, low odor, minimal safety hazards, and will not cause pollution.',
              '2. High precision',
              'Water washed UV cured resin has high molding accuracy, smooth printed surface, and clear and visible printing details.',
              '3. Good performance',
              'The mechanical properties of water washed resin, such as strength, toughness, and rigidity, are balanced and not easily brittle.',
              '4. Easy to print',
              'Low viscosity and high success rate of release make it easier to remove the support of water washed UV cured resin. Easier processing and more aesthetically pleasing models.',
              '5. Widely applicable',
              'Water washed photosensitive resin can be used for printing anime figurines, construction industry parts, etc.'
            ]
          }
        ]
      },

      {
        label: 'Bloom-740w',
        collapse:[
          {
            problem: 'Q: Leveling Operation?',
            li:[
              'A: When the distance between the printing platform and the screen is too high, it will cause the model to not form on the printing platform, so we need to level it first.',
              'First, click on "Motor" on the homepage of the printer touch screen, click on the rising platform, and then loosen the screw of the material slot to take out the material slot',
              'Slightly loosen the leveling screw, place an A4 paper on the exposure screen, and lay it flat',
              'Click on the motor on the homepage, click "Leveling", the platform will descend to the exposure screen, adjust the platform position and angle, tighten the leveling screw clockwise, and the paper cannot be pulled out of the platform, indicating successful leveling',
              'After successful leveling, please raise the platform and place it in the material chute'
            ]
          },
          {
            problem: 'Q: Installation Of Material Trough?',
            li:[
              'A: 1 Firstly, remove the protective film on the back of the resin trough and the exposure screen.',
              '2. Place the material trough in the groove above the exposure screen and adjust the position to align with the screw opening.',
              '3. Place the two red material slot screws in the screw holes and rotate clockwise until they are tightened.'
            ]
          },
          {
            problem: 'Q: Loading Steps?',
            li:[
              'A: 1 First, adjust the printing platform to the highest point, then gently shake the resin bottle up and down for 15 seconds to evenly distribute it.',
              '2. Place the funnel at one corner of the material trough, open the resin bottle, and pour the resin into the funnel at a constant speed, allowing it to slowly enter the material trough and filter out excess bubbles.',
              'The maximum capacity of the slot is 550ml, please do not exceed the maximum value',
              'During the loading process, please wear gloves throughout the entire process'
            ]
          },
          {
            problem: 'Q: How To Avoid Model Misalignment And Layering During The Printing Process?',
            li:[
              'A: 1 Check if the model support is sufficient. When the model is not properly supported and the density is insufficient, it is easy to cause slight shaking of the model during printing and demolding. At this point, it is recommended to increase the support density or manually add support to fix the model.',
              '2. Check if the release film of the material box is loose. If there is any looseness, it is recommended to tighten the release film steel ring.',
              '3. Adjust the printing parameters appropriately.'
            ]
          },
          {
            problem: 'Q: Preparation Before Printing?',
            li:[
              'A: 1 To import an stl model, you can download the model from the model library in Magic Pen Slicer software, or download the model from the Octopus Core model website and export the slice file using slicing software.',
              '2. Consumables: Before applying the photosensitive resin, gently shake it left and right. Do not shake vigorously to produce a large number of bubbles. Do not directly contact the skin with photosensitive resin or eyes. Be sure to wear gloves. If it causes skin allergies or discomfort, rinse with water or alcohol. If the situation is serious, seek medical attention. Consumables selection, it is recommended that the manufacturer propose consumables to prevent damage to the printer. When photosensitive resin is not in use, it can be filtered and then recycled into bottles, or covered with something to prevent direct sunlight and strong light exposure, and to prevent dust from entering.',
              '3. Check the platform: Before using the machine for printing, check whether the printing platform is level, and whether the screws on the platform and at both ends of the material slot are tightened to avoid affecting the printing.',
              '4. Check screen exposure: Before curing with a 3D printer, observe if the screen is illuminated properly. Please wear UV resistant glasses for observation. When testing the screen, please remember not to look directly at the screen to avoid damaging the eyes. Check if impurities inside the groove affect printing, and do not use metal blades to contact the release film inside the groove. Determine whether the exposure meets the specified range to avoid failure due to subsequent exposure or insufficient exposure.'
            ]
          },
          {
            problem: 'Q: Printing Operation?',
            li:[
              'A: 1 First, save the model generation. zyslr4/. zip file to a USB drive.',
              '2. Insert the USB flash drive and wait for the screen to display the USB flash drive inserted pop-up window.',
              '3. Click on the "USB" tab, select the file, click on the model file, select printing parameters, and start printing.'
            ]
          },
          {
            problem: 'Q: Is Printing Completed And Processed?',
            li:[
              'A: 1 Before carrying out any cleaning steps, please ensure that appropriate safety measures are taken, including wearing gloves and goggles. Some resins and cleaning solutions may be harmful to the skin and eyes.',
              '2. Gently remove the printed model from the printing platform, being careful not to damage the model.',
              '3. Usually, the surface of the printed model will have some uncured resin attached. Use alcohol or other appropriate cleaning solution to place the model in a cleaning container, ensuring that it covers the entire model. Soak the model in cleaning solution for about 1-3 minutes. Remove residual resin, soak and wipe clean before cleaning and polishing.',
              '4. You can use a soft brush to gently wipe the surface of the model to ensure cleanliness.',
              '5. Place the cleaned model into a UV exposure box or use a UV lamp for a second curing, which takes 2-3 minutes.',
              '6. If necessary, you can coat or color the model to enhance its appearance.'
            ]
          },
          {
            problem: 'Q: What Is The Content And Solution Of The Malfunction?',
            li:[
              'A: 1 Resin issues',
              'Resin contamination or prolonged static resin precipitation can cause color deviation in model printing. Please use resin in the correct way.',
              '2. There is abnormal noise or jamming problem with the screw guide rail',
              'Long term non use of lubricating grease can cause abnormal noise in the screw guide rail. Simply apply lubricating grease to the screw guide rail.',
              '3. Platform attachment issues',
              'The printed model may not attach properly to the printing platform, resulting in the inability to fix the printed model and causing it to shift or fall off. It may be due to insufficient solidification time of the bottom layer of the model or problems with the model itself. It is recommended to readjust the model support and add a base.',
              '4. Incomplete resin curing',
              'If the resin is not fully cured, it may cause the surface of the printed item to be sticky, the details to be unclear, or there may be uncured areas. It is recommended to extend the curing time according to the size of the model.',
              '5. Software settings issues',
              'Incorrect printing parameters, incorrect file formats, and incorrect support settings may result in poor printing model quality or inability to print successfully.',
              '6. Curing time issue',
              'Incorrect curing time setting may result in insufficient or excessive resin curing, affecting printing results. Please set the curing time reasonably based on the size of the model.'
            ]
          },
        ]
      },

      {
        label: 'Magic pen slicer',
        collapse:[
          {
            problem: 'Q: What Is 3D Slicing Software?',
            li:[
              "A: 1 Slicing software is a 3D software that can convert digital 3D models into printing codes that can be recognized by the 3D printer, allowing the 3D printer to start executing printing commands. In layman's terms, it means using slicing software to calculate and automatically generate the path of a 3D printer, and the printer reads the path to print the model",
              '2. The specific workflow is that the slicing software can horizontally cut models in formats such as STL according to the settings you choose, thereby obtaining individual floor plans and calculating how much consumables and time the printer needs to consume. Then, these information will be uniformly stored in the GCode file and transmitted to the 3D printer through SD cards, data cables, networks, and other means, and it will start working.'
            ]
          },
          {
            problem: 'Q: Is Slicing Software Used?',
            li:[
              'A: 1 Click on "File" to open the STL format model file. If the file is damaged, you can click on "Auto Repair File" to fix it.',
              '2. Adjust model size: Click "Zoom", then select "Lock Scale" on the right interface to adjust the desired model size according to the scale.',
              '3. Model support: Confirm the size ratio of the model, click on "support" above the model, find the "suspended detection" button in the upper left corner, and click on it. Confirm the detection on the right interface. After the detection is completed, click on "automatic support" in the upper left corner.',
              '4. Slicing: After confirming the support is complete, click "Slicing" above the model. If you need to set slicing parameters, you can click on Slicing Settings to adjust them. After saving the slicing settings, click "Confirm" on the right interface to start slicing. After slicing is completed, click the "Save" icon in the upper left corner. After confirming the print preview, click "Save Document" to save the document.'
            ]
          },
          {
            problem: 'Q: Adding Support Techniques?',
            li:[
              'A: 1 Click on "File" to open the STL format model file. If the file is damaged, you can click on "Auto Repair File" to fix it.',
              '2. After automatically adding support, it needs to be checked again. In some areas where the support density is insufficient, it is necessary to manually add some, or in some areas where there is no support at the bottom, it can also be increased appropriately.',
              '3. When printing the model without sticking it to the bottom, be sure to have sufficient support at the closest point to the bottom.',
              '4. For models that require shell extraction and drilling, support should be added after the shell extraction and drilling.',
              '5. Some models have uneven bottoms, which can be raised and printed appropriately, or offset downwards a bit (if the bottom requirements are not high), which is equivalent to cutting a small part of the bottom flat.',
              '6. Remember to drill holes in the hollow model or shell after extraction to allow the resin inside to flow out.'
            ]
          },
          {
            problem: 'Q: Is The Slice File Unrecognized?',
            li:[
              'A: The file format is incorrect. Check if the format of the slice file matches the printer. If the file is damaged or the new version of the slicing software is incompatible with the old firmware of the printer, you can download the latest firmware from the Octopus Factory official website to update the printer.',
            ]
          }
        ]
      },

      {
        label: 'Octopus Nucleus',
        collapse:[
          {
            problem: 'Q: What Are The Steps For Printing The Octopus Core App?',
            li:[
              'A: 1 Download the Octopus Core app, open the workbench of the app, add a printer device, and bind it (note, please make sure your phone and printer are on the same WiFi network).',
              '2. After confirming successful device binding, select the device, import the file, and perform online slicing processing.',
              '3. After the file processing is completed, select Start Printing and you can open the workbench to monitor the printer situation at any time.'
            ]
          },
          {
            problem: 'Q: How To Download The Model?',
            li:[
              'A: Click on the model library, select different model categories, get your favorite model, click on the model to view the model, and download the model.'
            ]
          },
          {
            problem: 'Q: What Are The Steps For Printing On A Cloud Platform?',
            li:[
              'A: 1 Log in to your account for subsequent operations.',
              '2. Click on the workbench, add the device you want to print, create a printing task, select the slice file and device for printing.',
              '3. Print tasks can be viewed on the workbench page.'
            ]
          }
        ]
      },
      
    ]
  }
}

