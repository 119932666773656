window.onscroll = function (e) {
  // console.dir(e.target);
  try {
    const tabNav = document.querySelector('#tabNav')
    const linkUs = document.querySelector('#linkUs')
    const phishing = document.querySelector('#phishing')
    const pirate = document.querySelector('#pirate')
    const report = document.querySelector('#report')
    // console.log('窗口滚动',document.documentElement.scrollLeft);
    const left = document.documentElement.scrollLeft
    // tabNav.style.backgroundColor = 'pink'
    if (tabNav) {
      tabNav.style.left = '-' + left + 'px'
    }
    if (linkUs) {
      linkUs.style.left = '-' + left + 'px'
    }
    if (phishing) {
      phishing.style.left = '-' + left + 'px'
    }
    if (pirate) {
      pirate.style.left = '-' + left + 'px'
    }
    if (report) {
      report.style.left = '-' + left + 'px'
    }
  } catch (error) {

  }

}