export default {
  position: '您的位置',
  homeB: '首页',
  gCB: 'Genius 控制主板',
  gDetail: [
    {
      zh: '显示屏接口',
      en: 'Display screen interface', 
      ja: 'ディスプレイ スクリーン インターフェース'
    }, 
    {
      zh: '电源/电机/限位/风扇',
      en: 'Power supply/ Motor/ Limit switch/Fan',
      ja: '電源/モーター/リミットスイッチ/ファン'
    }, 
    {
      zh: 'Genius III 主控芯片',
      en: 'Genius III main control chip',
      ja: 'Genius III メインコントロールチップ'
    }, 
    {
      zh: 'FPGA芯片',
      en: 'FPGA chip',
      ja: 'FPGA チップ'
    }, 
    {
      zh: '以太网',
      en: 'Ethernet',
      ja: 'イーサネット'
    }, 
    {
      zh: '天线',
      en: 'Antenna',
      ja: 'アンテナ'
    }, 
    {
      zh: 'DOR芯片',
      en: 'DOR chip',
      ja: 'DOR チップ'
    }, 
    {
      zh: 'EMMC芯片',
      en: 'EMMC chip',
      ja: 'EMMC チップ'
    },
    {
      zh: 'U盘接口',
      en: 'USB flash drive interface',
      ja: 'USBフラッシュドライブ インターフェース'
    },
    {
      zh:'Genius II主控芯片',
      en: 'Genius II main control chip',
      ja: 'Genius II メインコントロールチップ'
    },
    {
      zh:'A328电机芯',
      en: 'A328 motor core',
      ja: 'A328 モーターコア'
    },
    {
      zh: 'Genius I 主控芯片',
      en: 'Genius I main control chip',
      ja: 'Genius I メインコントロールチップ'
    },
    { 
      zh: '触控屏接口',
      en: 'Touch screen interface',
      ja: 'タッチスクリーン インターフェース'
    }
    ,
    {
      zh: '曝光屏接口',
      en: 'Exposure screen interface',
      ja: '露光スクリーン インターフェース '
    }
    ,
    {
      zh: 'MIPI芯片',
      en: 'MIPI chip',
      ja: 'MIPI チップ'
    }
    ,
    {
      zh: 'A328P芯片',
      en: 'A328P chip',
      ja: 'A328P チップ'
    }
    ,
    {
      zh: '电机接口',
      en: 'Motor interface',
      ja: 'モーター インターフェース'
    }
    ,
    {
      zh: '正电机限位接口',
      en: 'Positive motor limit interface',
      ja: 'ポジティブモーターリミット インターフェース'
    }
    ,
    {
      zh: '负电机限位接口',
      en: 'Negative motor limit interface',
      ja: 'ネガティブモーターリミット インターフェース'
    }
    ,
    {
      zh: '风扇接口',
      en: 'Fan interface',
      ja: 'ファン インターフェース '
    }
    ,
    {
      zh: '电源',
      en: 'Power supply',
      ja: '電源'
    }
    ,
    {
      zh: 'LED接口',
      en: 'LED interface',
      ja: 'LED インターフェース'
    }
    ,
    {
      zh: '按键接口',
      en: 'Button interface',
      ja: 'ボタン インターフェース'
    }
    ,
    {
      zh: 'USB接口',
      en: 'USB interface',
      ja: 'USB インターフェース '
    }
    ,
    {
      zh: 'ipex-1天线接口',
      en: 'IPEX-1 antenna interface',
      ja: 'IPEX-1 アンテナ インターフェース'
    }
    ,
    {
      zh: 'RJ45接口',
      en: 'RJ45 interface',
      ja: 'RJ45 インターフェース'
    }
  ],
  
  head: {
    product: '产品',
    resin: '耗材树脂',
    geniusControlBoard: 'Genius主板',
    coreOcto: '软件与平台',
    coreOctoApp: '章鱼核',
    support: '支持',
    know: '知识中心',
    warranty: '保修政策',
    faq: '常见问题',
    compPro: '公司简介',
    aboutUs: '关于我们',
    application: '应用领域',
    shop: '商城',
    search: '搜索',
    chinese: '中文简体',
    en: 'English',
    ja: '日本語'
  },
  footer: {
    products: '产品',
    printer: 'Bloom-740w',
    mainBoard: 'Genius 控制主板',
    resin: '树脂',
    softPatform: '软件与平台',
    coreOctoApp: '章鱼核APP',
    shop: '商城',
    support: '支持',
    know: '知识中心',
    warranty: '保修政策',
    faq: '常见问题',
    compPro: '公司简介',
    aboutUs: '关于我们',
    application: '应用领域',
    linkUs: '联系我们',
    tel: '电话',
    mail: '邮箱',
    address: '地址',
    detailAddress: '广东省深圳市宝安区福永街道宝安大道兴华路6042号B栋3楼',
    compName: '深圳市章鱼工场科技有限公司',
    ipc: '粤ICP备2023010580号',
    puclicNetwork: '粤公网安备44030002001547号',
    //belonging: '多样性和归属感',
    //softDownload: '软件下载',
    //coreOcto: '章鱼核',
    //coreOctoPlat: '章鱼核云平台',
    //productSup: '产品支持',
    //downInstallSoft: '下载并安装软件',
    //poli: '企业社会责任政策',
  },
  home: {
    learnMore: '了解更多',
    buyNow: '立即购买',
    banner: [
      {
        title: '新一代性能光固化Bloom-740w',
        value: '精准创造,静享打印之美',
        li: '全新3D打印体验, 让您在打印过程中彷佛身临其境, 享受宁静的山谷之美'
      },
      {
        title: 'Genius 光固化控制主板',
        value: '一站式解决方案的智能掌航者',
        li: '兼容市场上大部分LCD曝光屏, 设计功能和定制产品、设备及升级系统, 提供技术支持'
      },
      {
        title: 'LCD桌面级光敏树脂',
        value: '科技美学与工业并存',
        li: '专为 Bloom-740w 3D打印机而设计的树脂, 材料的灵活性带来更好的结果和更快乐的体验'
      }, {
        title: 'Magic Pen Slicer',
        value: '技术融入艺术 科技融入创意',
        li: '梦想魔法实现者, 让你的创意像魔法一般轻松实现, 无需任务专业培训, 任何人都可以立即进行打印'
      }
    ],

    smallBanner: [
      {
         title: '轻松入门',
         value: '简易上手，',
         description: '一键式操作，简单高效。' 
      },
      {
        title: '性能和品质并存',
        value: '强劲品质，',
        description: '高质量，长效稳定。' 
      },  
      {
        title: 'Bloom-740w和Magic Pen Slicer',
        value: '工作搭档，',
        description: '默契好友。' 
      },
      {
        title: '兼容性',
        value: '英语法等语言，',
        description: 'Bloom-740w都兼容。' 
      },
      {
        title: '品质感',
        value: '精湛工艺，',
        description: '科技与工业' 
      }
    ],

    title:[
      {
        title: '智造性能机',
        description: '创新技术 改变世界',
      },
      {
        title: '了解Bloom-740w',
        description: '科技与技术的碰撞, 为打印高性能材料而生',
      },
      {
        title: '全系产品',
        description: '专业制造 科技与创意融合',
      },
      {
        title: '工作好伙伴',
        description: '专业制造 科技与创意融合',
      },
      {
        title: '打印作品展示',
        description: '用户创作精选作品',
      },
    ],

    products:[
      'Bloom-740w',
      'ABS-Like Resin',
      'Water-Wash Resin',
      'Ggenius I 控制主板',
      'Ggenius II 控制主板',
      'Ggenius III 控制主板'
    ],
  },

  printer: {

    banner: {
      title: '新一代性能光固化Bloom-740w',
      value: '从想象到现实，创意就在此刻',
      purchase: '立即购买',
      watch: '观看视频'
    },
    detailed: {
      txt1:'Bloom-740w',
      txt2: '小体积、大创意',
      txt3: '40μm',
      txt4: '精细度',
      txt5: '0.5S',
      txt6: '快拆',
      txt7: '无层纹打印',
      txt8: '快拆式打印头',
      txt9: '创意无限：Bloom-740w带给我们无限的创意空间，将我们的想法和设计转化为真实的物体。无论是个人的创意作品还是商业的产品原型，Bloom-740w都能以精准的打印效果呈现出来，只需一个小巧的打印机，开启创作的无限可能性。',
      txt10: '个性定制：Bloom-740w为个性化定制提供了便利，可以根据个人需求进行定制生产，无论是定制的饰品、家居用品还是个性化的礼品，我们都可以在家中利用Bloom-740w进行制作。这种个性化定制的趋势不仅满足了人们对独特物品的追求，也提供了更多商业机会和创业空间。',
      params: '详细参数'
    },
    productColor: {
      top:{
        title: '实力与颜值并存',
        des: '技术与艺术的碰撞',
      },
      product:[
        {
          title: '打印头',
          des: '一按一压拆卸',
        },
        {
          title: '直线导航',
          des: '高精密Z轴',
        },
        {
          title: 'ARM芯片',
          des: '高性能流畅速度',
        },
        {
          title: '远程监控',
          des: '支持APP/WIFI无线网络',
        },
        {
          title: 'DIY创意',
          des: '个性化定制',
        },
        {
          title: '直观UI界面',
          des: '双色模式自由选择',
        },
      ],
    },

    param: '参数信息',
    other:[
      {
        title: '最高精度，还原模型完美细节',
        des: 'S型图形算法，细节清晰可见'   
      },
      {
        title: '红银白色，科技与工业的结合',
        des: '科技与创意的融合'   
      },
      {
        title: '40μm 高精度打印',
        des: '如丝滑般的表面'   
      },
      {
        title: '亮点合集',
        des: 'BLoom-740w的内涵',   
        imgTitle01: '静音打印',
        imgDes01: '50dB',
        imgDes02:'简易调平',
        imgDes03:'卡扣安装',
        imgDes04:'可靠质量',
        label: '融合3D数字化全流程可视化管理，提供最实用、最敏捷的',
        value: '解决方案，实现品质和速度双重升纬'
      },
      {
        title:'COB光源',
        des1:'高光均匀性高达90%以上, 消除不完美因素',
        des2:'创新升级光源类型, 配合光源修改蒙版光均匀性高达90%以上, 可调光性, 节能且使用寿命长, 亮度及颜色稳定'
      },
      {
        title:'掌握打印“芯”内核',
        des1:'确保每一层的打印都如期而至',
        des2:'打印机主板搭载高性能ARM芯片，性能稳定。处理再复杂的模型打印任务都能保障流畅的操作',
      },
      {
        title: '亚像素曝光',
        des1: '每个细节清晰可见',
        des2: '图像边缘Alpha混合+抗锯齿处理,降低模型打印纹理，使图像边缘',
        des3: '缘过渡更平滑从而提升打印模型的表面光滑度'
      },
      {
        title: '自研切片软件',
        des1: '功能齐全 满足更多打印需求',
        des2:'自研Magic Pen切片软件,拥有深浅两款主题界面多国语言。软件功能包括了模型的处理，支撑添加，模型切片以及模型模拟打印等模块。搭配自带的模型库，享受专业创作的同时，玩转属于自己的创意。'
      }
    ],
    
    video: {
      title: '视频',
      origWork: '原创作品',
      vLabels: [
        'Bloom-740w',
        '精细度打印',
        'COB光源',
        '亚像素曝光',
        '动态层厚'
      ]
    }
  },
  printDetailParams: {
    bread: {
      position: '您的位置',
      home: '首页',
      detailPar: '详细参数 '
    },
    devStr: '设备结构',
    devPar: {
      title: '设备参数',
      model: '型号',
      theory: '原理',
      pxSize: 'XY像素尺寸',
      moldingSize: '成型尺寸',
      dist: '分辨率',
      touchScreen: '触摸屏',
      printWays: '打印方式',
      power: '额定功率',
      volt: '电压',
      suttle: '净重',
      deviceSize: '设备尺寸',
      deviceLang: '设备语言',
      lcdDisplayForm: 'LCD显示成型',
      wucun: '5寸 4k',
      lang: '中文、英语、日语'
    }
  },
  software: {
    learnMore: '了解更多',
    banner: {
      value: 'Magic Pen Slicer是一款功能齐全, 它采用项目化的工作流程, 系统化的切片算法和简洁易懂的用户界面, 为用户提供非常流畅的打印体验',
      windows: 'Windows 10 (64-bit) 或更高版本',
      mac: 'macOS 10.15 或更高版本'
    },
    introd01: {
      label: '以始为终的3D打印软件',
      value: '无限创作空间',
      des: 'Magic Pen Slicer是一款高效的3D打印预处理软件, 提供了直观易用的界面, 让用户可以轻松地完成3D打印前的所有预处理工作, 同时拥有丰富的操作功能：切割、镂空、贴字、修复、布尔操作、新建模型、自动支撑、功能快捷键、多机打印、虚拟打印等, 无论你是3D打印专业人士还是初学者, 都可以使用它来提高工作效率和打印质量',
    },

    introd02: {
      title: '一键式自动处理',
      des: '一键式快速操作'
    },

    introd03: {
      title: '直观易用界面  预处理软件',
      des: '一键式处理'
    },

    ads: {
      ads2: [
        {
          label: '集群打印',
          value: '便捷操作, 省略手动调整的操作步骤, 一键自动分布排列模型, 减少繁琐重复步骤, 一步到位分布排列'
        },
        {
          label: '图像算法',
          value: '通过自主研发算法对模型进行亚像素曝光, 实现抗锯齿打印出丝绸般光滑的表面, 展示高分辨率图像'
        },
        {
          label: '自动支撑',
          value: '自动检测模型中的需要支撑的区域, 解决悬空情况, 防止几何面被破坏, 完成精准而复杂的模型支撑'
        },
        {
          label: '自动布局',
          value: '便捷操作，省略手动调整的操作步骤，一键自动分布排列模型，减少繁琐重复步骤，一步到位分布排列'
        },
        {
          label: '切片预览',
          value: '模型与切片图对照, 可视化展示模型每一层的打印结果, 精确标准化流程工序, 尽显工艺精湛之美'
        },
        {
          label: 'ZYSLR4',
          value: '专属切片格式，同时也支持STL、OBJ、Plain3d格式文件, 您可以导入使用各种CAD软件设计的模型'
        },
      ],
  
    },
  },

  softwareSysReq: {
    title: '系统要求',
    tr: 'Magic Pen Slicer',
    td: [
      "操作系统",
      "Windows10(64)位、MacOS10.15以上系统",
      "浏览器",
      "章鱼工场建议使用以下 Web浏览器的最新版本 来访问",
      "CPU",
      "Windows10(64)位、MacOS10.15以上系统",
      "磁盘空间",
      "8GB可用磁盘空间(用于安装)",
      "RAM",
      "至少4GB RAM (建议使用8GB或更大空间)",
      "指针设备",
      "鼠标",
    ]
  },

  mainBoard: {
    purchase: '立即购买',
    detailParams: '详细参数',
    param: '参数信息',
    banner: {
      label: 'Genius 光固化控制主板',
      value: '一站式解决方案的智能掌航者',
      des: `兼容市场上大部分LCD曝光屏，设计功能和定制产品、设备及升级系统，提供技术支持`,
    },
    g3: {
      title: '光固化3D打印静音主板 提供一站式解决方案',
      des: '主板采用FPGA实现mipi屏控制，可兼容市面上大多数的LCD曝光屏，可为设计功能和自定义产品、设备和系统，工程师提供技术和版型定制-丰富用户产品线。',
      pic: [
        {
          title:'芯片',
          des: 'TMC2208静音芯片'
        },
        {
          title:'双核心',
          des: '双核心可拆卸'
        },
        {
          title:'曝光屏',
          des: '14K曝光屏'
        }
      ]
    },

    g2: {
      title: '光固化3D打印静音主板 提供一站式解决方案',
      des: '主板采用FPGA实现mipi屏控制，可兼容市面上大多数的LCD曝光屏，可为设计功能和自定义产品、设备和系统，工程师提供技术和版型定制-丰富用户产品线。',
      pic: [
        {
          title:'芯片',
          des: 'TMC2208静音芯片'
        },
        {
          title:'Wi-Fi',
          des: '远程打印监控'
        },
        {
          title:'接口',
          des: '摄像头接口'
        }
      ]
    },
    g1: {
      title: '光固化3D打印静音主板 提供一站式解决方案',
      des: '主板采用FPGA实现mipi屏控制，可兼容市面上大多数的LCD曝光屏，可为设计功能和自定义产品、设备和系统，工程师提供技术和版型定制-丰富用户产品线。',
      pic: [
        {
          title:'芯片',
          des: 'TMC2208静音芯片'
        },
        {
          title:'元器件',
          des: '高质量元器件'
        },
        {
          title:'外观',
          des: '个性化外观'
        }
      ]
    },
    mainBoard02: {
      G3:{
        title: '专业选手，LCD的核心选择',
        des: '工业级、三大特点（蜂鸣器、Wi-Fi、双核心）、高级DIY'
      },
      G2: {
        title: '品质严选，性能与颜值并存',
        des: '进阶级、Wi-Fi、远程打印及视频监控、消费级打印机'
      },
      G1: {
        title: '性能最佳优化，成本首选',
        des: '入门级、小体积（2K、4K以下分辨率）、消费级打印机'
      }
    },
  
    mainBoard03: {
      G3:{
        title: '兼容多尺寸曝光屏  14K超高分辨率',
        value: '提高打印效率、还原生动细节',
        des1: '14k',
        des2: '&',
        des3: '断电续打 脱模检测',
        des4: '高运行内存,  512M DDR,  16G EMMC \ ARM架构,  体积小、低功耗、低成本、高性能 \ 支持连接网口以及Wi-Fi,  远程打印等功能 \ 支持打印机断电续打,  脱模检测和自动注料等功能 \ 适配2K、4K、8K、14K曝光屏 \ 静音IC,  可减小打印时产生的噪音'
      },
      G2:{
        title: '2～3倍运行速度  4K高清画质',
        value: '兼容性与稳定性、16G大内存',
        des1: '4k',
        des2: '&',
        des3: 'Wi-Fi 摄像头',
        des4: '高运行内存,  512M DDR,  16G eMMC \ ARM架构,  体积小、低功耗、低成本、高性能 \ 支持连接网口以及Wi-Fi,  远程打印等功能 \ 支持远程视频监控,  能远程清晰查看打印的全部过程 \ 适配2K、4K曝光屏 \ 静音IC,  可减小打印时产生的噪音',
      },
      G1:{
        title: '内部设计芯片  小巧体积 最佳性能',
        value: '高质量元器件、个性化外观',
        des1: '高性能',
        des2: '&',
        des3: '低成本 低功耗',
        des4: '高运行内存,  64M DDR,  512M SDNAND  \ ARM架构,  体积小、低功耗、低成本、高性能 \ 支持连接网口以及Wi-Fi,  远程打印等功能 \ 适配2K、4K曝光屏 \ 静音IC,  可减小打印时产生的噪音',
      }
    },
  
    cpu: {
      title: '静音电机驱动、低功耗、小而强劲',
      des: '采用MIPI 高速接口- 2.5 Gbps，最高支持14K以上MIPI屏，搭载进口的电机驱动芯片TMC2208，实现在高速运动下的超静音效果，主控与FPGA之间采用SDIO保证通讯，确保每一层图像的正常输出和显示，主控采用全志高性能处理芯片，提供通用的步进电机模块接口，外接2209、等大电流的驱动电机模块，适配性强。',
      des2: '为视觉处理应用提供一流的性能'
    },

    oem01: {
      top:{
        title: '美学外观&实用性能',
        des: '个性化定制'
      },
      down: {
        title: 'OEM定制是一种高度个性化的服务，它允许用户根据自己的需求和喜好来设计和选择触摸屏的外观、功能和性能。这种定制服务不仅提供了更多的选择，也使得产品更加符合用户的个性化需求。',
        des: 'OEM定制',
        des2: '产品方案'
      }
    },

    oem02: {
      title: '工艺设计',
      des1: '无约束成形工艺突破设计限制实现不受结构局限的巧妙设计',
      des2: '解锁全新产品理念，重新定义产品定制，响应客户的多样化需求，我们可提供产品定制、功能设计等，可实现灵活调整，契合柔性制造的现代生产理念，突破3D打印工艺，OEM定制为客户带来附加价值。',
      des3: '解决方案',
      des4: '可针对产品造型、功能等进行升级，提供产品升级方案供客户自行灵活选择。'
    },

    bordShow02: {
      title: '双核心自由选择搭配',
      des: '灵活采用不同的核心板实现主板的快速定制，降低研发周期，为客户提供完整的OEM定制方案'
    },

    lastPage: {
      title: '专用3D打印控制系统',
      des1: '匀光系统、运行速度更强、直观UI界面',
      des2: '自研专门用于3D打印控制系统，与Genius主控方案协同进行工作，实现更高效的打印过程，深度助力3D打印技术的发展。'
    }
  },

  resin: {
    banner: {
      label: 'LCD桌面级光敏树脂',
      value: '科技美学与工业并存',
      des: '专为 Bloom-740w 3D打印机而设计的树脂，材料的灵活性带来更好的结果和更快乐的体验',
      purchase: '立即购买'
    },
    swiper:{
      des1: 'ABS-Wash',
      des2: 'Water-Wash',
      des3: '树脂模型',
    },
    introduce:{
      top:{
        title:'创造性应用突破 材料创新引领者'
      },
      left01:{
        title: 'ABS-Like Resin',
        des: 'ABS树脂具有卓越的强度和耐用性，适用于各种应用。它对许多化学品具有很好的耐受性。ABS易于成型、切割和机加工，适用于各种生产工艺。它可以产生平滑有光泽的表面。ABS可容易地涂漆和表面处理，以改变外观或增加保护。ABS树脂在一定温度范围内保持稳定性。它对冲击和振动有较好的吸收性能。'
      },
      right02:{
        title:'材料特性',
        des1: 'ABS树脂具有具有抗冲击强度高、吸水性小等优良性能,且易于加工成型, 还有很好的耐低温性和抗蠕变性, 表面光泽性好',
        des2: '耐高温',
        des3: '高实色度和颜色稳定',
        des4: '卓越的表面质量',
        des5: '出色的强度和耐久性'
      },
      left03:{
        title:'材料特性',
        des1: 'Water-Wash Resin性能均衡，拥有着较高的拉伸强度与延展性及抗冲击特性，可适用于直接生产功能性零配件以及终端产品外壳',
        des2: '易于清洗',
        des3: '快速固化',
        des4: '低气味',
        des5: '适用于高分辨率打印'
      },
      left04:{
        title: 'Water-Wash Resin',
        des:'水洗树脂模型可水洗、流动性好、高精度、环保、兼容性广、高性价比、成品表面光滑、固化时间短，在保证模型打印性能的同时，解决打印成型产品不易离型的难题，大幅提升打印成功率，延长离型膜使用寿命。树脂保证模型强度和不过度收缩的同时，提高打印速度，缩短固化时间，提升机器的打印效率。'
      }
    },

    introduce02:{
      title: '工艺作品 应用展示 特征性能'
    },

    argument:{
      title:'基础性能',
      des1:'多样化应用',
      des2: '不一样的应用维度，不同应用的打印材料，满足性能与美学综合的需求，提供高性能、高精度、高稳定的技工与材料，覆盖既有应用同时，支持工作者开发更多创新应用',
      tab:{
        head:{
          cell01: '测试项目',
          cell02: '单位',
          cell03: '测试标准',
          cell04: '数值',
        },
        title:[
          '拉伸强度',
          '拉伸模量',
          '断裂伸长率',
          '弯曲强度',
          '弯曲弹性模量',
          '冲击强度',
          '硬度',
          '屈服点伸长率',
          '最大力点变形',
          '最大力'
        ]
      }
    }
  },
  coreOctoApp: {
    banner: {
      label: '章鱼核',
      value: '章鱼核是一款一体化的3D打印服务平台, 提供云端切片和远程任务下发, 让您随时随地尽享3D打印乐趣',
    },
    richRe: {
      label: '高质量模型',
      value: '创意不停',
      des: '海量的模型资源，为您提供方便快捷地获取和利用模型资源的途径，让您的创意想法实现更加便利，我们还支持原创模型的付费功能，让创作者能够获得自己作品收益'
    },
    cloudOp: {
      top: {
        title: '可视化管理平台',
        des: '3D数字化打印全流程可视化管理平台，具备云端切片、实时监控、视频回放、云端任务下发等功能，提供贴心一体化服务，仅需一台手机，即可实现云端3D打印'
      },
      thr: [
        {
          title: '实时监控',
          des: '您可以随时观察打印机的运行状态，确保打印过程的稳定性和安全性'
        },
        {
          title: '视频回放',
          des: '自动记录打印过程，帮助用户更好地诊断打印故障'
        },
        {
          title: '云端切片',
          des: '通过智能算法进行切片处理，同时还可以预览切片后的模型效果，切片即可打印'
        },
        {
          title: '云端任务下发',
          des: '远程管理无论PC端还是移动端都可以快速发送任务开启工作'
        }
      ]
    },
    range: {
      title: '远程打印',
      des: '支持远程发送控制您的3D打印机，并通过在线切片，可进行多个设备切换，实现远程一体切片打印'
    },

    cloudServer: {
      top: {
        title: '云端服务',
        des: '云端在线批量模型切片操作，用户可快速体验切片，简单清晰直观，方便快捷高效，提供3D云存储空间，减少存储空间，随时访问和编辑'
      },
      left: {
        title: '批量模型切片',
        des: '您可以批量上传多个模型文件，对多个模型进行快速而精确的切片'
      },
      right: {
        title: '云存储空间',
        des: '提供安全可靠的3D云存储空间，模型文件上传到云端，随时随地访问和编辑，让您方便地存储和管理您的3D模型'
      }
    }
  },

  application:{
    banner:{
      title: '探索 3D 打印技术在不同领域的优势'
    },
    page:[
      {
        title:'产品原型',
        des:'三维打印技术可以直接将设计文件转化为实体模型，并快速灵活地制作原型。这种快速原型制作不仅可以大大缩短产品开发周期，降低生产成本，还可以进行多次设计迭代，快速验证和改进产品设计。为产品设计和开发提供更加高效、便捷和创新的解决方案。'
      },
      {
        title:'数字化齿科',
        des:'光固化三维打印技术在传统的牙科定制过程中发挥着重要作用。有了这项技术，医生可以根据患者的口腔状况，快速生成精确的牙科模型，为患者量身定制牙科修复方案。与传统的手工制作或使用模具相比，光固化三维打印技术不仅能更快地完成整个过程，还能实现更高的精度和质量，有效解决了困扰牙科行业多年的精度问题。'
      },
      {
        title:'用创新的力量打印未来',
        des:'三维打印技术在建筑行业有着广泛的应用，包括建筑建模、建筑构件制造、创新设计、修复和维护以及可持续建筑。通过定制生产和精密制造，它可以帮助建筑师和设计师更直观地将设计理念和想法形象化，从而创造出更具创造性和艺术性的建筑作品。此外，它还能减少建筑过程中的浪费和对环境的影响，为实现建筑业的可持续发展注入新的活力和动力。'
      },
      {
        title:'手办模型',
        des:'打印大大简化了整个手板模型的制作流程，在缩短制作周期和节约成本的同时，使模型的结构比例更加精确，最大限度地满足手板模型的精细度要求。定制化生产可满足客户的个性化需求。'
      },
      {
        title:'珠宝设计',
        des:'光固化 3D 打印技术解决了作为珠宝制造核心的蜡模制作的局限性和成本问题，使私人定制成为可能'
      }
    ]
  },

  knowCenter: {
    banner: {
      label: '以质量创品牌',
    },
    product:[
      'Bloom-740w',
      'Magic Pen Slicer',
      '章鱼核',
      'Genius I',
      'Genius II',
      'Genius III',
    ],
    handBook:[
      '产品手册',
      '软件手册',
      '平台手册',
      '主板手册'
    ]

  },

  belonging: {
    bread: {
      belonging: '多样性和归属感'
    },
    banner: {
      label: '多样性与归属感',
      value: '我们正在建设一支多元化的员工队伍和一种归属感文化，让更多人有机会想象、设计和创造一个更美好的世界。'
    },
    oppor: '扩展机遇',
    change: '引领变革',
    intro1: '多样性激发新的想法、创造力和业务增长。我们致力于在 章鱼工场 营造多元化和包容性环境，欢迎各种不同的背景、观点和信仰。',
    intro2: '作为引领变革的公司，我们有责任为尽可能多的人创造参与未来工作的机会。',
    lis: [
      {
        label: '多元化与包容性',
        value: '多元化团队、包容文化'
      },
      {
        label: '人才与发展',
        value: '激发活力、支持成长'
      },
      {
        label: '创造力',
        value: '突破创新、探索未来'
      },
      {
        label: '归属感',
        value: '包容、协作、进步、发展'
      },
      {
        label: '责任与信任',
        value: '客户至上、协作共赢'
      },
      {
        label: '现在与未来',
        value: '开拓技术创新、未来科技融入生活'
      }
    ]
  },
  csrp: {
    title: '企业社会责任政策',
    txtLis: [
      '章鱼工场创立于2017年，以创新精神、承先启后和专业知识为核心价值。每一件3D打印机，以及树脂、主板皆启发自3D技术研发的技术传承，诉说着底蕴深厚的3D计算机图形学的传奇，并展现出积极且富有诗意的人生观。',
      '尊重自然环境以及员工和社会的利益 ，是章鱼工场重要企业文化之一。此政策将解释章鱼工场如何向客户、员工、股东、供应链合作伙伴以至整个社会履行企业社会责任。',
      '章鱼工场致力为商业、社会和环境创造可持续发展的未来。将企业社会责任融入章鱼工场的精湛3D技术之中，并于所有利益相关者分享这一理念。因为章鱼工场深信，人人都可为可持续发展做出贡献。',
      '章鱼工场力行支持循环经济的概念。章鱼工场尽可能避免“获取、制造及弃置”（take，make，dispose）的线性模式，并支持创新的循环模式，确保产品、组件及材料物尽其用，并从设计开始，减少浪费。',
      '章鱼工场作为运联网集团一员，进行业务活动时始终以诚为本、以信为先，同时按照集团的《商业行为准则》尊重各利益相关者。',
      '章鱼工场遵照《世界人权宣言》（Universal Declaration of Human Rights）开展业务，尊重雇员和合作伙伴的权利。为解决、监察和减少对人权的影响，章鱼工场秉承《联合国商业与人权指导原则》，遵守所有适用法律和法规。此外，章鱼工场定期检视其企业社会责任政策及监察政策绩效，并采取持续改进。'
    ],
    employer: {
      label: '负责任的雇主',
      des: '章鱼工场强调企业的核心价值，重视团队精神，致力为员工提供安全、健康、公平和富启发性的工作环境。有关于健康与安全的指导原则详载于其《环境、健康及安全政策》。章鱼工场为员工提供发展机会，并确保他们具备管理业务的技能。章鱼工场重视多元文化，一直在招聘、聘用、发展和留用的过程中推动平等机会。',
    },
    purchasing: {
      label: '负责任的采购',
      des: '章鱼工场致力以负责的方式采购原材料，尊重商业道德、人权、劳工权利和自然环境。此外，章鱼工场要求供货商遵守运联网集团的《供货商行为准则》，确保自身业务符合这些指导原则。章鱼工场期望供货商与各自的承包商和供货商沟通，鼓励它们将这些原则纳入其业务政策和准则。章鱼工场将继续与行业组织及商业伙伴合作，在整个价值链推广最佳实践。章鱼工场将寻求解决方案，以长远而言可持续的方式采购原材料。'
    },
    envir: {
      label: '环境责任',
      des: '章鱼工场不断致力改善环境管理，以减少对环境造成的影响，并专注寻求创新方案，以促进循环经济。作为运联网集团的一部分，我们将倡导科学基础减量，这为我们带来清晰的发展道路，引领我们于2030年前减少对气候变化的影响，同时使业务能在未来以更环保的方式增长。'
    },
    inver: {
      label: '社会投资',
      des: '章鱼工场致力传扬其核心价值，包括创新精神、承先启后和专业知识，藉此让社会各界分享其积极人生观，章鱼工场在文化、技术、艺术、传承、教育、科研、环境等领域参与众多社区投资活动，务求为社会作出积极贡献。'
    }
  },
  profile: {
    about: {
      label: 'About Us',
      des1: '深圳市章鱼工场科技有限公司',
      des2: '创立于2017年，总部位于广东省深圳市宝安区。作为3D打印的数字化生产方案供应商，公司主要业务为3D打印设备、软件、耗材、技术方案等。公司自主研发、自主创新，产品技术完全拥有自主知识产权。章鱼工场是全球领先的图形图像信息处理，并行计算、异构计算、大规模集成电路和机械自动化解决方案供应商，目前专注于 3D 打印增材制造领域，坚持数字化管理运营，持续探索与创新，并致力于实现数字化小世界，创造以数字化为主的和以视觉信息为辅的多彩世界。'
    },
    framework: {
      title: '产品架构',
      lis: [
        {
          label: 'Bloom-740w',
          value: '消费级光固化3D打印机'
        },
        {
          label: '光敏树脂',
          value: '3D打印耗材'
        },
        {
          label: 'Genius控制主板',
          value: 'OEM定制'
        },
        {
          label: 'Magic pen Slicer',
          value: '3D切片预处理软件'
        },
        {
          label: '章鱼核云平台',
          value: '3D打印服务平台'
        },
      ]
    },
    authentication: {
      label: '国际认证',
      text:[
        {
          title: 'FCC认证',
          des: '电磁兼容'
        },
        {
          title: 'PSE认证',
          des: '产品安全'
        },
        {
          title: 'TELECT认证',
          des: '产品标准'
        }
      ]
    },

    culture: {
      title: '企业文化',
      text:[
        {
          title: '价值观',
          des: '诚信、探索、创新、科技',
          tran: 'Values'
        },
        {
          title: '愿景',
          des: '信任的合作伙伴，引领成功之路',
          tran: 'Vision'
        },
        {
          title: '使命',
          des: '成为3D主板行业的领导者',
          tran: 'mission'
        }
      ]
    },

    patent: {
      title: '资质专利'
    }
 
  },
  installDownload: {
    title: '下载并安装软件',
    content: [
      {
        name: '准备安装',
        label: '收集信息并准备好您的计算机',
        value: '在安装您的 Magic Pen Slicer 软件之前，请准备好您的系统并收集您需要的所有信息。',
        lis: [
          {
            label: '1. 检查检查必备条件',
            value: [
              '检查系统要求',
              '确认您使用的是经过认证和推荐的计算机硬件',
              '阅读您正在安装的软件的发行说明'
            ]
          },
          {
            label: '2. 获得许可',
            value: '您需要管理权限才能安装。要在Windows中进行验证，请选择控制面板 > 用户帐号 > 管理用户帐号。'
          },
          {
            label: '3. 安装系统更新并禁用防病毒程序',
            value: '如果您有待处理的操作系统更新，请安装它们并重新启动。考虑暂时禁用防病毒程序，因为它们通常会干扰安装。'
          },
          {
            label: '4. 关闭除安装程序之外的所有正在运行的应用程序'
          }
        ]
      },
      {
        name: '下载您的软件',
        lis: [
          {
            label: '从哪里下载产品和更新',
            value: '下载软件，请访问章鱼工场官网。',
            lis: [
              {
                label: '下载软件',
                value: [
                  '1. 访问 https://octopusworks.com/ 章鱼工场官网。',
                  '2. 在菜单栏中点击产品，展开的列表中点击 Magic Pen Slicer 进入到下载页面。',
                  '3. 选择您要下载软件。'
                ]
              },
              {
                label: '更新软件',
                value: '在软件里点击菜单栏-帮助-检查更新；即可更新软件，或在章鱼工场官网下载最新版本的软件。'
              }
            ]
          },
          {
            label: '下载并安装',
            lis: [
              {
                label: '选择适合您安装的下载方法。',
                value: '您可以从 https://octopusworks.com/ 产品部分，点击 Magic Pen Slicer 进行下载。'
              },
              {
                label: '安装',
                value: `在章鱼工场官网将 Magic Pen Slicer（3D打印切片软件）下载下来，勾选许可证协议，选择软件安装位置,（建议用户安装在D盘中）,
                点击下一步。开始安装，安装结束点击完成。` 
              }
            ]
          }
        ]
      }
    ],
  } ,
    support: {
    title: '产品支持',
    banner: {
      label: '产品支持',
      value: '引入最新的科技和环保理念,智能化设计'
    },         
    soft: {
      sysReq: '产品系统要求',
      name: 'Magic Pen Slicer',
      proVer: '涵盖的产品和版本',
      des: '章鱼工场 Magic Pen Slicer 2023 软件受以下64位操作系统支持，并且至少需要具有以下64位硬件的系统：',
      form1: {
        soft: '软件',
        op: {
          label: '操作系统',
          value: 'Windows10(64)位、MacOS10.15以上系统'
        },
        bro: {
          label: '浏览器',
          value: '章鱼工场建议使用以下 Web浏览器的最新版本 来访问联机补充内容：'
        }
      } ,
      form2: {
        hard: '硬件',
        cpu: {
          label: 'cpu',
          value: 'Windows10(64)位、MacOS10.15以上系统'
        },
        vch: {
          label: '显卡硬件',
          value: '相关建议系统和显卡的详细列表，请参考Magic Pen切片器认证硬件'
        },
        diskSp: {
          label: '磁盘空间',
          value: '8GB可用磁盘空间(用于安装)'
        },
        ram: {
          label: 'RAM',
          value: '至少4GB RAM (建议使用8GB或更大空间)'
        },
        pointDev: {
          label: '指针设备',
          value: '鼠标'
        }
      }
    },
    gBoard: {
      name: 'Genius 控制主板',
      proVer: '涵盖的产品和版本',
      lis: [
        {
          name: 'Genius I Series静音主板',
          expScr: {
            label: '曝光屏',
            value: '最高支持4K分辨率'
          },
          power: '电源',
          weight: '重量',
          intSto: '内存',
          intSup: {
            label: '接口支持',
            value: 'I2C、UART、SPI、GPIO等'
          }
        },
        {
          name: 'Genius II Series静音主板',
          expScr: {
            label: '曝光屏',
            value: '最高支持4K分辨率'
          },
          power: '电源',
          weight: '重量',
          intSto: '内存',
          intSup: {
            label: '接口支持',
            value: 'I2C、UART、SPI、GPIO等'
          }
        },
        {
          name: 'Genius III Series静音主板',
          expScr: {
            label: '曝光屏',
            value: '最高支持12K分辨率'
          },
          power: '电源',
          weight: '重量',
          intSto: '内存',
          intSup: {
            label: '接口支持',
            value: 'I2C、UART、SPI、GPIO、Type-C等'
          }
        },
      ]
    }
  },

  technicalParam: {
    title:'技术参数',
    table: [
      {
        th: '机身',
        td:[
          '打印尺寸',
          '163.92*102.4*180mm³ (长*宽*高)',
          '轴安装板',
          '镁合金',
          '机箱主体',
          'ABS塑料',
          '底座',
          'ABS塑料',
          '外罩',
          'ABS塑料'
        ]
      },
      {
        th: '打印头',
        td:[
          '连接结构',
          '按压式卡扣'
        ]
      },
      {
        th: '构建平台',
        td:[
          '尺寸',
          '163.92mm*102.4mm（长*宽）',
          '平台',
          '喷砂',
          '调平',
          '螺丝调平'
        ]
      },
      {
        th: '速度',
        td:[
          '打印速度',
          '20-60 mm/h',
          '电机运动最大速度',
          '10000 mm/s²',
        ]
      },
      {
        th: '冷却',
        td:[
          '光源散热风扇',
          '闭环控制',
        ]
      },
      {
        th: '支持耗材类型',
        td:[
          'ABS-Like Resin，Water-Wash Resin',
          '适合打印',
        ]
      },
      {
        th: '传感器',
        td:[
          '下限位传感器',
          '光电传感器',
        ]
      },
      {
        th: '物理大小',
        td:[
          '产品尺寸',
          '260*260*465 mm³ (长*宽*高)',
          '净重',
          '6.55kg'
        ]
      },
      {
        th: '电气参数',
        td:[
          '输入电压',
          '100-240 VAC, 50/60 Hz',
          '最大功率',
          '60 W',
          '电源接口',
          '品字AC插口'
        ]
      },
      {
        th: '电子器件',
        td:[
          '显示屏',
          '5寸 800 x 480 电容式触摸屏',
          '通讯',
          'Wi-Fi',
          '存储',
          'USB',
          '操作界面',
          '触摸屏、手机端 APP、电脑端应用',
          '运动控制器',
          '单核 ARM Cortex-A7 处理器'
        ]
      },
      {
        th: '软件',
        td:[
          'Magic pen slicer',
          '支持其他可导出zip格式文件',
          '操作系统',
          'MacOS、Windows'
        ]
      }
    ]
  },


  bordParamInfo:{
    title: '参数信息',
    G1:{
      tr: 'Genius I',
      td:[
        '外观尺寸',
        '120*80*16mm',
        '孔位距离',
        '114*74mm',
        '孔位直径',
        '3mm',
        '输入电压',
        '24V',
        '电源接口',
        '5.08 2P',
        '接口类型',
        'MIPI',
        '微处理器',
        'Cortex-A7',
        '电机驱动芯片',
        'TMC2208',
        '支持触摸屏',
        '3.5 \\ 5 \\ 7英寸彩色触摸屏',
        '支持打印屏',
        '5.96inch、DBT066MONO(6.6inch 4K Mono)、DXQ608-X04(6.08inch 2K Mono)、123D089CFSP01-00(8.9inch 4K Mono)',
        '支持切片文件格式',
        'zip、zyslr4',
        '推荐切片软件',
        'Magic Pen Slicer',
        '数据接口',
        'U盘、网口'
      ]
    },
    G2:{
      tr: 'Genius II',
      td:[
        '外观尺寸',
        '100*80*18mm',
        '孔位距离',
        '94*74mm',
        '孔位直径',
        '5.08mm',
        '输入电压',
        '24V',
        '电源接口',
        '5.08 2P',
        '接口类型',
        'MIPI',
        '微处理器',
        'Cortex-A7',
        '电机驱动芯片',
        'TMC2208',
        '支持触摸屏',
        '3.5 \\ 7英寸彩色触摸屏',
        '支持打印屏',
        '5.96inch、DBT066MONO(6.6inch 4K Mono)、DXQ608-X04(6.08inch 2K Mono)、123D089CFSP01-00(8.9inch 4K Mono)',
        '支持切片文件格式',
        'zip、zyslr4',
        '推荐切片软件',
        'Magic Pen Slicer',
        '数据接口',
        'U盘、网口'
      ]
    },
    G3:{
      tr: 'Genius III',
      td:[
        '外观尺寸',
        '120*80*18mm',
        '孔位距离',
        '114*74mm',
        '孔位直径',
        '5.08mm',
        '输入电压',
        '24V',
        '电源接口',
        '5.08 2P',
        '接口类型',
        'MIPI',
        '微处理器',
        'Cortex-A7',
        '电机驱动芯片',
        'TMC2208',
        '支持触摸屏',
        '3.5 \\ 7英寸彩色触摸屏',
        '支持打印屏',
        '5.96inch、DBT066MONO(6.6inch 4K Mono)、DXQ608-X04(6.08inch 2K Mono)、123D089CFSP01-00(8.9inch 4K Mono)',
        '支持切片文件格式',
        'zip、zyslr4',
        '推荐切片软件',
        'Magic Pen Slicer',
        '数据接口',
        'U盘、网口'
      ]
    }
  },


  warranty:{
    title: '保修政策',
    instructions:'我们的保修涵盖以下内容：',
    des: '我们的保修涵盖以下内容：Bloom-740w提供1年保修，主板及触摸屏提供1年保修（赠品不保修)。',
    collapse:[
      {
        label: '退换政策',
        li:[
          '自签收日起的7个自然日内，符合以下任意情况，我们提供退/换货服务，您可在原购买渠道申请退/换货服务：',
          '1. 产品外观要求无损伤、划痕、污迹、裂痕、变形等；随机配件、工具、赠品、包装、说明书等要求齐全完好。经我司售后检测判定，如退货收到的产品外观损坏，配件不全、说明书丢失或因未按照原包装打包邮寄导致的问题，客户需承担相应的费用，具体损耗及费用需寄回后经专业售后人员进行检测核算。',
          '2. 非质量问题退货，如使用了耗材，客户需承担耗材的费用；由于包装纸盒返回后是无法二次使用的，因此客户需承担包装费，具体损耗及费用需寄回后经专业售后人员进行检测核算。',
          '3. 无法提供原购买凭证、三包凭证及相关凭据，或购买凭证有伪造，涂改迹象，拒绝提供退/换货服务。',
          '4. 已打开使用并且影响二次销售的产品，拒绝提供退/换货服务。',
          '5. 发现产品由于运输导致损坏，但未当场拒收，且事后也未能提供物流公司货损证明，拒绝提供退/换货服务。',
          '6. 因不喜欢、不想要、拍错、使用不当、不满意产品等原因造成的退/换货，退回运费需客户自行承担。',
          '7. 换货时，客户需先将产品寄回，公司收到产品后会及时安排发货。',
          '注：请保管好您的原购买凭证、购物发票、保修卡及相关凭据，作为售后服务的重要依据。',
          '平台购买支持7日无理由退货，即产品签收时间起算，满168小时为7天；产品退回运费需用户承担',
          '因产品品质问题，非人为损坏的情况，签收后15日内可联系章鱼工场进行换货服务'
        ]
      },
      {
        label: '保修政策',
        li:[
          '1. 在购买之日起，打印机、主板、触摸屏设备享受1年的免费保修服务，损耗品LCD屏幕、离型膜、易损件和赠品除外。',
          '2. 易损件（喷头套件、玻璃等）保修三个月。',
          '3. 保修期内维修：如您的产品无跌落、浸水．非授权拆解改装等情况，确定故障由材料、工艺或功能不良引起的，经我司售后检测判定为品质问题，公司会进行免费维修或更换零配件。如果是人为导致的非品质问题，客户需承担相应更换的配件费用，无需承担维修费。',
          '4. 保修期外维修：由客户承担维修过程中产生的人工费、配件费、包装费、来回运费等所有费用。'
        ]
      },
      {
        label: '以下情况不能列入保修范围',
        li:[
          '1. 无法提供有效的机器制造编码、购买凭证和保修卡，或信息被涂改、字迹模糊、无法辨认等。',
          '2. 正常的使用磨损而造成的部件损坏。',
          '3. 未按照说明书指导，不正确安装、使用及操作，或私自对产品进行改装而造成产品故障或损坏。',
          '4. 在非产品规定的工作环境下使用而造成产品故障或损坏。',
          '5. 由于滥用（超出工作负荷等）或维护不当（受潮、进液、浸泡、发霉等）造成产品故障或损坏。',
          '6. 由于不可抵抗因素（如：火灾、地震、雷击、水灾等）造成产品故障或损坏。',
          '7. 由于使用其他品牌部件或低质量耗材造成的设备故障或损坏。',
          '8. 未经官方授权的服务点维修过的产品。',
          '9. 发现产品由于运输导致损坏，但未当场拒收，且事后也无法提供物流公司货损证明。'
        ]
      }
    ]
  },

  faq: {
    title: '常见问题解答',
    lis: [
      {
        label: 'Genius 控制主板',
        collapse:[
          {
            problem: '问：什么是Genius 控制主板?',
            li:[
              '答：Genius 控制主板兼容市场上大多数LCD曝光屏，可为设计功能和自定义产品、设备和系统工程师提供技术和版型定制。',
              '采用FPGA实现mipi屏控制',
              'OEM定制硬件功能及版型',
              '静音IC，减小打印时产生的噪音',
              '连接网口及Wi-Fi，远程打印操控',
              '采用CRC校验，保证数据的一致性'
            ]
          },
          {
            problem: '问：Genius 控制主板可以用来做什么?',
            li:[
              '答：1. 提供基础的打印控制逻辑，缩短产品研发周期',
              '2. 提供外置接口，快速实现定制化需求',
              '3. 连接应用程序，进行远程操作及监控',
              '4. 采用linux系统及Qt呈现高清UI界面'
            ]
          }
        ]
      },

      {
        label: '光敏树脂',
        collapse:[
          {
            problem: '问：ABS树脂有哪些特点?',
            li:[
              '答：1. 具有精细的表面质量，可攻牙装配工件等。',
              '2. 高强度和精度，成品具有良好的坚韧性，成型出色，耐冲击好。',
              '3. 可刀削，具备手工雕刻特性，深度固化后表面耐刮。'
            ]
          },
          {
            problem: '问：水洗树脂有哪些特点?',
            li:[
              '答：1. 环保安全',
              '水洗光敏树脂粘度非常低，流动性很好，气味低，安全隐患少，不会产生污染。',
              '2. 高精度',
              '水洗光固化树脂成型精度高、打印成品表面光滑，打印细节清晰可见。',
              '3. 性能好',
              '水洗树脂强度、韧性、刚性等力学性能均衡，不易脆断。',
              '4. 易于打印',
              '低粘度，离型成功率高，使得水洗光固化树脂的支撑更容易拆除。处理更轻松，还能让模型更美观。',
              '5. 应用广泛',
              '水洗光敏树脂可以用于打印动漫手办、建筑工业零件等。'
            ]
          }
        ]
      },

      {
        label: 'Bloom-740w',
        collapse:[
          {
            problem: '问：调平操作?',
            li:[
              '答：当打印平台与屏幕距离过高时，会导致模型无法成型在打印平台，因此我们需要先进行调平。',
              '首先点击打印机触摸屏首页中的"电机",点击上升平台,然后将料槽螺丝拧松,将料槽取出',
              '稍微拧松调平螺丝,放一张A4纸在曝光屏上,平铺放好即可',
              '点击首页中的电机，点击"调平",平台下降至曝光屏幕,调整平台位置及角度,顺时针紧调平螺丝,纸张无法抽出平台,即调平成功',
              '调平成功后,请将平台升高,放入料槽'
            ]
          },
          {
            problem: '问：料槽安装?',
            li:[
              '答：1. 首先,将树脂料槽背面保护膜和曝光屏保护膜撕掉。',
              '2. 将料槽放置曝光屏上方的凹槽处,调整位置对准螺丝口。',
              '3. 将两个红色料槽螺丝放置螺丝口,顺时针旋转,直至拧紧即可。'
            ]
          },
          {
            problem: '问：装料步骤?',
            li:[
              '答：1. 先将打印平台调至最高点,然后将树脂瓶上下轻微摇晃15秒,将其均匀。',
              '2. 将漏斗放在料槽的一角,打开树脂瓶,匀速将树脂倒入漏斗内,让其缓慢进入料槽,过滤掉多余气泡。',
              '槽位最大容量为550ml,请不要超过最大值',
              '装料过程中,请全程佩戴手套'
            ]
          },
          {
            problem: '问：如何避免打印过程中模型出现错层、层纹?',
            li:[
              '答：1. 检查模型支撑是否足够。当模型支撑不到位、密度不够，打印脱模时容易导致模型轻微晃动。此时建议增加支撑密度，或手动添加支撑固定模型。',
              '2. 检查料盒离型膜是否松弛，如果有松弛建议拧紧离型膜钢圈。',
              '3.适当调整打印参数。'
            ]
          },
          {
            problem: '问：打印前准备工作?',
            li:[
              '答：1. 导入stl模型，可以在Magic Pen Slicer软件里的模型库下载模型，或在Octopus Core模型网站下载模型，使用切片软件导出切片文件。',
              '2. 耗材：光敏树脂应用前轻度的左右晃动一下，切勿大力晃动导致产生大量气泡。切勿皮肤直接接触光敏树脂或接触到眼睛，一定要戴手套，若引起皮肤过敏或是不适，请上用清水或酒精冲洗，如情况严重请以防就医。耗材挑选，建议应用厂家提议耗材，以防导致打印机的受损。光敏树脂不应用时可以过滤后再进行回收到瓶子内，或是用东西遮盖住料槽，避免阳光直射和强光照射，而且避免灰尘进入。',
              '3. 检查平台：在使用机器打印前，检查打印平台是否调平，平台的螺丝和料槽两端螺丝是否拧紧，避免影响打印。',
              '4. 检查屏幕曝光：使用3D打印机固化前，观察照射屏幕是否正常。请戴防紫外线眼镜观察。测试屏幕时，请记住不要直视屏幕，以免损伤眼睛。检验槽内杂质是否影响打印，不要用金属刀片接触槽内离型膜。确定曝光是否符合规定的范围，避免因后续曝光或曝光不足而失效。'
            ]
          },
          {
            problem: '问：打印操作?',
            li:[
              '答：1. 先将模型生成.zyslr4/.zip文件保存到U盘。',
              '2. 插入U盘，待屏幕出现U盘已插入弹窗。',
              '3. 点击"U盘"标签，选择文件，点击模型文件，选择打印参数，开始打印。'
            ]
          },
          {
            problem: '问：打印完成处理?',
            li:[
              '答：1. 在进行任何清洗步骤之前，请确保采取适当的安全措施，包括佩戴手套和护目镜。一些树脂和清洗液可能对皮肤和眼睛有害。',
              '2. 将打印完成的模型从打印平台上轻轻取下，小心不要损坏模型。',
              '3. 通常，打印完成后的模型表面会附着一些未固化的树脂。使用酒精或其他适当的清洗液，将模型放入清洗容器中，确保覆盖整个模型。让模型在清洗液中浸泡一段时间，时间约1~3分钟。去除残留的树脂，浸泡完擦拭干净后就可以清洗打磨。',
              '4. 您可以使用软刷轻轻擦拭模型表面，以确保清洗干净。',
              '5. 将已清洗的模型放入紫外线曝光箱或使用紫外线灯进行第二次固化，这个过程需要2~3分钟。',
              '6. 如果需要，您可以为模型涂层或上色，以增加外观效果。'
            ]
          },
          {
            problem: '问：出现故障内容及解决方法?',
            li:[
              '答：1. 树脂问题',
              '树脂污染或者长时间静止树脂沉淀会导致模型打印出现颜色的偏差,请按照正确方式使用树脂。',
              '2. 丝杆导轨出现异响或者卡住问题',
              '长时间未使用润滑脂会导致丝杆导轨出现异响,只需要在丝杆导轨上涂抹润滑脂即可。',
              '3. 平台附着问题',
              '打印模型可能无法正常附着于打印平台上,导致打印模型无法固定,造成打印模型偏移或脱落。可能是模型底层固化时间不够,或模型本身出现问题,建议重新调整模型支撑,添加底座。',
              '4. 树脂固化不完全',
              '如果树脂固化不完全,可能会导致打印物品表面粘稠,细节不清晰,或出现未固化的区域,建议根据模型大小,延长固化时间。',
              '5. 软件设置问题',
              '错误的打印参数、错误的文件格式、错误的支撑设置等可能导致打印模型质量不佳或无法成功打印。',
              '6. 固化时间问题',
              '错误的固化时间设置可能导致树脂固化不足或过度,影响打印结果。请根据模型的大小合理设置固化时间。'
            ]
          },
        ]
      },

      {
        label: 'Magic pen slicer',
        collapse:[
          {
            problem: '问：什么是3D切片软件?',
            li:[
              '答：1. 切片软件是一种3D软件，它可以将数字3D模型转换为3D打印机可识别的打印代码，从而让3D打印机开始执行打印命令。通俗理解就是通过切片软件计算自动生成3D打印机行走的路径，打印机读取路径从而打印模型）',
              '2. 具体的工作流程是：切片软件可以根据您选择的设置将STL等格式的模型进行水平切割，从而得到一个个的平面图，计算打印机需要消耗多少耗材及时间。而后将这些信息统一存入GCode文件中，通过SD卡/数据线/网络等方式传输给3D打印机，并使其开始工作。'
            ]
          },
          {
            problem: '问：切片软件使用?',
            li:[
              '答：1. 点击"文件"，打开STL格式模型文件，若文件有损坏，可点击自动修复文件即可。',
              '2. 调节模型大小：点击"缩放"，然后在右边界面选择点击"锁定比例"，可根据比例调节想要的模型大小。',
              '3. 模型支撑：确认好模型大小比例，点击模型上方的"支撑"，在左上角找到"悬空检测"按键并点击，在右边界面确认进行检测，检测完成后，在左上角点击"自动支撑"即可。',
              '4. 切片：确认支撑完毕后，点击模型上方的"切片"，若需要设置切片参数，可点击切片设置进行调整，保存切片设置后，在右边界面点击确认开始切片，切片完成后，在左上角点击保存图标，确认完打印预览后，点击保存文档即可。'
            ]
          },
          {
            problem: '问：添加支撑技巧?',
            li:[
              '答：1. 点击"文件"，打开STL格式模型文件，若文件有损坏，可点击自动修复文件即可。',
              '2. 自动添加支撑之后需再次进行检查。有些地方支撑密度不够的，需手动添加一些，或者有些悬空的最下方没有支撑，也可以适当增加。',
              '3. 模型不贴底打印时，注意最接近底部的地方支撑数量一定要足够。',
              '4. 需要抽壳、打孔的模型，在抽壳及打孔之后再加支撑。',
              '5. 有些模型底部不平，可以适当抬高打印，或者向下偏移一点（如果对底部要求不高的话），这相当于切平了下面一小部分。',
              '6. 空心模型或者抽壳之后记得打孔，才能让里面的树脂流出来。'
            ]
          },
          {
            problem: '问：切片文件无法识别?',
            li:[
              '答：文件格式错误，检查切片文件的格式是否与打印机匹配。文件损坏或新版本的切片软件与打印机的旧版固件不兼容，可在章鱼工场官网下载最新的固件为打印机进行更新。',
            ]
          }
        ]
      },

      {
        label: '章鱼核',
        collapse:[
          {
            problem: '问：章鱼核APP打印步骤?',
            li:[
              '答：1. 下载章鱼核APP，打开APP的工作台，添加打印机设备，进行绑定（注意，请确认手机和打印机在同一个WiFi网络里)。',
              '2. 确认设备绑定成功后，选择设备，导入文件，在线切片处理。',
              '3. 文件处理完毕后，选择开始打印即可，可随时打开工作台监控打印机情况。'
            ]
          },
          {
            problem: '问：如何下载模型?',
            li:[
              '答：点击模型库,选择不同的模型分类,获取你心仪的模型, 点击模型查看模型,下载模型即可。'
            ]
          },
          {
            problem: '问：云平台打印步骤?',
            li:[
              '答：1. 登录账号，以便进行后续的操作。',
              '2.点击工作台，添加想要打印的设备，创建打印任务，选择切片文件及设备进行打印。',
              '3.可在工作台页面查看打印任务。'
            ]
          }
        ]
      },
      
    ]
  }
}
