<template>
  <div class="home">
    <Head/>
    <Banner/>
    <Main/>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
// import Banner from './Banner'
// import Main from './main'
const Banner = () => import('./Banner')
const Main = () => import('./main')
export default {  
  name: 'HomeView',
  components: {
    Banner,Main
  }
}
</script>

