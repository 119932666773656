export default {
  position: 'あなたの位置',
  homeB: 'ホーム',
  gCB: 'ジーニアス コントロール マザーボード',
  head: {
    product: '製品',
    resin: '消耗樹脂',
    geniusControlBoard: 'Geniusボード',
    coreOcto: 'ソフトウェアとプラットフォーム',
    coreOctoApp: 'タコの種',
    support: 'サポート',
    know: 'ナレッジセンター',
    warranty: '保証ポリシー',
    faq: 'よくある質問',
    compPro: '会社概要',
    aboutUs: '私たちについて',
    application: '応用分野',
    shop: 'ショッピングモール',
    search: 'サーチ',
    chinese: '中国語',
    en: 'English',
    ja: '日本語'
  },
  footer: {
    products: '製品',
    printer: 'Bloom-740w',
    mainBoard: 'Genius制御ボード',
    resin: 'じゅし',
    softPatform: 'ソフトウェアとプラットフォーム',
    coreOctoApp: 'タコの核アプリ',
    shop: 'ショッピングモール',
    support: 'サポート',
    know: 'ナレッジセンター',
    warranty: '保証ポリシー',
    faq: 'よくある質問',
    compPro: '会社概要',
    aboutUs: '私たちについて',
    application: '応用分野',
    linkUs: 'お問い合わせ',
    tel: '電話番号',
    mail: 'メールアドレス',
    address: '住所',
    detailAddress: '広東省深セン市宝安区福永街道宝安大道興華路6042号B棟3階',
    compName: '深セン市タコ工場科学技術有限公司',
    ipc: '広東ICP備2023010580号',
    puclicNetwork: '広東公網安備44030002001547号',
    //belonging: '多样性和归属感',
    //softDownload: '软件下载',
    //coreOcto: '章鱼核',
    //coreOctoPlat: '章鱼核云平台',
    //productSup: '产品支持',
    //downInstallSoft: '下载并安装软件',
    //poli: '企业社会责任政策',
  },
  home: {
    learnMore: 'もっと詳しく知る',
    buyNow: '今すぐ購入',
    banner: [
      {
        title: '新世代の高性能光硬化型Bloom-740w',
        value: '精密に作成し、静かに印刷の美しさを楽しむ',
        li: '新しい3 D印刷体験により、印刷中にまるでその場にいるかのように、静かな谷の美しさを楽しむことができます'
      },
      {
        title: 'Genius光硬化制御ボード',
        value: 'ワンストップソリューションのインテリジェントなハンドヘルド',
        li: '市場のLCD露出スクリーンの大部分を互換性があり、設計機能とカスタム製品、設備とアップグレードシステム、技術サポートを提供する'
      }, {
        title: 'LCDデスクトップクラス感光性樹脂',
        value: '科学技術の美学と工業の両立',
        li: 'Bloom-740 w 3 Dプリンタ用に設計された樹脂は、材料の柔軟性がより良い結果とより楽しい体験をもたらす'
      },
      {
        title: 'Magic Pen Slicer',
        value: 'テクノロジーがアートに統合される テクノロジーが創造性に統合される',
        li: '夢の魔法実現者、あなたのアイデアを魔法のように簡単に実現させ、専門的なトレーニングを必要とせず、誰でもすぐに印刷することができます'
      }
    ],

    smallBanner: [
      {
         title: '簡単なスタートアップ',
         value: '簡単に始められる、',
         description: 'ワンタッチで操作でき、簡単で効率的です。' 
      },
      {
        title: 'パフォーマンスと品質の両立',
        value: '強力な品質、',
        description: '高品質で耐久性があり、安定しています。' 
      },  
      {
        title: 'Bloom-740 wとMagic Pen Slicer',
        value: '仕事のパートナー、',
        description: 'チームメートと呼吸が合う。' 
      },
      {
        title: '互換性',
        value: '英語法などの言語、',
        description: 'Bloom-740 wは互換性があります。' 
      },
      {
        title: '品質感',
        value: '優れた技術、',
        description: '科学技術と工業' 
      }
    ],

    title:[
      {
        title: 'インテリジェントなパフォーマンスマシン',
        description: '革新的なテクノロジーが世界を変える',
      },
      {
        title: 'Bloom-740 wについて',
        description: '技術と技術の衝突、高性能材料の印刷のために生まれる',
      },
      {
        title: '全系製品',
        description: '専門製造技術と創意の融合',
      },
      {
        title: '仕事のパートナー',
        description: '専門製造技術と創意の融合',
      },
      {
        title: 'プリント作品の展示',
        description: 'ユーザーによる選りすぐりの作品の作成',
      },
    ],

    products:[
      'Bloom-740w',
      'ABS-Like Resin',
      'Water-Wash Resin',
      'Ggenius I制御ボード',
      'Ggenius II制御ボード',
      'Ggenius III制御ボード'
    ],

    software: [
      {
        subTitle: '創造性をマスターして未来を印刷する ',
        label: '自動サポート',
        value: 'ワンクリックサポート',
        des: 'サポートが必要なモデル内の領域を自動的に検出し、中断された状況を解決し、幾何学的表面の損傷を防ぎ、正確で複雑なモデルを完全にサポートします。' 
      }, 
      {
        subTitle: '掌握创意、打印未来',
        label: '自動レイアウト',
        value: '自動配置 ',
        des: '操作が便利で、手動調整手順を省略し、ワンクリックでモデルを自動的に配布および配置し、面倒な繰り返し手順を軽減し、ワンステップで配布と配置を実現します。' 
      }, 
      {
        subTitle: '掌握创意、打印未来',
        label: 'クラスター印刷',
        value: 'リモコン',
        des: '印刷の遠隔一括制御、接続プリンターのリアルタイム制御、印刷進捗のリアルタイム閲覧、印刷結果の遠隔監視' 
      },   
      {
        subTitle: '掌握创意、打印未来',
        label: 'スライスのプレビュー',
        value: '視覚化',
        des: 'モデルをスライス図と比較して、モデルの各層の印刷結果を視覚的に表示し、プロセスと手順を正確に標準化し、絶妙な職人技の美しさを完全に表示します。' 
      },   
      {
        subTitle: '掌握创意、打印未来',
        label: '画像アルゴリズム',
        value: '画像処理',
        des: '自社開発のアルゴリズムにより、モデルにサブピクセル露光を実行してアンチエイリアシングを実現し、シルクのように滑らかな表面を印刷して高解像度の画像を表示します。' 
      }, 
    ]  ,
    resin: {
      title: '光感応性樹脂',
      subTitle: '材料革新のリーダー',
      abs: {
          name: 'ABS樹脂',
          color: '色: ホワイト、グレー',
          des: '強度と耐久性に優れ、耐薬品性に​​優れ、滑らかなガラスのような表面を形成でき、外観を変えるために塗料で簡単に処理でき、温度範囲内で安定しており、さまざまな用途に適しています。プロセス。'
      },
      water: {
        name: '水洗いした樹脂',
        color: '色: ホワイト、グレー',
        des: 'バランスの取れた性能、高い引張強度、延性、耐衝撃性を備えており、機能部品や最終製品のケーシングの直接生産に適しています。 '
      }
    }  ,
    mainBoard: {
      title: 'Genius Control マザーボード',
      subTitle: 'スマートナビゲーター',
      G1: {
       name: 'Genius I',
       des: '64M DDR, 512M SDNAND'
      },
      G2: {
       name: 'Genius II',
       des: '521M DDR，16G EMMC'
      },
      G3: {
       name: 'Genius III 12K 高解像度大画面が間もなく登場します。これはあなたが望むコア コントローラーです',
       des: '自社開発のハイエンドマザーボード、高稼働メモリ、512M DDR、16G EMMC'
      }
   } ,
   aboutUs: {
     title: '私たちについて ',
     name: '深センタコ工場技術有限公司',
     des: '小さな世界をデジタル化し、主にデジタルで視覚情報によって補完されたカラフルな世界を作成することに取り組んでいる企業。 同社は、グラフィックスおよび画像情報処理、並列コンピューティング、ヘテロジニアス コンピューティング、大規模集積回路、および機械自動化ソリューションの世界をリードするプロバイダーです。'
   },
   services: [
    {
      title: 'サービス',
      label: 'デバイスの選択',
      value: 'インテリジェントなテスト 手動テスト'
    },
    {
      label: '緻密な職人技',
      value: '究極の感触'
    },
    {
      label: 'OEMのカスタマイズ',
      value: '新製品コンセプト'
    },
    {
      label: '製品のアップグレード',
      value: '設計上の限界を突破する'
    },
    {
      label: 'テクニカルサポート',
      value: 'デジタルソリューション'
    },
  ],
  partner: 'パートナー'
  },
  printer: {

    banner: {
      title: '新世代の高性能光硬化型Bloom-740w',
      value: '想像力から現実へ、クリエイティビティは今',
      purchase: '今すぐ購入',
      watch: '動画を見る'
    },
    detailed: {
      txt1:'Bloom-740w',
      txt2: '小さな機体、大きなアイデア',
      txt3: '40μm',
      txt4: 'ファインネス',
      txt5: '0.5S',
      txt6: 'クイック解体',
      txt7: 'レイヤーなし印刷',
      txt8: 'クイックスプリットヘッド',
      txt9: 'アイデアは無限：Bloom-740 wは私たちに無限のアイデア空間をもたらし、私たちのアイデアとデザインを真実の物体に変換します。Bloom-740 wは、個人のクリエイティブな作品でもビジネスの製品原型でも、正確な印刷効果で表示され、コンパクトなプリンタ1つで、創作の無限の可能性を開くことができます。',
      txt10: '個性的なカスタマイズ：Bloom-740 wは個性的なカスタマイズに便利を提供し、個人のニーズに応じてカスタマイズ生産を行うことができ、カスタマイズされたアクセサリー、家庭用品、個性的な贈り物にかかわらず、私たちは家の中でBloom-740 wを利用して制作することができます。このようなカスタマイズの傾向は、ユニークなものへの追求を満たすだけでなく、より多くのビジネス機会と起業の余地を提供しています。',
      params: '详细参数'
    },
    productColor: {
      top:{
        title: '実力とルックスの両立',
        des: '技術と芸術の衝突',
      },
      product:[
        {
          title: 'プリントヘッド',
          des: 'ワンプッシュずつ取り外す',
        },
        {
          title: 'ちょくせんガイド',
          des: '高精密Z軸',
        },
        {
          title: 'ARMチップ',
          des: '高性能で滑らかなスピード',
        },
        {
          title: '遠隔モニタリング',
          des: 'APP/WIFI無線ネットワークをサポートする',
        },
        {
          title: 'DIYアイデア',
          des: 'カスタマイズ',
        },
        {
          title: '直感的な UI インターフェイス',
          des: '2色モード自由選択',
        },
      ],
    },

    param: 'パラメータ情報',
    other:[
      {
        title: '最高精度、復元モデルの完全な詳細',
        des: 'S型グラフィックスアルゴリズム、詳細がはっきり見える'   
      },
      {
        title: '赤銀白色、科学技術と工業の結合',
        des: 'テクノロジーとアイデアの融合'   
      },
      {
        title: '40μm高精度印刷',
        des: 'すべすべしたような表面'   
      },
      {
        title: 'ハイライトコレクション',
        des: 'BLoom-740 wの内包',   
        imgTitle01: 'ミュート印刷',
        imgDes01: '50dB',
        imgDes02:'簡易レベリング',
        imgDes03:'スナップ取付け',
        imgDes04:'信頼性の高い品質',
        label: '3 Dデジタル化の全プロセス可視化管理を融合し、最も実',
        value: '用的で敏捷なソリューションを提供し、品質と速度の二重昇降を実現する'
      },
      {
        title:'COB光源',
        des1:'ハイライトの均一性が90%以上に達し、不完全な要素を排除',
        des2:'革新的に光源タイプをアップグレードし、光源に合わせてマスク光の均一性を90%以上修正し、調光性、省エネ、長寿命、輝度と色安定'
      },
      {
        title:'印刷コアコアの把握',
        des1:'各レイヤーの印刷が予定通りに到着していることを確認します',
        des2:'プリンタマザーボードには高性能ARMチップが搭載されており、性能は安定している。複雑なモデル印刷タスクを処理することで、スムーズな操作を保証',
      },
      {
        title: 'サブピクセル露光',
        des1: '各詳細が明確に表示されます',
        des2: '画像エッジAlpha混合+アンチエイリアシング処理により、モデルの印刷テクスチャを低減し、画像',
        des3: 'エッジの遷移をより滑らかにし、印刷モデルの表面平滑性を向上させる'
      },
      {
        title: '自社開発のスライシングソフトウェア',
        des1: 'さらなる印刷ニーズに応える充実の機能',
        des2:'Magic Penスライスソフトウェアを自己研究し、深さと浅さの2つのテーマインタフェースを持つ多言語。ソフトウェア機能には、モデルの処理、サポート追加、モデルスライス、モデルシミュレーション印刷などのモジュールが含まれています。持参したモデルライブラリを組み合わせて、プロの創作を楽しみながら、自分だけのアイデアを遊びましょう。'
      }
    ],

    video: {
      title: 'ビデオ',
      origWork: 'オリジナル作品',
      vLabels: [
        'Bloom-740w',
        '精密な印刷',
        'COBライトソース',
        'サブピクセル露光 ',
        'ダイナミックレイヤーサイズ'
      ]
    }
  },
  printDetailParams: {
    bread: {
      position: 'あなたの位置',
      home: 'ホームページ',
      detailPar: '詳細なパラメータ'
    },
    devStr: '装置の構造',
    devPar: {
      title: '装置のパラメータ',
      model: '型番',
      theory: '原理',
      pxSize: 'XYピクセルサイズ ',
      moldingSize: '成形サイズ',
      dist: '解像度',
      touchScreen: 'タッチスクリーン',
      printWays: '印刷方式',
      power: '定格電力',
      volt: '電圧',
      suttle: '重量',
      deviceSize: '装置のサイズ',
      deviceLang: '装置の言語',
      lcdDisplayForm: 'LCD表示成形',
      wucun: 'インチ 4k',
      lang: '中国語、英語、日本語'
    }
  },
  software: {
    learnMore: 'もっと詳しく知る',
    banner: {
      value: 'Magic Pen Slicerは、プロジェクト化されたワークフロー、システム化されたスライスアルゴリズム、簡潔でわかりやすいユーザーインタフェースを採用し、ユーザーに非常にスムーズな印刷体験を提供する機能が充実したオープンソースのスライスソフトウェアです',
      windows: 'Windows 10（64-bit）以降',
      mac: 'macOS 10.15以降'
    },
    introd01: {
      label: '最初から最後の3 D印刷ソフトウェア',
      value: '無限のオーサリング空間',
      des: 'Magic Pen Slicerは高効率な3 D印刷前処理ソフトウェアであり、直感的で使いやすいインタフェースを提供し、ユーザーが3 D印刷前のすべての前処理作業を簡単に完了できるようにし、同時に豊富な操作機能を持つ：切断、透かし彫り、字貼り、修復、ブール操作、新規モデル、自動サポート、機能ショートカットキー、マルチマシン印刷、仮想印刷など、3 D印刷の専門家であれ初心者であれ、生産性と印刷品質を向上させるために使用できます',
    },

    introd02: {
      title: 'ワンタッチ自動処理',
      des: 'ワンタッチクイックオペレーション'
    },

    introd03: {
      title: '直感的で使いやすいインタフェース前処理ソフトウェア',
      des: 'ワンクリック処理'
    },
    ads: {
      ads2: [
        {
          label: 'クラスタ印刷',
          value: '簡単な操作、手動調整の操作手順を省略し、モデルを自動的に分布配置し、煩わしい繰り返し手順を減少し、1歩で位置に分布配置する'
        },
        {
          label: '画像アルゴリズム',
          value: '自主開発アルゴリズムを通じてモデルにサブピクセル露光を行い、アンチエイリアシングによるシルクのような滑らかな表面の印刷を実現し、高解像度画像を展示する'
        },
        {
          label: 'じどうしじ',
          value: 'モデル中の支持が必要な領域を自動的に検出し、宙に浮いた状況を解決し、幾何面が破壊されるのを防止し、正確で複雑なモデル支持を完成する'
        },
        {
          label: '自動レイアウト',
          value: '簡単な操作、手動調整の操作手順を省略し、モデルを自動的に分布配置し、煩わしい繰り返し手順を減少し、1歩で位置に分布配置する'
        },
        {
          label: 'スライスプレビュー',
          value: 'モデルとスライス図を照合し、モデルの各層の印刷結果を可視化展示し、プロセス工程を正確に標準化し、技術の優れた美しさを表現する'
        },
        {
          label: 'ZYSLR4',
          value: '専用スライスフォーマット、STL、OBJ、Plain 3 dフォーマットファイルにも対応しており、さまざまなCADソフトウェアを使用して設計されたモデルを読み込むことができます'
        },
      ],
  
    },
  },

  softwareSysReq: {
    title: 'システム要件',
    tr: 'Magic Pen Slicer',
    td: [
      "オペレーティングシステム",
      "Windows 10（64）ビット、MacOS 10.15以上のシステム",
      "エクスプローラ",
      "タコ工場では、次のWebブラウザの最新バージョンを使用してアクセスすることを推奨しています。",
      "CPU",
      "Windows 10（64）ビット、MacOS 10.15以上のシステム",
      "ディスク容量",
      "8 GB使用可能なディスク容量（インストール用）",
      "RAM",
      "少なくとも4 GB RAM（8 GB以上のスペースを推奨）",
      "ポインタデバイス",
      "マウス",
    ]
  },

  mainBoard: {
    purchase: '今すぐ購入',
    detailParams: '詳細なパラメータ',
    param: 'パラメータ情報',
    banner: {
      label: 'Genius光硬化制御ボード',
      value: 'ワンストップソリューションのインテリジェントなハンドヘルド',
      des: `市場のLCD露出スクリーンの大部分を互換性があり、設計機能とカスタム製品、設備とアップグレードシステム、技術サポートを提供する`,
    },
    g3: {
      title: '光硬化3 Dプリント静音マザーボードによるワンストップソリューション',
      des: 'マザーボードはFPGAを採用してmipiスクリーン制御を実現し、市販のほとんどのLCD露出スクリーンと互換性があり、設計機能とカスタム製品、設備とシステム、エンジニアに技術とバージョン型カスタマイズ-ユーザー製品ラインを豊富に提供することができる。',
      pic: [
        {
          title:'チップ',
          des: 'TMC 2208ミュートチップ'
        },
        {
          title:'デュアルコア',
          des: 'デュアルコア取り外し可能'
        },
        {
          title:'露出スクリーン',
          des: '14 K露光スクリーン'
        }
      ]
    },

    g2: {
      title: '光硬化3 Dプリント静音マザーボードによるワンストップソリューション',
      des: 'マザーボードはFPGAを採用してmipiスクリーン制御を実現し、市販のほとんどのLCD露出スクリーンと互換性があり、設計機能とカスタム製品、設備とシステム、エンジニアに技術とバージョン型カスタマイズ-ユーザー製品ラインを豊富に提供することができる。',
      pic: [
        {
          title:'チップ',
          des: 'TMC 2208ミュートチップ'
        },
        {
          title:'Wi-Fi',
          des: 'リモート印刷モニタ'
        },
        {
          title:'インタフェース',
          des: 'カメラインタフェース'
        }
      ]
    },
    g1: {
      title: '光硬化3 Dプリント静音マザーボードによるワンストップソリューション',
      des: 'マザーボードはFPGAを採用してmipiスクリーン制御を実現し、市販のほとんどのLCD露出スクリーンと互換性があり、設計機能とカスタム製品、設備とシステム、エンジニアに技術とバージョン型カスタマイズ-ユーザー製品ラインを豊富に提供することができる。',
      pic: [
        {
          title:'チップ',
          des: 'TMC 2208ミュートチップ'
        },
        {
          title:'コンポーネント',
          des: '高品質のコンポーネント'
        },
        {
          title:'外観',
          des: '外観のカスタマイズ'
        }
      ]
    },
    
    mainBoard02: {
      G3:{
        title: 'プロ選手、LCDのコア選択',
        des: '工業級、三大特徴（ブザー、Wi-Fi、デュアルコア）、高級DIY'
      },
      G2: {
        title: '品質厳選、性能とルックスの両立',
        des: '進級、Wi-Fi、遠隔印刷及びビデオ監視、消費級プリンタ'
      },
      G1: {
        title: 'パフォーマンス最適化、コスト優先',
        des: 'エントリーレベル、小容量（2 K、4 K以下の解像度）、コンシューマークラスプリンタ'
      }
    },
  
    mainBoard03: {
      G3:{
        title: 'マルチサイズ露光スクリーン14 K対応超高解像度',
        value: '印刷効率の向上、生き生きとしたディテールの復元',
        des1: '14k',
        des2: '&',
        des3: '断電連続打離型検査',
        des4: '高運用メモリ、512 MDDR、16 G EMMC  ARMアーキテクチャ、小型、低消費電力、低コスト、高性能接続ネットワークポート及びWi-Fi、遠隔印刷などの機能プリンタ断電接続、離型検出と自動材料注入などの機能 2 K、4 K、8 K、14 K露光スクリーン静音ICを搭載し、印刷時に発生する騒音を低減できる'
      },
      G2:{
        title: '2～3倍の動作速度で4 K高画質',
        value: '互換性と安定性、16 G大メモリ',
        des1: '4k',
        des2: '&',
        des3: 'Wi-Fiカメラ',
        des4: '高運用メモリ、512 MDDR、16 G eMMC  ARMアーキテクチャ、小型、低消費電力、低コスト、高性能接続ネットワークポート及びWi-Fi、遠隔印刷などの機能遠隔ビデオ監視をサポートし、遠隔で印刷の全過程をはっきり見ることができ、 2 K、4 K露光スクリーン静音ICに適合し、印刷時に発生する騒音を低減することができる',
      },
      G1:{
        title: '内部設計チップの小型体積最適性能',
        value: '高品質部品、個性的な外観',
        des1: 'ハイパフォーマンス',
        des2: '&',
        des3: '低コスト、低消費電力',
        des4: '高性能メモリ、64M DDR、512M SDAND\ ARM アーキテクチャ、小型サイズ、低消費電力、低コスト、高性能\ ネットワーク ポートと Wi-Fi、リモート印刷およびその他の機能をサポート\ 2K および 4K 露出スクリーンに最適\ 静音IC、印刷時のノイズを軽減できます。',
      }
    },

    cpu: {
      title: '静音モータ駆動、低消費電力、小さくて強い',
      des: 'MIPI高速インターフェース-2.5 Gbpsを採用し、最高14 K以上のMIPIスクリーンをサポートし、輸入のモータ駆動チップTMC 2208を搭載し、高速運動下での超静音効果を実現し、主制御とFPGAの間にSDIOを採用して通信を保証し、各層の画像の正常な出力と表示を確保し、主制御は全志高性能処理チップを採用し、汎用のステッピングモータモジュールインターフェースを提供し、外接2209、などの大電流の駆動モータモジュールは、適応性が強い。',
      des2: '視覚処理アプリケーションに優れたパフォーマンスを提供'
    },

    oem01: {
      top:{
        title: '美的外観&実用性',
        des: 'カスタマイズ'
      },
      down: {
        title: 'OEMカスタマイズは、ユーザーが自分のニーズや好みに合わせてタッチスクリーンの外観、機能、パフォーマンスを設計、選択できるようにする高度にカスタマイズされたサービスです。このカスタマイズサービスは、より多くの選択肢を提供するだけでなく、ユーザーの個性的なニーズに合わせて製品を提供します。',
        des: 'OEMカスタマイズ',
        des2: '製品シナリオ'
      }
    },

    oem02: {
      title: 'プロセス設計',
      des1: '制約のない成形プロセスが設計制限を突破する 構造にとらわれない巧みな設計の実現',
      des2: '新しい製品理念をロック解除し、製品カスタマイズを再定義し、顧客の多様なニーズに応え、我々は製品カスタマイズ、機能設計などを提供することができ、柔軟な調整を実現することができ、フレキシブル製造の現代的な生産理念に合致し、3 Dプリントプロセスを突破し、OEMカスタマイズは顧客に付加価値をもたらす。',
      des3: 'ソリューション',
      des4: '製品の造形、機能などに対してアップグレードすることができ、製品のアップグレード方案を提供して顧客自身が柔軟に選択することができる。'
    },
    bordShow02: {
      title: 'デュアルコア自由選択の組み合わせ',
      des: '異なるコアボードを柔軟に採用してマザーボードの迅速なカスタマイズを実現し、研究開発周期を下げ、顧客に完全なOEMカスタマイズ方案を提供する'
    },
    lastPage: {
      title: '専用3 D印刷制御システム',
      des1: '均一な光システム、より高速な動作、直感的なUIインタフェース',
      des2: '自己研究は3 D印刷制御システムに特化し、Geniusマスター制御方案と協力して仕事を行い、より効率的な印刷過程を実現し、3 D印刷技術の発展を深く支援する。'
    }

  },

  resin: {
    banner: {
      label: 'LCDデスクトップクラス感光性樹脂',
      value: '科学技術の美学と工業の両立',
      des: `Bloom-740 w 3 Dプリンタ用に設計された樹脂は、材料の柔軟性がより良い結果とより楽しい体験をもたらす`,
      purchase: '今すぐ購入'
    },

    swiper:{
      des1: 'ABS-Wash',
      des2: 'Water-Wash',
      des3: '樹脂モデル',
    },

    introduce:{
      top:{
        title:'創造的応用突破材料革新リーダー'
      },
      left01:{
        title: 'ABS-Like Resin',
        des: 'ABS樹脂は優れた強度と耐久性を持ち、様々な用途に適している。それは多くの化学品に対して優れた耐性を持っている。ABSは成形、切断、機械加工が容易で、各種生産技術に適している。滑らかで光沢のある表面を作り出すことができます。ABSは、外観を変更したり、保護を追加したりするために、塗装と表面処理を容易にすることができる。ABS樹脂は一定温度範囲で安定性を維持している。衝撃と振動に対して優れた吸収性能を持っています。'
      },
      right02:{
        title:'ざいりょうとくせい',
        des1: 'ABS樹脂は耐衝撃強度が高く、吸水性が小さいなどの優れた性能を持ち、加工成形が容易であり、また優れた耐低温性と耐クリープ性があり、表面光沢性が良い',
        des2: '高温に耐える',
        des3: '高い固色度と色安定性',
        des4: '優れた表面品質',
        des5: '優れた強度と耐久性'
      },
      left03:{
        title:'ざいりょうとくせい',
        des1: 'Water-Wash Resinは性能が均衡しており、高い引張強度と延性と耐衝撃特性を持っており、機能性部品及び端末製品ケースの直接生産に適している',
        des2: '洗いやすい',
        des3: 'こうそくこうか',
        des4: '低臭気',
        des5: '高解像度印刷用'
      },
      left04:{
        title: 'Water-Wash Resin',
        des:'水洗樹脂モデルは水洗でき、流動性がよく、高精度、環境保護、互換性が広く、高価格比、完成品の表面が滑らかで、硬化時間が短く、モデルの印刷性能を保証すると同時に、印刷成形製品の離型しにくい難題を解決し、印刷成功率を大幅に向上させ、離型フィルムの使用寿命を延長する。樹脂はモデルの強度と過度の収縮を保証すると同時に、印刷速度を高め、硬化時間を短縮し、機械の印刷効率を向上させる。'
      }
    },

    introduce02:{
      title: '工芸作品の応用展示特徴性能'
    },
    argument:{
      title:'基本的なパフォーマンス',
      des1:'多様なアプリケーション',
      des2: '異なる応用次元、異なる応用の印刷材料、性能と美学の総合的な需要を満たし、高性能、高精度、高安定の技術と材料を提供し、カバーは応用がある同時に、作業者がより多くの革新的な応用を開発することを支持する',
      tab:{
        head:{
          cell01: 'テスト項目',
          cell02: '単位',
          cell03: 'テスト基準',
          cell04: '数値＃スウ＃',
        },
        title:[
          'ひっぱりつよさ',
          'ひっぱりけいすう',
          'はだんのび',
          'まげつよさ',
          'まげだんせいけいすう',
          'しょうげきつよさ',
          'かたさ',
          'こうふくてんのび',
          '最大力点変形',
          '最大の力'
        ]
      }
    }

  },
  coreOctoApp: {
    banner: {
      label: '章鱼核',
      value: 'タココアは統合された3 Dプリントサービスプラットフォームで、クラウドスライスとリモートタスクの配布を提供し、いつでもどこでも3 Dプリントを楽しむことができます',
    },
    richRe: {
      label: '高品質モデル',
      value: 'アイデアが止まらない',
      des: '大量のモデル資源は、モデル資源を簡単かつ迅速に取得し、利用する方法を提供し、あなたのアイデアをより便利に実現させ、私たちはオリジナルモデルの有料機能もサポートし、クリエイターが自分の作品の収益を得ることができるようにします'
    },

    cloudOp: {
      top: {
        title: 'Visualization Managementプラットフォーム',
        des: '3 Dデジタル化印刷の全プロセス可視化管理プラットフォームは、クラウドスライス、リアルタイム監視、ビデオ再生、クラウドタスクの発行などの機能を備え、親切な一体化サービスを提供し、1台の携帯電話だけで、クラウド3 D印刷を実現することができる'
      },
      thr: [
        {
          title: 'リアルタイムモニタリング',
          des: 'プリンタの動作状態を常に観察し、印刷プロセスの安定性と安全性を確保できます'
        },
        {
          title: 'ビデオ再生',
          des: '印刷プロセスを自動的に記録し、ユーザーが印刷障害をより診断できるようにする'
        },
        {
          title: 'クラウドスライス',
          des: 'スマートアルゴリズムによってスライス処理を行い、同時にスライス後のモデル効果をプレビューすることができ、スライスは印刷することができる'
        },
        {
          title: 'クラウドタスクの配布',
          des: 'PC であってもモバイルであっても、リモート管理によりタスクをすばやく送信して作業を開始できます。'
        }
      ]
    },
    range: {
      title: 'リモート印刷',
      des: 'リモート送信制御の3 Dプリンタをサポートし、オンラインスライスを通じて、複数のデバイス切り替えができ、リモート一体スライス印刷を実現する'
    },

    cloudServer: {
      top: {
        title: 'クラウドサービス',
        des: 'クラウドのオンラインバッチモデルスライス操作により、ユーザーはスライスを迅速に体験でき、簡単で明瞭で直感的で、便利で迅速で効率的で、3 Dクラウドストレージ空間を提供し、ストレージ空間を減少し、いつでもアクセスし、編集することができる'
      },
      left: {
        title: 'バルクモデルスライス',
        des: '複数のモデルファイルを一括アップロードして、複数のモデルを迅速かつ正確にスライスすることができます'
      },
      right: {
        title: 'クラウドストレージスペース',
        des: '安全で信頼性の高い3 Dクラウドストレージスペースを提供し、モデルファイルをクラウドにアップロードし、いつでもどこでもアクセスして編集することで、3 Dモデルの保存と管理を容易にする'
      }
    }
  },

  application:{
    banner:{
      title: '異なる分野における3 D印刷技術の優位性を探る'
    },
    page:[
      {
        title:'製品のプロトタイプ',
        des:'3 D印刷技術は、設計ファイルを直接ソリッドモデルに変換し、迅速かつ柔軟にプロトタイプを作成することができます。この高速プロトタイプ作成は、製品開発サイクルを大幅に短縮し、生産コストを削減するだけでなく、複数回の設計反復を行い、製品設計を迅速に検証し、改善することができます。製品設計と開発のために、より効率的で便利で革新的なソリューションを提供します。'
      },
      {
        title:'デジタル歯科',
        des:'光硬化三次元印刷技術は伝統的な歯科カスタマイズの過程で重要な役割を果たしている。この技術により、医師は患者の口腔状況に応じて、正確な歯科モデルを迅速に生成し、患者のために歯科修復案をカスタマイズすることができる。従来のハンドメイドや金型の使用に比べて、光硬化三次元印刷技術はより迅速にプロセス全体を完成することができるだけでなく、より高い精度と品質を実現することができ、歯科業界を悩ます長年の精度問題を効果的に解決した。'
      },
      {
        title:'革新的な力で未来を印刷',
        des:'3次元印刷技術は建築業界で広く応用されており、建築モデリング、建築部材製造、革新的な設計、修復とメンテナンス、持続可能な建築を含む。カスタム生産と精密製造を通じて、建築家とデザイナーが設計理念と考え方をより直感的にイメージ化するのを助け、より創造的で芸術的な建築作品を創造することができます。また、建築過程における無駄や環境への影響を減らし、建築業の持続可能な発展を実現するために新たな活力と原動力を注入することもできる。'
      },
      {
        title:'フィギュア',
        des:'3 Dプリントはハンドモデル全体の制作プロセスを大幅に簡略化し、制作周期を短縮し、コストを節約すると同時に、モデルの構造比率をより正確にし、ハンドモデルの精細度要求を最大限に満たす。カスタマイズされた生産は、お客様の個性的なニーズを満たすことができます。'
      },
      {
        title:'ジュエリーデザイン',
        des:'光硬化3 D印刷技術はジュエリー製造の核心である蝋型製作の限界とコスト問題を解決し、個人的なカスタマイズを可能にした。'
      }
    ]
  },

  knowCenter: {
     banner: {
      label: '品質でブランドを作る',
     },
     product:[
      'Bloom-740w',
      'Magic Pen Slicer',
      'タコの種',
      'Genius I',
      'Genius II',
      'Genius III',
    ],
    handBook:[
      '製品マニュアル',
      'ソフトウェアマニュアル',
      'プラットフォームマニュアル',
      'マザーボードのマニュアル'
    ]
  },

  belonging: {
    bread: {
      belonging: '多様性と所属感'
    },
    banner: {
      label: '多様性と所属感',
      value: '私たちは多様な従業員チームと所属感の文化を構築しており、より多くの人々に美しい世界を想像し、デザインし、創造する機会を提供しています'
    }, 
    oppor: '機会の拡大',
    change: '変革をリードする',
    intro1: '多様性は新しいアイデア、創造性、ビジネスの成長を引き起こします。章魚工房では、さまざまなバックグラウンド、視点、信念を持つ個人を歓迎し、多様で包括的な環境を築くことに取り組んでいます',
    intro2: '変革をリードする企業として、私たちはできるだけ多くの人々に未来の仕事に参加する機会を創造する責任があります',
    lis: [
      {
        label: '多様性と包摂性',
        value: '多様なチーム、包括的な文化'
      },
      {
        label: '才能と発展',
        value: '活力を引き出し、成長をサポート'
      },
      {
        label: '創造力',
        value: '突破的なイノベーション、未来の探求'
      },
      {
        label: '所属感',
        value: '包摂、協力、進歩、発展'
      },
      {
        label: '責任と信頼',
        value: 'お客様第一、協力して共に勝利'
      },
      {
        label: '現在と未来',
        value: '技術革新を切り拓き、未来のテクノロジーを生活に結びつける'
      }
    ]
  },
  csrp: {
    title: '企業社会的責任方針', 
    txtLis: [
      '章魚工場は2017年に設立され、革新の精神、先代の遺産を承け継ぎ、専門的な知識を核とした価値観を持っています。各3Dプリンター、樹脂、マザーボードは、3D技術の研究開発から得られた技術の継承に触発されており、深い歴史を持つ3Dコンピュータグラフィックスの伝説を語り、積極的で詩的な人生観を表現しています',
      '尊重自然環境以及従業員や社会の利益を尊重することは、章魚工場の重要な企業文化の一部です。このポリシーでは、Octopus Works（章魚工場）が顧客、従業員、株主、サプライチェーンのパートナー、そして社会全体に対して企業の社会的責任を果たす方法を説明します',
      '章魚工場はビジネス、社会、環境に対して持続可能な未来を創造することを約束しています。企業の社会的責任を章魚工場の高度な3D技術に統合し、この理念をすべての利害関係者と共有します。章魚工場は、誰もが持続可能な発展に貢献できると深く信じています',
      '章魚工場は、循環経済の概念を積極的に支持しています。会社はできるだけ「取得、製造、廃棄」（take, make, dispose）の線形モデルを避け、革新的な循環アプローチを支持し、製品、部品、および材料が最大限に活用されるようにし、設計段階から廃棄物を最小限に抑える取り組みをしています',
      '章魚工場は運聯網グループの一員として、ビジネス活動を行う際には常に誠実を基本とし、信頼を最優先にしています。同時に、グループの『商業行為准則』に基づいて、あらゆる利害関係者を尊重しています',
      '章魚工場は『世界人権宣言』に基づいて事業を行い、従業員やパートナーの権利を尊重しています。人権への影響を解決し、監視し、最小化するために、章魚工場は『国連ビジネスと人権に関するガイドライン』に基づいて行動し、すべての適用可能な法律と規制を遵守しています。さらに、章魚工場は定期的に企業の社会的責任と監督ポリシーのパフォーマンスを見直し、持続的な改善を行っています'
    ],
    employer: {
      label:'責任ある雇用主',
      des: '章魚工場は企業の中核価値を強調し、チームワークを重視し、従業員に安全で健康的で公正かつ刺激的な労働環境を提供することに努めています。健康と安全に関する具体的な原則は、同社の『環境、健康及び安全政策』に詳細に記載されています。章魚工場は従業員に成長の機会を提供し、彼らがビジネスを管理するスキルを持っていることを確認しています。同社は多文化主義を重視し、採用、雇用、成長、および定着のプロセスにおいて平等な機会を推進しています',
    },
    purchasing: {
      label: '責任ある調達',
      des: '章魚工場は責任ある方法で原材料を調達することを約束し、ビジネス倫理、人権、労働権、自然環境を尊重します。さらに、章魚工場はサプライヤーに対して運聯網グループの『サプライヤー行動規範』の順守を求め、それに基づいて自身の事業がこれらの指針と一致していることを確認します。章魚工場はサプライヤーに、これらの原則を各自の下請け業者やサプライヤーに伝え、これらの原則を事業ポリシーやガイドラインに組み込むよう奨励しています。章魚工場は引き続き業界団体やビジネスパートナーと協力し、価値連鎖全体で最良の実践を推進していきます。章魚工場は原材料を長期的かつ持続可能な方法で調達するための解決策を模索します'
    },
    envir: {
      label: '環境責任',
      des: '章魚工場は継続的に環境管理の改善に努め、環境への影響を減少させることに注力しています。循環型経済を促進するために革新的なソリューションを模索しています。運聯網グループの一部として、科学に基づいた削減を提唱し、明確な発展の道筋を示し、2030年までに気候変動への影響を減少させ、将来的にはより環境に優しい形で事業を拡大できるようにしています'
    },
    inver: {
      label: '社会投資',
      des: '章魚工場はその革新的な精神、先代を尊重し未来を切り拓く姿勢、専門的な知識など、中心的な価値観を広めることに取り組んでいます。これにより、社会と共有される積極的な人生観を提供しています。章魚工場は文化、技術、芸術、伝統、教育、研究、環境などの分野で様々な社会投資活動に積極的に参加し、社会に対して積極的な貢献を目指しています'
    }
  },

  profile: {
     about: {
      label: '私たちについて',
      des1: '深セン市タコ工場科学技術有限公司',
      des2: '2017年に設立され、本社は広東省深セン市宝安区にある。3 D印刷のデジタル化生産方案サプライヤーとして、会社の主な業務は3 D印刷設備、ソフトウェア、消耗品、技術方案などである。会社は自主的に研究開発し、自主的に革新し、製品技術は完全に自主知的財産権を持っている。タコ工場は世界をリードするグラフィック画像情報処理、並列計算、異種計算、大規模集積回路と機械自動化ソリューションのサプライヤーであり、現在は3 Dプリント増材製造分野に集中し、デジタル化管理運営を堅持し、持続的な探索と革新を続け、デジタル化小世界の実現に力を入れ、デジタル化を主とし、視覚情報を補助とする多彩な世界を創造する。'
    },

    framework: {
      title: 'プロダクトアーキテクチャ',
      lis: [
        {
          label: 'Bloom-740w',
          value: '消費レベルの光硬化3 Dプリンタ'
        },
        {
          label: 'ひかりじゅし',
          value: '3 Dプリント消耗品'
        },
        {
          label: 'Genius制御ボード',
          value: 'OEMカスタマイズ'
        },
        {
          label: 'Magic pen Slicer',
          value: '3 Dスライス前処理ソフトウェア'
        },
        {
          label: 'タコ核雲プラットフォーム',
          value: '3 Dプリントサービスプラットフォーム'
        },
      ]
    },

    authentication: {
      label: '国際認証',
      text:[
        {
          title: 'FCC認証',
          des: '電磁互換性'
        },
        {
          title: 'PSE認証',
          des: '製品の安全性'
        },
        {
          title: 'TELECT認証',
          des: '製品規格'
        }
      ]
    },

    culture: {
      title: '企業文化',
      text:[
        {
          title: '価値観',
          des: '誠実、探索、革新、科学技術',
          tran: 'Values'
        },
        {
          title: 'ビジョン',
          des: '信頼できるパートナー、成功への道',
          tran: 'Vision'
        },
        {
          title: 'ミッション',
          des: '3 Dボード業界のリーダーになる',
          tran: 'mission'
        }
      ]
    },

    patent: {
      title: '資格専利'
    }

  },

  installDownload: {
    title: 'ソフトウェアのダウンロードとインストール',
    downloadSoft: 'ソフトウェアのダウンロード',
    content: [
      {
        name: 'インストールの準備',
        label: '情報を収集し、コンピュータを準備する',
        value: 'Magic Pen Slicer ソフトウェアをインストールする前に、システムを準備し、必要な情報をすべて収集してください',
        lis: [
          {
            label: '1. 必要な条件をチェックしてください',
            value: [
              'システム要件の確認',
              '認定および推奨されたコンピュータハードウェアを使用していることを確認してください',
              'インストールしているソフトウェアのリリースノートを読む'
            ]
          },
          {
            label: '2. ライセンスを取得する',
            value: 'インストールには管理者権限が必要です。Windowsで確認するには、コントロールパネル > ユーザーアカウント > ユーザーアカウントの管理 を選択してください'
          },
          {
            label: '3. システムの更新をインストールし、ウイルス対策プログラムを無効にする',
            value: '保留中のオペレーティングシステムの更新がある場合は、それらをインストールして再起動してください。通常、インストールに干渉する可能性があるため、一時的にウイルス対策プログラムを無効にすることを検討してください'
          },
          {
            label: '4. インストールプログラム以外のすべての実行中のアプリケーションを閉じる'
          }
        ]
      },
      {
        name: 'あなたのソフトウェアをダウンロードしてください',
        lis: [
          {
            label: '製品および更新をダウンロードする場所',
            value: 'ソフトウェアをダウンロードするには、章魚工場の公式ウェブサイトを訪れてください',
            lis: [
              {
                label: 'ソフトウェアのダウンロード',
                value: [
                  '1. https://octopusworks.com/ へアクセスしてください',
                  `2. メニューバーで 'Products' をクリックし、展開されたリストで 'Magic Pen Slicer' をクリックしてダウンロードページに移動してください`,
                  '3. ダウンロードしたいソフトウェアを選択してください'
                ]
              },
              {
                label: 'ソフトウェアを更新する',
                value: 'ソフトウェアを更新するには、ソフトウェア内でメニューバー - ヘルプ - 更新を確認 をクリックしてください。または、章魚工場の公式ウェブサイトから最新バージョンのソフトウェアをダウンロードできます'
              }
            ]
          },
          {
            label: 'ダウンロードしてインストール',
            lis: [
              {
                label: '適切なインストール方法を選択してください',
                value: 'https://octopusworks.com/ の製品セクションから、Magic Pen Slicer をクリックしてダウンロードできます'
              },
              {
                label: 'インストール',
                value: `Octopus Works の公式ウェブサイトから Magic Pen Slicer（3D印刷スライシングソフトウェア）をダウンロードし、ライセンス契約にチェックを入れ、ソフトウェアのインストール場所を選択します（Dドライブにインストールすることをお勧めします）。次へをクリックしてインストールを開始し、インストールが完了したら完了をクリックしてください` 
              }
            ]
          }
        ]
      }
    ]
  },
  support: {
    title: '製品サポート',
    banner: {
      label: '製品サポート',
      value: '最新のテクノロジーと環境概念を導入し、スマートなデザイン'
    },         
    soft: {
      sysReq:  '製品システム要件',
      name: 'Magic Pen Slicer',
      proVer: 'カバーされる製品とバージョン',
      des: '章魚工場のMagic Pen Slicer 2023ソフトウェアは、以下の64ビットのオペレーティングシステムでサポートされており、少なくとも以下の64ビットのハードウェアを搭載したシステムが必要です：',
      form1: {
        soft: 'ソフトウェア',
        op: {
          label: 'オペレーティングシステム',
          value: 'Windows 10（64ビット）、MacOS 10.15 以上'
        },
        bro: {
          label: 'ブラウザ',
          value: '章魚工場は、オンライン補足コンテンツにアクセスする際に、以下のWebブラウザの最新バージョンを使用することをお勧めします：'
        }
      } ,
      form2: {
        hard: 'ハードウェア',
        cpu: {
          label: 'cpu',
          value: 'Windows 10（64ビット）、MacOS 10.15 以上'
        },
        vch: {
          label: 'グラフィックスカードハードウェア',
          value: '推奨されるシステムおよびグラフィックスカードの詳細なリストについては、Magic Pen Slicer 認定ハードウェアを参照してください'
        },
        diskSp: {
          label: 'ディスクスペース',
          value: '8GB 利用可能なディスクスペース'
        },
        ram: {
          label: 'RAM',
          value: '最低 4GB の RAM（8GB またはそれ以上を推奨）'
        },
        pointDev: {
          label: 'ポインティングデバイス',
          value: 'マウス'
        }
      }
    },
    gBoard: {
      name: 'Genius コントロールボード',
      proVer: '対象製品およびバージョン',
      lis: [
        {
          name: 'Genius I シリーズ サイレント メインボード',
          expScr: {
            label: '露光スクリーン',
            value: '最大4K解像度サポート'
          },
          power: '電源',
          weight: '重量',
          intSto: 'メモリ',
          intSup: {
            label: 'インターフェースサポート',
            value: 'I2C、UART、SPI、GPIOなど'
          }
        },
        {
          name: 'Genius II シリーズ サイレント メインボード',
          expScr: {
            label: '露光スクリーン',
            value: '最大4K解像度サポート'
          },
          power: '電源',
          weight: '重量',
          intSto: 'メモリ',
          intSup: {
            label: 'インターフェースサポート',
            value: 'I2C、UART、SPI、GPIOなど'
          }
        },
        {
          name: 'Genius III シリーズ サイレント メインボード',
          expScr: {
            label: '露光スクリーン',
            value: '最大12K解像度サポート'
          },
          power: '電源',
          weight: '重量',
          intSto: 'メモリ',
          intSup: {
            label: 'インターフェースサポート',
            value: 'I2C、UART、SPI、GPIO、Type-Cなど'
          }
        },
      ]
    }
  },

  technicalParam: {
    title:'技術パラメータ',
    table: [
      {
        th: 'ボディ',
        td:[
          '印刷サイズ',
          '163.92*102.4*180mm³ （縦*幅*高さ）',
          'シャフトプレート',
          'マグネシウム合金',
          'シャーシ本体',
          'ABSプラスチック',
          'ベース',
          'ABSプラスチック',
          'カバー枚',
          'ABSプラスチック'
        ]
      },
      {
        th: 'プリントヘッド',
        td:[
          '接続構造',
          'プレスバックル'
        ]
      },
      {
        th: 'プラットフォームの構築',
        td:[
          '寸法すんぽう',
          '163.92 mm*102.4 mm（縦*幅）',
          'プラットフォーム',
          'ふんしゃ',
          'レベリング',
          'ねじの平坦化'
        ]
      },
      {
        th: 'スピード',
        td:[
          '印刷速度',
          '20-60 mm/h',
          'モータ運動最大速度',
          '10000 mm/s²',
        ]
      },
      {
        th: 'れいきゃく',
        td:[
          '光源放熱ファン',
          'へいループせいぎょ',
        ]
      },
      {
        th: 'サポートされる消耗品タイプ',
        td:[
          'ABS-Like Resin，Water-Wash Resin',
          '印刷に適した',
        ]
      },
      {
        th: 'センサー',
        td:[
          '下限位置センサ',
          '光電センサ',
        ]
      },
      {
        th: '物理サイズ',
        td:[
          '製品サイズ',
          '260*260*465 mm³ （縦*幅*高さ）',
          'ネットウェイト',
          '6.55kg'
        ]
      },
      {
        th: '電気パラメータ',
        td:[
          'にゅうりょくでんあつ',
          '100-240 VAC, 50/60 Hz',
          '最大電力',
          '60 W',
          '電源コネクタ',
          '品字ACソケット'
        ]
      },
      {
        th: 'でんしそし',
        td:[
          'ディスプレイ',
          '5インチ800 x 480容量式タッチスクリーン',
          'コミュニケーション',
          'Wi-Fi',
          'ストレージ',
          'USB',
          '操作インタフェース',
          'タッチパネル、携帯アプリ、パソコンアプリ',
          'モーションコントローラ',
          'シングルコアARM Cortex-A 7プロセッサ'
        ]
      },
      {
        th: 'ソフトウェア',
        td:[
          'Magic pen slicer',
          'その他のエクスポート可能zip形式ファイルのサポート',
          'オペレーティングシステム',
          'MacOS、Windows'
        ]
      }
    ]
  },


  bordParamInfo:{
    title: 'パラメータ情報',
    G1:{
      tr: 'Genius I',
      td:[
        '外观尺寸',
        '120*80*16mm',
        'アパーチャ距離',
        '114*74mm',
        'アパーチャ直径',
        '3mm',
        'にゅうりょくでんあつ',
        '24V',
        '電源コネクタ',
        '5.08 2P',
        'インタフェースタイプ',
        'MIPI',
        'マイクロプロセッサ',
        'Cortex-A7',
        'モータ駆動チップ',
        'TMC2208',
        'タッチパネル対応',
        '3.5  5  7インチカラータッチスクリーン',
        '印刷画面のサポート',
        '5.96inch、DBT066MONO(6.6inch 4K Mono)、DXQ608-X04(6.08inch 2K Mono)、123D089CFSP01-00(8.9inch 4K Mono)',
        'スライスファイルフォーマットのサポート',
        'zip、zyslr4',
        '推奨スライスソフトウェア',
        'Magic Pen Slicer',
        'データインタフェース',
        'Uディスク、メッシュポート'
      ]
    },
    G2:{
      tr: 'Genius II',
      td:[
        '外观尺寸',
        '100*80*18mm',
        'アパーチャ距離',
        '94*74mm',
        'アパーチャ直径',
        '5.08mm',
        'にゅうりょくでんあつ',
        '24V',
        '電源コネクタ',
        '5.08 2P',
        'インタフェースタイプ',
        'MIPI',
        'マイクロプロセッサ',
        'Cortex-A7',
        'モータ駆動チップ',
        'TMC2208',
        'タッチパネル対応',
        '3.5  5  7インチカラータッチスクリーン',
        '印刷画面のサポート',
        '5.96inch、DBT066MONO(6.6inch 4K Mono)、DXQ608-X04(6.08inch 2K Mono)、123D089CFSP01-00(8.9inch 4K Mono)',
        'スライスファイルフォーマットのサポート',
        'zip、zyslr4',
        '推奨スライスソフトウェア',
        'Magic Pen Slicer',
        'データインタフェース',
        'Uディスク、メッシュポート'
      ]
    },
    G3:{
      tr: 'Genius III',
      td:[
        '外观尺寸',
        '120*80*18mm',
        'アパーチャ距離',
        '114*74mm',
        'アパーチャ直径',
        '5.08mm',
        'にゅうりょくでんあつ',
        '24V',
        '電源コネクタ',
        '5.08 2P',
        'インタフェースタイプ',
        'MIPI',
        'マイクロプロセッサ',
        'Cortex-A7',
        'モータ駆動チップ',
        'TMC2208',
        'タッチパネル対応',
        '3.5  5  7インチカラータッチスクリーン',
        '印刷画面のサポート',
        '5.96inch、DBT066MONO(6.6inch 4K Mono)、DXQ608-X04(6.08inch 2K Mono)、123D089CFSP01-00(8.9inch 4K Mono)',
        'スライスファイルフォーマットのサポート',
        'zip、zyslr4',
        '推奨スライスソフトウェア',
        'Magic Pen Slicer',
        'データインタフェース',
        'Uディスク、メッシュポート'
      ]
    }
  },

  warranty:{
    title: '保証ポリシー',
    instructions:'当社の保証は以下の内容をカバーします。',
    des: 'デルの保証には、ブルーム740 wは1年間保証、マザーボードとタッチスクリーンは1年間保証（無償提供）が含まれています。',
    collapse:[
      {
        label: 'スワップ政策',
        li:[
          '署名日から7日間の自然な日のうち、次のいずれかに該当する場合は、返品/交換サービスを提供します。元の購入チャネルで返品/交換サービスを申請できます。',
          '1.製品外観に損傷、傷、汚れ、ひび割れ、変形などがないことを要求する；ランダム部品、工具、景品、包装、説明書などの要求は完全である。弊社のアフターサービス検査の判定により、返品して受け取った製品の外観が損傷し、部品が不足し、説明書が紛失したり、元の包装に従って梱包して郵送していないことによる問題が発生したりした場合、顧客は相応の費用を負担しなければならず、具体的な損失と費用は返送した後、専門のアフターサービス担当者に検査して計算しなければならない。',
          '2.非品質問題返品、例えば消耗品を使用した場合、顧客は消耗品の費用を負担する必要がある、包装紙カセットは戻ってから2回使用することはできないため、顧客は包装費を負担しなければならず、具体的な損失と費用は返送してから専門のアフターサービス担当者に検査して計算しなければならない。',
          '3.元の購入証明書、3パックの証明書及び関連証明書を提供できない、或いは購入証明書に偽造があり、証拠を塗り替え、返品/交換サービスの提供を拒否する。',
          '4.使用が開始され、二次販売に影響を与えている製品は、返品/交換サービスの提供を拒否します。',
          '5.製品が輸送によって損傷したことを発見したが、その場で拒否せず、事後にも物流会社の貨物損失証明書を提供できず、返品/交換サービスの提供を拒否した。',
          '6.気に入らない、欲しくない、撮り間違え、不適切な使用、製品に満足していないなどの原因による返品/交換は、運賃の返却は顧客の自己負担が必要である。',
          '7.交換する時、お客様は先に製品を返送する必要があり、会社は製品を受け取ったら直ちに出荷を手配します。',
          '注：アフターサービスの重要な根拠として、購入証明書、ショッピング領収書、保証カード及び関連証明書を保管してください。',
          'プラットフォーム購入サポートは7日に返品する理由がなく、つまり製品の受領時間を起算して、168時間を満タンにして7日とする、製品返品運賃はユーザー負担',
          '製品の品質問題により、他の人が破損した場合、署名後15日以内にタコ工場に連絡して交換サービスを行うことができる'
        ]
      },
      {
        label: '保証ポリシー',
        li:[
          '1.購入した日から、プリンター、マザーボード、タッチスクリーン設備は1年間の無料保証サービスを享受し、消耗品LCDスクリーン、離型フィルム、消耗品と景品は除く。',
          '2.損傷しやすい部品（ヘッドセット、ガラスなど）の保証3ヶ月。',
          '3.保証期間内の修理：もしあなたの製品が落下、浸水していない場合、無許可で分解改造などの情況、故障が材料、技術或いは機能不良によるものであることを確定し、弊社のアフターサービス検査により品質問題と判定され、会社は無料修理或いは部品交換を行う。人為的な非品質問題であれば、修理費を負担する必要はなく、交換する部品の費用を負担する必要があります。',
          '4.保証期間外修理：修理過程で発生した人件費、部品費、包装費、往復運賃などのすべての費用を顧客が負担する。'
        ]
      },
      {
        label: '以下の場合は保証の対象外です',
        li:[
          '1.有効な機械製造コード、購入証明書、保証カードを提供できない、または情報が塗り替えられ、字がぼやけ、認識できないなど。',
          '2.通常の使用摩耗による部品の損傷。',
          '3.説明書の指導に従っていない、正しく取り付け、使用及び操作していない、或いは無断で製品を改造して製品の故障或いは損傷をもたらした。',
          '4.製品に規定されていない作業環境で使用することにより、製品の故障または損傷を引き起こす。',
          '5.乱用（作業負荷超過など）またはメンテナンス不適切（湿気、液体注入、浸漬、カビ発生など）による製品の故障または損傷。',
          '6.不可抵抗要素（例えば：火災、地震、落雷、水害など）による製品の故障または損傷。',
          '7.他のブランド部品または低品質消耗品の使用による設備の故障または損傷。',
          '8.公的に認可されていないサービス拠点で修理された製品。',
          '9.製品が輸送によって損傷したことを発見したが、その場で拒否せず、事後に物流会社の貨物損失証明書を提供することができなかった。'
        ]
      }
    ]
  },


  faq: {
    title: 'よくある質問',
    lis: [
      {
        label: 'Genius制御ボード',
        collapse:[
          {
            problem: 'Q：Geniusコントロールボードとは？',
            li:[
              'A：Genius制御マザーボードは市場のほとんどのLCD露出スクリーンと互換性があり、設計機能とカスタマイズされた製品、設備、システムエンジニアに技術とバージョンのカスタマイズを提供することができる。',
              'FPGAを用いたmipiスクリーン制御の実現',
              'OEMカスタムハードウェア機能とバージョン',
              'プリント時のノイズを低減するミュートIC',
              'ネットワークポートとWi-Fiを接続し、遠隔印刷操作',
              'CRC検査を採用し、データの一致性を保証する'
            ]
          },
          {
            problem: 'Q：Genius Controlマザーボードは何に使用できますか。',
            li:[
              'A：1.基本的な印刷制御ロジックを提供し、製品開発サイクルを短縮する',
              '2. 外部インタフェースを提供し、迅速にカスタマイズニーズを実現する',
              '3. アプリケーションを接続して遠隔制御と監視を行う',
              '4. Linux システムと Qt を使用して高解像度の UI インターフェイスを表示します'
            ]
          }
        ]
      },

      {
        label: '感光性樹脂',
        collapse:[
          {
            problem: 'Q：ABS樹脂の特徴は何ですか？',
            li:[
              'A：1. 表面品位が良好で、組立ワーク等のタップ加工に使用できます。',
              '2.高強度と精度、完成品は良好な強靭性を持ち、成形が優れ、耐衝撃が良い。',
              '3.切削可能で、手彫り特性を備え、深さ硬化後の表面は耐掻き。'
            ]
          },
          {
            problem: 'Q：水洗樹脂にはどんな特徴がありますか？',
            li:[
              'A：1.環境に配慮した安全性',
              '水洗感光性樹脂は粘度が非常に低く、流動性がよく、においが低く、安全上の危険性が少なく、汚染が発生しない。',
              '2.高精度',
              '水洗光硬化樹脂は成形精度が高く、印刷製品の表面が滑らかで、印刷の詳細がはっきりと見える。',
              '3. 優れたパフォーマンス',
              '水洗樹脂は、強度、靱性、剛性などの機械的特性のバランスが取れており、壊れにくいです。',
              '4.印刷しやすい',
              '低粘度、離型成功率が高く、水洗光硬化樹脂の支持を容易に取り外すことができる。処理が容易になり、モデルをより美しくすることができます。',
              '5.応用が広い',
              '水洗感光性樹脂は、アニメフィギュア、建築工業部品などの印刷に使用することができる。'
            ]
          }
        ]
      },

      {
        label: 'Bloom-740w',
        collapse:[
          {
            problem: 'Q：レベリング操作？',
            li:[
              'A：印刷プラットフォームと画面の距離が高すぎると、モデルが印刷プラットフォームに形成されないため、まず平らに調整する必要があります。',
              'まずプリンタのタッチパネルのトップページにある「モーター」をクリックし、上昇プラットフォームをクリックし、次にスロットのねじを緩め、スロットを取り出します',
              '平らなねじを少し緩めて、A 4用紙を露出スクリーンに置いて、平らに置くだけでいいです',
              'トップページのモーターをクリックして、「レベリング」をクリックして、プラットフォームを露出画面に下げて、プラットフォームの位置と角度を調整して、時計回りにねじを締めて、紙はプラットフォームを抽出できなくて、つまりレベリングに成功しました',
              'レベリングに成功したら、プラットフォームを上げてスロットに入れてください'
            ]
          },
          {
            problem: 'Q：スロットの取り付け？',
            li:[
              'A：1.まず、樹脂材料タンク裏面保護フィルムと露光スクリーン保護フィルムを引き裂く。',
              '2. 材料トラフを露光スクリーン上の溝に置き、ネジ口と位置が合うように位置を調整します。',
              '3. 2 本の赤いトラフ ネジをネジの開口部に置き、しっかり締まるまで時計回りに回します。'
            ]
          },
          {
            problem: 'Q：材料供給手順？',
            li:[
              'A：1.まずプリントプラットフォームを最高点に合わせ、樹脂ボトルを上下に15秒間軽く揺らして均一にします。',
              '2.ホッパーを材料槽の隅に置き、樹脂瓶を開け、等速で樹脂をホッパー内に入れ、ゆっくりと材料槽に入れ、余分な気泡を濾過する。',
              'タンクの最大容量は550 mlですので、最大値を超えないでください',
              '装填中は手袋を着用してください'
            ]
          },
          {
            problem: 'Q：どのようにして印刷中にモデルにレイヤー、エンボスが発生しないようにしますか？',
            li:[
              'A：1.モデルサポートが十分かどうかを確認します。モデルの支持が十分でなく、密度が不足していると、印刷が離型するときにモデルが軽く揺れやすくなります。このとき、支持密度を増やすか、手動で支持固定モデルを追加することをお勧めします。',
              '2.カートリッジ離型フィルムが弛緩しているかどうかを検査し、弛緩があれば離型フィルム鋼輪を締めることを提案する。',
              '3.印刷パラメータを適切に調整します。'
            ]
          },
          {
            problem: 'Q：印刷前の準備は？',
            li:[
              'A：1.stlモデルを導入すると、Magic Pen Slicerソフトウェア内のモデルライブラリでモデルをダウンロードしたり、Octopus Coreモデルサイトでモデルをダウンロードしたり、スライスソフトウェアを使用してスライスファイルをエクスポートすることができます。',
              '2.消耗品：感光性樹脂を使用する前に軽度の左右に揺動して、決して強く揺動して大量の気泡を発生させないでください。皮膚が感光性樹脂に直接触れたり、目に触れたりしないでください。必ず手袋をして、皮膚アレルギーや不快感を引き起こす場合は、水やアルコールで洗い流してください。もし深刻な場合は医者にかからないようにしてください。消耗品の選別は、プリンターの損傷を防ぐために、メーカーが消耗品を提案することを提案することを提案する。感光性樹脂が使用されていない場合は、濾過してから瓶に回収したり、物で材料タンクを覆ったりして、直射日光と強い光が当たらないようにしたり、ほこりが入らないようにしたりすることができます。',
              '3.検査プラットフォーム：機械を使用して印刷する前に、印刷プラットフォームが平らに調整されているかどうかを検査し、プラットフォームのねじとスロットの両端のねじが締められているかどうかを検査し、印刷に影響を与えないようにする。',
              '4.スクリーン露光の検査：3 Dプリンタを使用して硬化する前に、照射スクリーンが正常かどうかを観察する。紫外線カット眼鏡をかけて観察してください。画面をテストするときは、目を傷つけないように画面を直視しないようにしてください。槽内の不純物が印刷に影響するかどうかを検査し、金属刃で槽内離型膜に接触しないでください。露光が規定の範囲に合っているかどうかを決定し、後続の露光や露光不足による失効を回避する。'
            ]
          },
          {
            problem: 'Q：印刷操作？',
            li:[
              'A：1.まず、モデル生成.zyslr4/.zipファイルをUSBディスクに保存します。',
              '2.Uディスクを挿入し、画面にUディスクが弾窓に挿入されたことが表示されるようにします。',
              '3.「Uディスク」タブをクリックし、ファイルを選択し、モデルファイルをクリックし、印刷パラメータを選択し、印刷を開始します。'
            ]
          },
          {
            problem: 'Q：印刷完了処理？',
            li:[
              'A：1.洗浄手順を行う前に、手袋やゴーグルの着用など、適切な安全対策を講じてください。樹脂や洗浄液の中には、皮膚や目に有害なものがある可能性があります。',
              '2.印刷が完了したモデルを印刷プラットフォームから軽く取り外し、モデルを壊さないように注意してください。',
              '3.通常、印刷完了後のモデル表面には未硬化の樹脂が付着しています。アルコールまたはその他の適切な洗浄液を使用して、モデルを洗浄容器に入れ、モデル全体をカバーすることを確認します。モデルを洗浄液にしばらく浸漬させ、時間は約1 ~ 3分。残った樹脂を取り除き、浸してきれいに拭き取った後、洗浄して磨くことができます。',
              '4.ソフトブラシを使用してモデルの表面を軽く拭き、きれいに洗浄できるようにすることができます。',
              '5.洗浄したモデルを紫外線露光箱に入れたり、紫外線ランプを使って2回目の硬化を行ったりするには、2 ~ 3分かかります。',
              '6.必要に応じて、モデルをコーティングしたりペイントしたりして、外観効果を高めることができます。'
            ]
          },
          {
            problem: 'Q：障害の内容と解決方法は？',
            li:[
              'A：1. 樹脂の問題',
              'レジンの汚れや長期放置によるレジンの析出はモデル印刷の色ずれの原因となりますので、レジンは正しい方法でご使用ください。',
              '2.ワイヤロッドガイドに異音が発生したり、引っかかったりする問題',
              'グリースを長時間使用しないと、ワイヤロッドガイドに異音が発生し、ワイヤロッドガイドにグリースを塗布するだけでよい。',
              '3.プラットフォームの付着問題',
              '印刷モデルが印刷プラットフォームに正常に取り付けられない可能性があり、印刷モデルが固定できず、印刷モデルがずれたり外れたりする可能性があります。モデル下地の硬化時間が足りないか、モデル自体に問題がある可能性があります。モデル支持を再調整し、ベースを追加することをお勧めします。',
              '4.樹脂硬化不完全',
              '樹脂の硬化が不完全であると、印刷物の表面が粘稠になったり、細部が不明瞭になったり、未硬化の領域が現れたりする可能性があります。モデルのサイズに応じて、硬化時間を延長することをお勧めします。',
              '5.ソフトウェア設定の問題',
              '誤った印刷パラメータ、誤ったファイル形式、誤った支持設定などにより、印刷モデルの品質が低下したり、印刷に失敗したりする可能性があります。',
              '6.硬化時間の問題',
              '誤った硬化時間の設定は樹脂の硬化不足や過度の原因となり、印刷結果に影響を与える可能性がある。硬化時間はモデルの大きさに合わせて適切に設定してください。'
            ]
          },
        ]
      },

      {
        label: 'Magic pen slicer',
        collapse:[
          {
            problem: 'Q：3 Dスライスソフトとは？',
            li:[
              'A：1.スライスソフトウェアは、デジタル3 Dモデルを3 Dプリンタで認識可能な印刷コードに変換し、3 Dプリンタに印刷コマンドを実行させる3 Dソフトウェアです。わかりやすいのは、スライスソフトウェア計算によって3 Dプリンタが歩く経路を自動的に生成し、プリンタが経路を読み取ってモデルを印刷する）',
              '2.具体的なワークフローは、スライスソフトウェアが選択した設定に基づいてSTLなどの形式のモデルを水平にカットすることで、プリンタにどれだけの消耗品と時間がかかるかを計算する平面図を得ることができます。そして、これらの情報をGCodeファイルに一括して格納し、SDカード/データ線/ネットワークなどを介して3 Dプリンタに転送し、動作を開始させる。'
            ]
          },
          {
            problem: 'Q：スライスソフトの使用？',
            li:[
              'A：1.「ファイル」をクリックしてSTL形式モデルファイルを開き、ファイルに破損があれば、自動修復ファイルをクリックすることができます。',
              '2.モデルサイズの調整：「スケール」をクリックし、右画面で「スケールをロック」を選択すると、スケールに基づいてモデルサイズを調整できます。',
              '3.モデル支持：モデルの大きさの比率を確認し、モデルの上の「支持」をクリックし、左上隅に「宙に浮いた検出」ボタンを見つけてクリックし、右画面で確認して検出し、検出が完了したら、左上隅に「自動支持」をクリックすればよい。',
              '4.スライス：支持が完了したら、モデルの上の「スライス」をクリックし、スライスパラメータを設定する必要があれば、スライス設定をクリックして調整し、スライス設定を保存した後、右画面で確認をクリックしてスライスを開始し、スライスが完了したら、左上で保存アイコンをクリックし、印刷プレビューを確認した後、保存ドキュメントをクリックすればよい。'
            ]
          },
          {
            problem: 'Q：サポートテクニックを追加しますか？',
            li:[
              '答：1.「ファイル」をクリックしてSTL形式モデルファイルを開き、ファイルに破損があれば、自動修復ファイルをクリックすることができます。',
              '2.支持を自動的に追加した後、再度検査する必要があります。一部の場所では支持密度が足りない場合は、手動で追加する必要があります。または、宙に浮いている一番下には支持がなくても、適切に増やすことができます。',
              '3.モデルが下地を貼らずに印刷する場合は、底に最も近い場所で支持数が十分であることに注意してください。',
              '4.シェル、パンチが必要なモデルは、シェルとパンチの後に支持を加える。',
              '5.モデルの中には底が平らではないものもあり、印刷を適切に持ち上げたり、下に少しずらしたりすることができます（底に高さが必要でなければ）、これは下の小さな部分を平らにすることに相当します。',
              '6.中空の模型や殻を抜いた後に穴を開けて、中の樹脂を流すことができます。'
            ]
          },
          {
            problem: 'Q：スライスファイルは認識できませんか？',
            li:[
              'A：ファイル形式が間違っていて、スライスファイルの形式がプリンタと一致するかどうかをチェックします。ファイルが破損したり、新しいバージョンのスライスソフトウェアはプリンタの旧版ファームウェアと互換性がなく、タコ工場の公式サイトで最新のファームウェアをダウンロードしてプリンタの更新を行うことができます。',
            ]
          }
        ]
      },

      {
        label: 'タコの種',
        collapse:[
          {
            problem: 'Q：タコ核アプリの印刷手順は？',
            li:[
              'A：1.タコ核APPをダウンロードし、APPのワークベンチを開き、プリンタデバイスを追加し、バインドします（携帯電話とプリンタが同じWiFiネットワークにあることを確認してください）。',
              '2.デバイスバインドが成功したことを確認したら、デバイスを選択し、ファイルをインポートし、オンラインスライス処理します。',
              '3.ファイルの処理が終わったら、印刷を開始することを選択すればよく、いつでもテーブルを開けてプリンタの状況を監視することができる。'
            ]
          },
          {
            problem: 'Q：モデルをダウンロードする方法は？',
            li:[
              'A：モデルライブラリをクリックして、異なるモデル分類を選択して、あなたの好きなモデルを取得して、モデルをクリックしてモデルを見て、モデルをダウンロードすればいいです。'
            ]
          },
          {
            problem: 'Q：クラウドプラットフォームの印刷ステップ？',
            li:[
              'A：1.後続の操作を行うためにアカウントにログインします。',
              '2.ワークベンチをクリックし、印刷したいデバイスを追加し、印刷ジョブを作成し、スライスファイルとデバイスを選択して印刷します。',
              '3.ワークベンチページで印刷ジョブを表示できます。'
            ]
          }
        ]
      },
      
    ]
  }
}
 
