 <template>
  <div id="Footer" ref="bottom">
    <div class="footer">
      <div class="top">
        <div class="left">
          <!--产品-->
          <div class="li common">
            <h4>{{ $t("footer.products") }}</h4>
            <p
              @click="
                $router.push({
                  name: 'printer',
                  params: { menu: { head: 5, sub: 0 } },
                })
              "
            >
              {{ $t("footer.printer") }}
            </p>
            <p @click="$router.push({ name: 'mainBoard' })">
              {{ $t("footer.mainBoard") }}
            </p>
            <p @click="$router.push({ name: 'resin' })">
              {{ $t("footer.resin") }}
            </p>
          </div>
          <!--软件与平台-->
          <div class="li common">
            <h4>{{ $t("footer.softPatform") }}</h4>
            <p
              @click="
                $router.push({
                  name: 'software',
                  params: { menu: { head: 2, sub: 2 } },
                })
              "
            >
              Magic Pen Slicer
            </p>
            <p
              @click="
                $router.push({
                  name: 'app',
                  params: { menu: { head: 3, sub: 2 } },
                })
              "
            >
              {{ $t("footer.coreOctoApp") }}
            </p>
            <p @click="gotoShop">{{ $t("footer.shop") }}</p>
          </div>

          
          <div class="li common">
            <h4>{{ $t("footer.support") }}</h4>
            <p
              @click="
                $router.push({
                  name: 'know',
                  params: { menu: { head: 4, sub: 1 } },
                })
              "
            >
              {{ $t("footer.know") }}
            </p>
            <p @click="$router.push({name: 'warranty'})">
              {{ $t("footer.warranty") }}
            </p>
            <p @click="$router.push('/support/faq')">{{ $t("footer.faq") }}</p>

            <!--企业社会责任政策-->
            <!-- <p @click="$router.push('/support/csrp')">
              {{ $t("footer.poli") }}
            </p> -->
          </div>

          <!--公司简介-->
          <div class="li common">
            <h4>{{ $t("footer.compPro") }}</h4>
            <p @click="$router.push({name: 'profile'})">
              {{ $t("footer.aboutUs") }}
            </p>
            <p @click="$router.push({name: 'application'})">
              {{ $t("footer.application") }}
            </p>
          </div>


          <!--联系我们-->
          <div class="li">
            <h4>{{ $t("footer.linkUs") }}</h4>
            <p style="cursor: text; width:8.69792vw; margin-top:0.52083vw;">{{ $t("footer.tel") }}：0755-23358384</p>
            <p style="cursor: text; width: 15.46875vw; margin-top:0.52083vw;">
              {{ $t("footer.mail") }}：sales.szx@octopusworks.com
            </p>
            <div class="addre" style="width: 16.45833vw;">
              <p class="name" style="cursor: text">
                {{ $t("footer.address") }}：
              </p>
              <p class="add" ref="add" style="cursor: text">
                {{ $t("footer.detailAddress") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foo">
      <div class="bottom">
        <ul>
          <li style="font-weight: 400 !important">
            Copyright © 2022-2032 {{ $t("footer.compName") }}
          </li>
          <li></li>
          <li style="font-weight: 400 !important">All Rights reserved</li>
          <li></li>
          <li style="font-weight: 400 !important;">
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >{{ $t("footer.ipc") }}</a
            >
          </li>
          <li></li>
          <li class="last" style="font-weight: 400 !important">
            <a href="https://beian.mps.gov.cn/#/query/webSearch"  target="_blank"
              ><img src="~@/assets/imgs/Footer/national-emblem.png" alt="" />
              {{ $t("footer.puclicNetwork") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  props: {
    border: {
      default: true,
    },
  },
  mounted() {
    this.getLang();
  },
  methods: {
    gotoCoreOcto() {
      window.location.href = "https://core.octopusworks.com/";
    },
    gotoShop() {
      window.location.href = "https://core.octopusworks.com/productLibrary";
    },
    getVw(px){
      let vw = (px / 1920) * 100;
      console.log("vw" , vw);
      return vw + 'vw';
    },
    vh(px){
      let vh = (px / 1080 ) * 100;
      console.log("vh" , vh);
      return vw + 'vh';
    },
    getLang() {
      let lang = localStorage.getItem("lang") || "zh";
      if(lang === "en"){
        this.$refs.add.style = "padding-left:"+ this.getVw(15) + ";" + "width:" + this.getVw(270) + ';'
      }
    },
  },
};
</script>
<style lang="less" scoped>
#Footer {
  width: 100%;
  height: 320px;
  color: #000;
  background-color: #101010;
  // border-top: 1px solid #ccc;
  .footer {
    width: 1200px;
    height: 270px;
    padding-top: 60px;
    background: #101010;
    margin: 0 auto;
    .top {
      box-sizing: content-box;
      width: 1200px;
      // margin: 0 auto 50px;
      display: flex;
      justify-content: space-between;
      // border-bottom: 1px solid #666666;
      .left {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 150px;
        // justify-content: space-between;
        div.li {
          // width: 96px;
          height: 150px;
          // margin-right: 105px;
          h4 {
            // height: 22px;
            font-weight: 700;
            font-size: 16px;
            line-height: 21px;
            color: #FFF;
            font-family: "AliPuHui-Bo" !important;
          }
          p {
            width: 122px;
            font-weight: 400 !important;
            font-family: "AliPuHui-Li" !important;
            color: #FFF;
            cursor: pointer;
            // height: 22px;
            font-size: 14px;
            line-height: 21px;
            margin-top: 21px;
            letter-spacing: -0.28px;
          }
          p.icp {
            cursor: text;
            text-decoration: none;
          }
          div.addre {
            display: flex;
            width: 225px;
            // text-align: left;
            // margin-right: 5px;
            .name {
              font-family: "AliPuHui-Li" !important;
              text-align: justify;
              width: 47px;
              color: #FFF;
              font-size: 14px;
              font-weight: 400;
              margin-top: 10px;
              // line-height: normal;
            }
            .add {
              // padding-left:10px;
              width: 211px;
              margin-top: 10px;
            }
          }
        }
        div.li:last-child {
          margin-right: 0;
        }
        .common {
          p:hover {
            color: #e40807;
          }
        }
      }
    }
  }
  .foo {
    background-color: #101010;
  }
  .bottom {
    width: 1200px;
    margin: 0 auto;
    // height: 66px;
    background-color: #101010;
    ul {
      display: flex;
      box-sizing: content-box;
      width: 1200px;
      // margin: 0 360px;
      padding: 19px 0;
      align-items: center;
      font-size: 12px;
      line-height: 22px;
      color: #FFF;
      background-color: #101010;
      li {
        font-family: "AliPuHui-Li" !important;
        margin-right: 8px;
        a {
          font-family: "AliPuHui-Li" !important;
          color: #FFF;
          cursor: pointer;
        }
        a:hover {
          color: #e40807;
        }
      }
      li:nth-child(2n) {
        width: 1px;
        height: 12px;
        background-color: #FFF;
      }
      li.last {
        display: flex;
        align-items: center;
        a {
          display: flex;
          align-items: center;
        }
        img {
          width: 20px;
          height: 22px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>