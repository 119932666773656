import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: localStorage.getItem('lang') || 'zh'
  },
  getters: {
  },
  mutations: {
    changeLang(state,lang) {
      localStorage.setItem('lang',lang)
      console.log('lang',lang);
      state.lang = lang
    }
  },
  actions: {
  },
  modules: {
  }
})
