import axios from 'axios'
// axios.defaults.baseURL = process.env.VUE_APP_BASE_API
// axios.defaults.baseURL = 'http://47.106.145.204:8080/'
// axios.defaults.baseURL = 'http://10.60.201.39:8081/'
axios.defaults.baseURL = 'https://code.octopusworks.com/'


//上传图片
const uploadImg = (formData) => {
  return axios.post('/Upload/ImageToOss', formData)
}

//发送反馈信息
const sendInfo = (info) => {
  return axios.post('/CollectInfo/Save', {
    "name": info.name,
    "phone": info.phone,
    "phoneCode": info.code,
    "email": info.mail,
    "coupleBackTitle": info.title,
    "coupleBackContent": info.content,
    "imageUrl": info.imageUrl
  })
}

//发送手机验证码
const sendCode = (phone) => {
  return axios.post('/CollectInfo/SendSms', {
    "phoneNumber": phone,
    "codeType": 1,
    "codeValidityMinute": 0,
    "owner": 1
  })
}

//获取软件版本
const getSoftwares = (lang) => {
  return axios.get(`/official/software/getInfo/${lang}`)
} 
//获取app下载二维码
const getAppCode = () => {
  return axios.get('/common/api/getQRCode')
}
export default { getSoftwares,getAppCode,uploadImg,sendInfo,sendCode }