import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//导入注册的全局组件js文件
import '@/components'

//导入基本样式和思源黑体
import '@/style/base.less'

//导入滚动JS
import '@/utils/scroll.js'

//导入计算vw以及vh工具类
import './style/getVw.js'

import './utils/flexible'

import i18n from './lang'
// import './utils/rem'


//每次跳转浏览器滚动导到头部
import './permission'
//引入api.js
import './api/feedback'
//引入pdfObject.js
import './utils/pdfobject'

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    Vue.prototype.$bus = this
  },
  render: h => h(App)
}).$mount('#app')
